import { Button, Text } from '@/shared/design-system/v2';

interface PreviewButtonProps {
  onClick: () => void;
}

export const PreviewButton = ({ onClick }: PreviewButtonProps) => (
  <Button
    variant="subtle"
    onClick={onClick}
    sx={{
      '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
    }}
  >
    <Text variant="small01" color="blue.6">
      Preview
    </Text>
  </Button>
);

import { TemplateModel } from '@/main/generated/api';
import { Chip, Horizontal, Text } from '@/shared/design-system/v2';

interface TemplateCategoryFiltersProps {
  selectedFilters: string[];
  setSelectedFilters: React.Dispatch<React.SetStateAction<string[]>>;
  templateList: TemplateModel[];
  allCategories: string[];
  size?: 'sm' | 'lg';
}

export const TemplateCategoryFilters = ({
  selectedFilters,
  setSelectedFilters,
  templateList,
  allCategories,
  size = 'lg',
}: TemplateCategoryFiltersProps): JSX.Element => {
  const filtersList = [
    ...allCategories
      .map(category => ({
        id: category,
        label: category,
      }))
      .sort((a, b) => {
        if (a.label === 'Others') return 1;
        if (b.label === 'Others') return -1;
        return 0;
      }),
  ];

  const labelStyles =
    size === 'sm' ? {} : { paddingTop: '8px', paddingBottom: '8px', height: '36px' };
  const textVariant = size === 'sm' ? 'subTitle05' : 'subTitle02';

  return (
    <Horizontal py="sm">
      <Chip.Group multiple value={selectedFilters} onChange={setSelectedFilters}>
        {filtersList.map(filter => (
          <Chip
            key={filter.id}
            color="blue.6"
            sx={{
              label: labelStyles,
            }}
            value={filter.id}
            variant="outline"
          >
            <Text
              variant={textVariant}
              color={selectedFilters.includes(filter.id) ? 'blue.6' : 'gray.7'}
            >
              {filter.label} (
              {templateList.filter(template => template.category?.includes(filter.id)).length})
            </Text>
          </Chip>
        ))}
      </Chip.Group>
    </Horizontal>
  );
};

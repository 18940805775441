import { useState } from 'react';
import {
  Box,
  Center,
  SegmentedControl,
  SimpleGrid,
  Tooltip,
  Vertical,
  useDisclosure,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { MARKOVML_CONTACT_US_PAGE } from '../../../developer-docs/links';
import { PaymentSubscriptionType } from '../../../generated/api';
import { getSoloSubscriptionType, getTeamSubscriptionType } from '../util';
import { FreePlanConfirmModal } from './FreePlanConfirmModal';
import { PaidPlanCheckoutModal } from './PaidPlanModal';
import { PricingPlanDetails } from './PricingPlanDetails';
import { BillingFrequency } from './constants';

const getSubscriptionPlans = ({
  billingFrequency,
  openFreePlanModal,
  openSoloPlanModal,
  openTeamPlanModal,
}: {
  billingFrequency: BillingFrequency;
  openFreePlanModal: () => void;
  openSoloPlanModal: () => void;
  openTeamPlanModal: () => void;
}) => {
  const isBilledMonthly = billingFrequency === 'monthly';

  const SUBSCRIPTION_PLANS: { type: PaymentSubscriptionType; onSelect: () => void }[] = [
    {
      type: PaymentSubscriptionType.FreemiumMonthly,
      onSelect: openFreePlanModal,
    },
    {
      type: isBilledMonthly
        ? PaymentSubscriptionType.SoloMonthly99
        : PaymentSubscriptionType.SoloAnnually948,
      onSelect: openSoloPlanModal,
    },
    {
      type: isBilledMonthly
        ? PaymentSubscriptionType.TeamMonthly599
        : PaymentSubscriptionType.TeamAnnually5988,
      onSelect: openTeamPlanModal,
    },
    {
      type: PaymentSubscriptionType.EnterprisePlan,
      onSelect: () => {
        window.location.href = MARKOVML_CONTACT_US_PAGE;
      },
    },
  ];

  return SUBSCRIPTION_PLANS;
};

const frequencyOptions = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'annually' },
];

const gridBreakpoints = [
  { maxWidth: 'sm', cols: 2, spacing: 'sm' },
  { maxWidth: 'xs', cols: 1, spacing: 'sm' },
];

interface PricingPlansTableProps {
  activeSubscriptionType?: PaymentSubscriptionType;
}

export const PricingPlansTable = ({
  activeSubscriptionType,
}: PricingPlansTableProps): JSX.Element => {
  const theme = useMarkovTheme();
  const [frequency, setFrequency] = useState<BillingFrequency>('monthly');

  const [confirmModalOpened, { open: openConfirmModal, close: closeConfirmModal }] =
    useDisclosure(false);
  const [soloModalOpened, { open: openSoloModal, close: closeSoloModal }] = useDisclosure(false);
  const [teamModalOpened, { open: openTeamModal, close: closeTeamModal }] = useDisclosure(false);

  const subscriptionPlans = getSubscriptionPlans({
    billingFrequency: frequency,
    openFreePlanModal: openConfirmModal,
    openSoloPlanModal: openSoloModal,
    openTeamPlanModal: openTeamModal,
  });

  const handleChange = (value: BillingFrequency) => {
    setFrequency(value);
  };

  return (
    <>
      <FreePlanConfirmModal opened={confirmModalOpened} onClose={closeConfirmModal} />
      <PaidPlanCheckoutModal
        opened={soloModalOpened}
        onClose={closeSoloModal}
        subscriptionType={getSoloSubscriptionType(frequency)}
      />
      <PaidPlanCheckoutModal
        opened={teamModalOpened}
        onClose={closeTeamModal}
        subscriptionType={getTeamSubscriptionType(frequency)}
      />
      <Vertical spacing="xl">
        <Center>
          <Tooltip
            opened
            withArrow
            arrowSize={8}
            label="Save 20%"
            color="yellow"
            position="right"
            radius="sm"
          >
            <Box>
              <SegmentedControl
                color={theme.colors.blue[6]}
                data={frequencyOptions}
                value={frequency}
                onChange={handleChange}
              />
            </Box>
          </Tooltip>
        </Center>
        <SimpleGrid spacing="lg" cols={4} breakpoints={gridBreakpoints}>
          {subscriptionPlans.map(({ type: planType, onSelect }) => (
            <PricingPlanDetails
              key={planType}
              planType={planType}
              isActive={planType === activeSubscriptionType}
              frequency={frequency}
              onSelect={onSelect}
            />
          ))}
        </SimpleGrid>
      </Vertical>
    </>
  );
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { Layout, Layouts } from 'react-grid-layout';
import { useAppMetadata } from '@/main/contexts/app-metadata/AppMetadata';
import {
  BaseAPIFilter,
  CreateDashboardRequestModel,
  DashboardModel,
  DashboardUILayoutModelV1VersionEnum,
  ListDashboardResponseModel,
  SortOrder,
  UpdateDashboardArtifactState,
  UpdateDashboardNameDescriptionRequestModel,
} from '@/main/generated/api';
import { dashboardAndWidgetApi } from '@/shared/lib/api';
import { parseLayoutJson } from './util';

const ROOT_KEY = 'dashboard';

export const dashboardKeys = {
  all: [ROOT_KEY],
  listAll: (workspaceId: string) => [ROOT_KEY, 'list', workspaceId],
  list: (
    workspaceId: string,
    filters?: BaseAPIFilter[],
    start?: number,
    limit?: number,
    sortKey?: string,
    sortOrder?: SortOrder,
    searchQuery?: string,
  ) => [
    ...dashboardKeys.listAll(workspaceId),
    start,
    limit,
    sortKey,
    sortOrder,
    filters,
    searchQuery,
  ],
  getDashboardDetails: (workspaceId: string, dashboardId: string) => [
    ROOT_KEY,
    workspaceId,
    dashboardId,
  ],
};

export type DashboardWithLayoutV1 = Omit<DashboardModel, 'uiLayout'> & {
  uiLayout: {
    version: DashboardUILayoutModelV1VersionEnum.V1;
    layout: Layouts;
  };
};

interface DashboardListResponse {
  response: DashboardWithLayoutV1[];
  numRecords: number;
}

export const useGetDashboardsListQuery = ({
  filters = [],
  sortKey = 'createDate',
  sortOrder = SortOrder.Desc,
  start = 0,
  limit = 20,
  searchQuery,
}: {
  filters?: BaseAPIFilter[];
  sortKey?: string;
  sortOrder?: SortOrder;
  start?: number;
  limit?: number;
  searchQuery?: string;
} = {}) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<ListDashboardResponseModel>,
    AxiosError<unknown>,
    DashboardListResponse
  >({
    queryKey: dashboardKeys.list(
      workspaceId,
      filters,
      start,
      limit,
      sortKey,
      sortOrder,
      searchQuery,
    ),
    queryFn: async () =>
      dashboardAndWidgetApi.listDashboardV1(
        workspaceId,
        {
          start,
          limit,
          sortKey,
          sortOrder,
          filters,
        },
        searchQuery,
      ),
    select: data => ({
      response: data.data.response.map(
        dashboard =>
          ({
            ...dashboard,
            uiLayout: {
              version: DashboardUILayoutModelV1VersionEnum.V1,
              layout: parseLayoutJson(dashboard.uiLayout),
            },
          } as DashboardWithLayoutV1),
      ),
      numRecords: data.data.numRecords,
    }),
  });
};

export const useGetDashboardQuery = (dashboardId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<DashboardModel>, AxiosError<unknown>, DashboardWithLayoutV1>({
    queryKey: dashboardKeys.getDashboardDetails(workspaceId, dashboardId),
    queryFn: async () => dashboardAndWidgetApi.getDashboardV1(workspaceId, dashboardId),
    enabled: Boolean(dashboardId),
    select: data =>
      ({
        ...data.data,
        uiLayout: {
          version: DashboardUILayoutModelV1VersionEnum.V1,
          layout: parseLayoutJson(data.data.uiLayout),
        },
      } as DashboardWithLayoutV1),
  });
};

export const useCreateDashboardMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (req: CreateDashboardRequestModel) =>
      dashboardAndWidgetApi.createDashboardV1(workspaceId, req),
    onSuccess: () => {
      queryClient.invalidateQueries(dashboardKeys.listAll(workspaceId));
    },
  });
};

interface UpdateDashboardLayoutReq {
  dashboardId: string;
  layouts: Layouts;
}

export const useUpdateDashboardLayoutMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ dashboardId, layouts }: UpdateDashboardLayoutReq) => {
      const stringifiedLayout = JSON.stringify(layouts);

      return dashboardAndWidgetApi.updateDashboardLayoutV1(workspaceId, dashboardId, {
        uiLayout: {
          version: DashboardUILayoutModelV1VersionEnum.V1,
          layout: stringifiedLayout,
        },
      });
    },
    onMutate: async (req: UpdateDashboardLayoutReq) => {
      const currentDashboardData = queryClient.getQueryData<AxiosResponse<DashboardModel>>(
        dashboardKeys.getDashboardDetails(workspaceId, req.dashboardId),
      );

      if (currentDashboardData) {
        const stringifiedLayout = JSON.stringify(req.layouts);
        queryClient.setQueryData(dashboardKeys.getDashboardDetails(workspaceId, req.dashboardId), {
          ...currentDashboardData,
          data: {
            ...currentDashboardData.data,
            uiLayout: {
              version: DashboardUILayoutModelV1VersionEnum.V1,
              layout: stringifiedLayout,
            },
          },
        });
      }

      return { currentDashboardData };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(dashboardKeys.all);
    },
  });
};

interface AddWidgetToDashboardReqProps {
  widgetId: string;
  dashboard: DashboardModel;
  currentCols?: number;
}

export const useAddWidgetToDashboardMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (req: AddWidgetToDashboardReqProps) => {
      const newLayoutItem: Layout = {
        i: req.widgetId,
        w: 8,
        h: 8,
        x: 0,
        y: Infinity,
      };

      const parsedLayout = parseLayoutJson(req.dashboard.uiLayout);
      const updatedLayout = [...parsedLayout.xs, newLayoutItem];
      const strigifiedUpdatedLayout = JSON.stringify({
        xs: updatedLayout,
      });

      const newLayout = {
        version: DashboardUILayoutModelV1VersionEnum.V1,
        layout: strigifiedUpdatedLayout,
      };

      return dashboardAndWidgetApi.updateDashboardLayoutV1(workspaceId, req.dashboard.dashboardId, {
        uiLayout: newLayout,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(dashboardKeys.all);
    },
  });
};

export const useUpdateDashboardStateMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (updateDashboardArtifactState: UpdateDashboardArtifactState) =>
      dashboardAndWidgetApi.updateDashboardStateV1(workspaceId, updateDashboardArtifactState),
    onSuccess: () => {
      queryClient.invalidateQueries(dashboardKeys.listAll(workspaceId));
    },
  });
};

export const useUpdateDashboardNameDescriptionMutation = (dashboardId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      updateDashboardNameDescriptionRequestModel: UpdateDashboardNameDescriptionRequestModel,
    ) =>
      dashboardAndWidgetApi.updateDashboardNameDescriptionV1(
        workspaceId,
        dashboardId,
        updateDashboardNameDescriptionRequestModel,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(dashboardKeys.getDashboardDetails(workspaceId, dashboardId));
      queryClient.invalidateQueries(dashboardKeys.listAll(workspaceId));
    },
  });
};

import { useRef, useState } from 'react';
import { Alert, Center, Loader } from '@/shared/design-system/v2';
import {
  AgGridReact,
  RowClickedEvent,
} from '@/shared/design-system/v2/core/data-display/table/ag-grid';
import { ResourceModel, StorageType } from '../../../generated/api';
import { API_PAGE_SIZE } from '../../../queries/constants';
import { useGetConnectorResourcesListQueryV3 } from '../../../queries/data-sources';
import { EmptyState } from '../../common/EmptyState';
import { DataSourcesTable } from '../../data-sources/DataSourcesTable';
import { supportedConnectorTypes, supportedResourceTypes } from '../util';

const PAGE_SIZE = API_PAGE_SIZE;

// Define file extensions to filter out
const EXCLUDED_EXTENSIONS = ['.mp4', '.mp3', '.docx', '.wav'];

interface DataResourcesContainerProps {
  onSelectResource: (resourceId: string) => void;
  initialColWidth?: Record<string, number>;
}

export const DataResourcesContainer = ({
  onSelectResource,
  initialColWidth,
}: DataResourcesContainerProps): JSX.Element => {
  const gridRef = useRef<AgGridReact<ResourceModel>>(null);
  const [page, setPage] = useState(1);

  const resourcesListQuery = useGetConnectorResourcesListQueryV3(
    supportedConnectorTypes,
    undefined,
    page,
    PAGE_SIZE,
    '',
    supportedResourceTypes,
  );

  if (resourcesListQuery.isLoading) {
    return (
      <Center h="100%">
        <Loader text="Loading data sources ..." />
      </Center>
    );
  }

  if (resourcesListQuery.isError) {
    return <Alert color="red">Error loading data sources</Alert>;
  }

  // Filter out mp3, mp4, and docx files from resources
  const resources = resourcesListQuery.data.response.filter(resource => {
    if (resource.connectorType === StorageType.MarkovLibrary && resource.resourceMetadata) {
      const metadata = resource.resourceMetadata;

      // Check if any metadata field contains excluded file extensions
      const hasExcludedFile = Object.entries(metadata).some(([_, value]) => {
        if (typeof value === 'string') {
          const lowerValue = value.toLowerCase();
          return EXCLUDED_EXTENSIONS.some(ext => lowerValue.endsWith(ext));
        }
        return false;
      });

      // Keep resources that don't have excluded file types
      return !hasExcludedFile;
    }

    // Keep all other resource types
    return true;
  });
  const numRecords = resourcesListQuery.data.count;

  if (numRecords === 0) {
    return (
      <EmptyState
        title="No existing data sources were found"
        subtitle="Add a data source from the Connectors tab to query your data using natural language"
      />
    );
  }

  const handleRowClick = (e: RowClickedEvent<ResourceModel>) => {
    const resourceId = e.data?.resourceId;
    if (!resourceId) {
      return;
    }
    if (e?.event?.defaultPrevented) {
      return;
    }
    onSelectResource(resourceId);
  };

  const handlePageNumberChange = (pageNumber: number) => {
    gridRef.current?.api.showLoadingOverlay();
    setPage(pageNumber);
  };

  return (
    <DataSourcesTable
      gridRef={gridRef}
      rows={resources}
      onRowClicked={handleRowClick}
      pagination
      paginationPageSize={PAGE_SIZE}
      totalRows={numRecords}
      currentPage={page}
      onPageNumberChange={handlePageNumberChange}
      leftActionsContent={<></>}
      initialColWidth={initialColWidth}
    />
  );
};

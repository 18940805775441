import first from 'lodash/first';
import { Navigate, useLocation } from 'react-router-dom';
import { FullPageLoader } from '@/shared/design-system';
import { Container } from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';
import { cleanErrorsInSearchParams } from '@/shared/lib/router';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useWorkspaceList } from '../../queries/userHome';
import { ErrorBar } from '../common/ErrorBar';
import { InfoMessage } from '../common/InfoMessage';

export const Home = (): JSX.Element => {
  const query = useQueryParams();
  const location = useLocation();
  const { isLoading, error, data } = useWorkspaceList();

  // Redirecting to a workspace if workspace id is there as workspace params
  const workspaceToRoute = query.get('workspaceId');
  if (workspaceToRoute) {
    return (
      <Navigate
        to={{
          pathname: `/${workspaceToRoute}`,
          search: cleanErrorsInSearchParams(window.location.search),
        }}
      />
    );
  }

  if (isLoading) {
    return <FullPageLoader text="Loading your details..." />;
  }

  if (error) {
    return (
      <Container my={48} mx={60}>
        <ErrorBar errorMessage="Unable to load your workspace list. Please refresh after sometime or contact Support." />
      </Container>
    );
  }

  if (!data || !data.length) {
    logger.error('Workspace list is empty on home page');
    return (
      <Container my={48} mx={60}>
        <InfoMessage text="Looks like you aren't part of any workspace. Please ask existing users to invite to their workspace or contact Support." />
      </Container>
    );
  }

  const workspaceToRedirect = first(data)?.workspaceId ?? '';
  const redirectPath = location.state?.redirectPath;

  // If we have a redirect path from auth callback, use it with the workspace ID
  if (redirectPath) {
    return (
      <Navigate
        to={{
          pathname: `/${workspaceToRedirect}${redirectPath}`,
          search: cleanErrorsInSearchParams(window.location.search),
        }}
        replace
      />
    );
  }

  // Otherwise just redirect to the workspace root
  return (
    <Navigate
      to={{
        pathname: `/${workspaceToRedirect}`,
        search: cleanErrorsInSearchParams(window.location.search),
      }}
    />
  );
};

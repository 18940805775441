import { useState } from 'react';
import { ChatPromptNewThread } from '@/main/components/common/chat-with-data/chat/input-prompt/ChatPromptNewThread';
import { Box, Horizontal, Popover } from '@/shared/design-system/v2';
import { ThreadHistoryPopoverContainer } from '../ThreadHistoryPopover.container';
import { DataSourceButton } from './DataSourceButton';
import { PopoverContent } from './PopoverContent';
import { PreviewButton } from './PreviewButton';
import { DataSource } from './types';

interface ChatPromptInputHeaderProps {
  selectedDataSource: DataSource;
  recentDataSources: DataSource[];
  openDataSourceSelectionModal: () => void;
  onPreviewDataClick: () => void;
  onDataSourceSelect: (resourceId: string) => void;
  resourceId?: string;
}

export const ChatPromptInputHeader = ({
  selectedDataSource,
  recentDataSources,
  openDataSourceSelectionModal,
  onPreviewDataClick,
  onDataSourceSelect,
  resourceId,
}: ChatPromptInputHeaderProps): JSX.Element => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleClose = () => setIsPopoverOpen(false);

  return (
    <Horizontal position="apart" p="xs">
      <Horizontal spacing="xs" bg="white">
        <Popover position="top" opened={isPopoverOpen} onChange={setIsPopoverOpen}>
          <Popover.Target>
            <Box onClick={() => setIsPopoverOpen(true)}>
              <DataSourceButton
                dataSourceName={selectedDataSource.resourceName}
                isPopoverOpen={isPopoverOpen}
              />
            </Box>
          </Popover.Target>

          <Popover.Dropdown
            sx={theme => ({
              border: `1px solid ${theme.colors.blue[2]}`,
              borderRadius: theme.radius.md,
              boxShadow: theme.shadows.md,
            })}
          >
            <PopoverContent
              recentDataSources={recentDataSources}
              selectedDataSourceId={selectedDataSource.resourceId}
              onDataSourceSelect={onDataSourceSelect}
              openDataSourceSelectionModal={openDataSourceSelectionModal}
              onClose={handleClose}
            />
          </Popover.Dropdown>
        </Popover>

        <PreviewButton onClick={onPreviewDataClick} />
      </Horizontal>
      <Horizontal spacing="xs">
        {resourceId && <ThreadHistoryPopoverContainer resourceId={resourceId} />}
        {resourceId && <ChatPromptNewThread />}
      </Horizontal>
    </Horizontal>
  );
};

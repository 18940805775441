import { IconCheck } from '@tabler/icons-react';
import { PropsWithChildren } from 'react';
import {
  useGetWorkspaceCurrentSubscriptionDetailsQuery,
  useGetWorkspaceTrialEligibilityQuery,
} from '@/main/queries/account/payment-and-subscription';
import {
  Badge,
  Button,
  Card,
  Horizontal,
  Image,
  Text,
  Vertical,
  openConfirmModal,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { PaymentSubscriptionStatusType, PaymentSubscriptionType } from '../../../generated/api';
import { getPaymentSubscriptionProductType, subscriptionPlanDetails } from '../util';
import { AnnualDiscountBadge } from './AnnualDiscountBadge';
import {
  BillingFrequency,
  NonEnterpriseSubscriptionType,
  PaymentSubscriptionProductType,
  getMonthlyCostUsd,
} from './constants';

const FeatureItem = ({ children }: PropsWithChildren<Record<never, never>>) => {
  const theme = useMarkovTheme();

  return (
    <Horizontal noWrap spacing="sm">
      <IconCheck color={theme.colors.blue[6]} />
      <Text variant="bodyShort02" color="gray.7">
        {children}
      </Text>
    </Horizontal>
  );
};

interface PricingPlanDetailsProps {
  planType: PaymentSubscriptionType;
  frequency: BillingFrequency;
  onSelect: () => void;
  isActive?: boolean;
}

export const PricingPlanDetails = ({
  planType,
  frequency,
  onSelect,
  isActive = false,
}: PricingPlanDetailsProps) => {
  const { isEligible: isTrialEligible } = useGetWorkspaceTrialEligibilityQuery();
  const { data: subscription } = useGetWorkspaceCurrentSubscriptionDetailsQuery();

  const subscriptionProductType = getPaymentSubscriptionProductType(planType);
  const { planName, iconUrl, description, features } =
    subscriptionPlanDetails[subscriptionProductType];

  const isEnterprise = planType === PaymentSubscriptionType.EnterprisePlan;
  const buttonLabel = isEnterprise ? 'Contact us' : 'Choose plan';
  const priceDetail = isEnterprise
    ? 'Custom Quote'
    : `$${getMonthlyCostUsd(
        subscriptionProductType as NonEnterpriseSubscriptionType,
        frequency,
      )}/month`;

  const isTrialing =
    isTrialEligible && subscription?.status === PaymentSubscriptionStatusType.Trialing;
  // Enable upgrade during trial period
  const isBtnDisabled = isActive && !isTrialing;

  const handleButtonClick = () => {
    // Edge case: if upgrading during free trial period, show confirm modal as trial will end.
    // For Freemium/Enterprise plans, we want different UX, so this is just for Solo/Team upgrade.
    const isFreemium = planType === PaymentSubscriptionType.FreemiumMonthly;
    const shouldConfirm = isTrialing && !isFreemium && !isEnterprise;
    if (shouldConfirm) {
      openConfirmModal({
        size: 'lg',
        centered: true,
        withCloseButton: false,
        labels: { confirm: 'Confirm', cancel: 'Cancel' },
        onConfirm: onSelect,
        children: (
          <Vertical spacing="xl">
            <Text variant="subTitle02">
              By changing your plan, you will be ending your free trial.
            </Text>
            <Text variant="bodyShort02">Are you sure you want to continue?</Text>
          </Vertical>
        ),
      });
    } else {
      onSelect();
    }
  };

  const shouldShowDiscount =
    frequency === 'annually' &&
    (subscriptionProductType === PaymentSubscriptionProductType.Solo ||
      subscriptionProductType === PaymentSubscriptionProductType.Team);

  return (
    <Card withBorder p="xxl" radius="lg">
      <Vertical spacing="xxl">
        <Vertical>
          <Horizontal noWrap>
            <Image src={iconUrl} width={24} />
            {/* padding-bottom needed for text alignment */}
            <Text variant="heading02" pb="xs">
              {planName}
            </Text>
            {isActive && (
              <Badge variant="outline" color="green">
                Active
              </Badge>
            )}
          </Horizontal>
          <Text variant="bodyShort02" color="gray.7" mih={72}>
            {description}
          </Text>
          <Horizontal spacing="sm">
            <Text variant="heading04">{priceDetail}</Text>
            {shouldShowDiscount && (
              <AnnualDiscountBadge subscriptionType={subscriptionProductType} />
            )}
          </Horizontal>
        </Vertical>
        <Button
          variant="primary"
          size="md"
          color="gray.9"
          onClick={handleButtonClick}
          disabled={isBtnDisabled}
        >
          {buttonLabel}
        </Button>
        <Vertical spacing="lg">
          <Text variant="subTitle03" color="gray.6">
            {planName} plan includes:
          </Text>
          <Vertical>
            {features.map((feature, i) => (
              // index as key since order of features is stable
              <FeatureItem key={i}>{feature}</FeatureItem>
            ))}
          </Vertical>
        </Vertical>
      </Vertical>
    </Card>
  );
};

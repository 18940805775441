import { Badge } from '@/shared/design-system/v2';
import { NonEnterpriseSubscriptionType, getAnnualDiscountPercentage } from './constants';

interface AnnualDiscountBadgeProps {
  subscriptionType: NonEnterpriseSubscriptionType;
}

export const AnnualDiscountBadge = ({ subscriptionType }: AnnualDiscountBadgeProps) => {
  const discount = getAnnualDiscountPercentage(subscriptionType);

  return (
    <Badge size="lg" color="yellow">
      {discount}% off
    </Badge>
  );
};

import { SIGNUP_ILLUSTRATION } from '@/shared/assets';
import { Box, Button, Center, Image, Text, Vertical } from '@/shared/design-system/v2';

const SIGNUP_ILLUSTRATION_WIDTH = 78;

export interface LoginProps {
  onLogin: () => void;
  appName: string;
  appLogoUrl: string;
}

// TODO: Move this component to a shared directory and use it in the office-add-in's login screen
export const Login = ({ onLogin, appName, appLogoUrl }: LoginProps) => (
  <Center h="100%" bg="white.0">
    <Vertical spacing={40} align="center">
      <Image src={appLogoUrl} alt="MarkovML" height={40} fit="contain" />
      <Vertical spacing="sm" align="center">
        <Vertical align="center" spacing="lg">
          <Box w={SIGNUP_ILLUSTRATION_WIDTH}>
            <Image src={SIGNUP_ILLUSTRATION} />
          </Box>
          <Text ta="center" variant="bodyShort02" color="gray.7">
            Login to {appName} to get started
          </Text>
        </Vertical>
      </Vertical>
      <Vertical spacing="xl" align="center">
        <Button w={160} variant="primary" onClick={onLogin}>
          Sign in
        </Button>
      </Vertical>
    </Vertical>
  </Center>
);

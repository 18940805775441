import { IconDatabasePlus } from '@tabler/icons-react';
import {
  Button,
  CloseButton,
  Horizontal,
  Radio,
  ScrollArea,
  Text,
  Vertical,
} from '@/shared/design-system/v2';
import { DataSourceItem } from './DataSourceItem';
import { DataSource } from './types';

interface PopoverContentProps {
  recentDataSources: DataSource[];
  selectedDataSourceId: string;
  onDataSourceSelect: (resourceId: string) => void;
  openDataSourceSelectionModal: () => void;
  onClose: () => void;
}

export const PopoverContent = ({
  recentDataSources,
  selectedDataSourceId,
  onDataSourceSelect,
  openDataSourceSelectionModal,
  onClose,
}: PopoverContentProps) => {
  const handleSelectAnother = () => {
    openDataSourceSelectionModal();
    onClose();
  };

  const handleChange = (value: string) => {
    onDataSourceSelect(value);
    onClose();
  };

  return (
    <Vertical maw={360} spacing="md">
      <Horizontal position="apart">
        <Text variant="subTitle02" color="gray.7">
          Recent Data Sources
        </Text>
        <CloseButton size="sm" onClick={onClose} />
      </Horizontal>

      <Radio.Group value={selectedDataSourceId} onChange={handleChange}>
        <ScrollArea.Autosize mah={240}>
          {recentDataSources.map(source => (
            <DataSourceItem
              key={source.resourceId}
              source={source}
              isSelected={source.resourceId === selectedDataSourceId}
              onSelect={handleChange}
            />
          ))}
        </ScrollArea.Autosize>
      </Radio.Group>

      <Button
        variant="subtle"
        onClick={handleSelectAnother}
        leftIcon={<IconDatabasePlus size={20} />}
      >
        <Text pl="xs" variant="subTitle02" color="blue.6">
          Select another data source
        </Text>
      </Button>
    </Vertical>
  );
};

import { IconHistory } from '@tabler/icons-react';
import { useState } from 'react';
import {
  ThreadProps,
  ThreadsItem,
} from '@/main/components/common/chat-with-data/menu/panels/threads-panel/ThreadsItem';
import { useThreadsItemContext } from '@/main/contexts/chat-with-data/ThreadsItemContext';
import {
  ActionIcon,
  Alert,
  Loader,
  Popover,
  ScrollArea,
  Text,
  Tooltip,
  Vertical,
} from '@/shared/design-system/v2';
import { InfiniteListWrapper } from '../../../InfiniteListWrapper';

export interface ThreadHistoryPopoverProps {
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  threads: ThreadProps[];
}

export const ThreadHistoryPopover = ({
  isLoading,
  isFetching,
  isError,
  fetchNextPage,
  hasNextPage,
  threads,
}: ThreadHistoryPopoverProps) => {
  const { activeThread, setActiveThread } = useThreadsItemContext();
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const handleThreadOnClick = (thread: ThreadProps) => {
    setActiveThread(thread);
    setPopoverOpen(false);
  };

  const togglePopover = () => {
    setPopoverOpen(prev => !prev);
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loader color="yellow.6" text="Loading chat history" />;
    }

    if (isError) {
      return <Alert color="red">Error loading chat history</Alert>;
    }

    return (
      <Vertical h={400} w="100%" justify="space-between" spacing={0}>
        <Text variant="subTitle02" align="center" py="sm">
          History
        </Text>
        <ScrollArea h="100%" offsetScrollbars pl="md">
          <InfiniteListWrapper
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetching}
            fetchNextPage={fetchNextPage}
          >
            {threads.length === 0 ? (
              <Text align="center" italic>
                No History
              </Text>
            ) : (
              threads.map((threadsItem, index) => (
                <ThreadsItem
                  key={index}
                  thread={threadsItem}
                  isActive={threadsItem.threadId === activeThread?.threadId}
                  onClick={handleThreadOnClick}
                />
              ))
            )}
          </InfiniteListWrapper>
        </ScrollArea>
      </Vertical>
    );
  };

  return (
    <Popover opened={isPopoverOpen} onChange={togglePopover} position="top" width={500} shadow="md">
      <Popover.Target>
        <Tooltip label="View thread history" withArrow>
          <ActionIcon aria-label="View thread history" color="gray.7" onClick={togglePopover}>
            <IconHistory size={20} />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown>{renderContent()}</Popover.Dropdown>
    </Popover>
  );
};

import { Layouts } from 'react-grid-layout';
import {
  DashboardUILayoutModelV1,
  DashboardUILayoutModelV1VersionEnum,
  DashboardUILayoutModelV2,
  DashboardUILayoutModelV2VersionEnum,
} from '@/main/generated/api';
import { logger } from '@/shared/initializers/logging';

export const parseLayoutJson = (
  uiLayout: DashboardUILayoutModelV1 | DashboardUILayoutModelV2,
): Layouts => {
  switch (uiLayout.version) {
    case DashboardUILayoutModelV1VersionEnum.V1:
      let parsedLayout: Layouts = { sm: [] };

      if (uiLayout.layout === '') {
        // Must be a new dashboard
        return parsedLayout;
      }

      try {
        const parsedStringifiedLayout = JSON.parse(uiLayout.layout);

        // Discard any other layout. Keep sm for simplicity
        // Later this can be revisited
        parsedLayout = {
          sm:
            parsedStringifiedLayout.sm || parsedStringifiedLayout.md || parsedStringifiedLayout.lg,
        };
      } catch (e) {
        logger.error('Unable to parse layout');
      }

      return parsedLayout;

    case DashboardUILayoutModelV2VersionEnum.V2:
    default:
      return { sm: [] };
  }
};

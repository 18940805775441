import { MouseEventHandler } from 'react';
import { ActionIcon, Box, Card, CloseButton, RichTextEditor } from '@/shared/design-system/v2';
import { useWidgetStyles } from '../widget.styles';
import { useTextViewEditorStyles } from './TextViewEditor.styles';
import { useTextEditor } from './useTextEditor';

interface TextViewProps {
  initialContent: string;
  editable: boolean;
  onContentChange: (content: string) => void;
  onWidgetRemove?: MouseEventHandler<HTMLButtonElement>;
  withBorder?: boolean;
}

export const TextView = ({
  initialContent,
  editable,
  onContentChange,
  onWidgetRemove,
  withBorder = true,
}: TextViewProps) => {
  const { classes: widgetClasses } = useWidgetStyles();
  const editor = useTextEditor({ content: initialContent, editable, onContentChange });

  const { classes } = useTextViewEditorStyles({ active: editor?.isFocused });

  return (
    <Card
      withBorder={withBorder}
      radius="md"
      bg="white.0"
      p="xs"
      h="100%"
      hoverEffect={editable}
      sx={{ overflow: 'auto' }}
      className={widgetClasses.card}
    >
      {onWidgetRemove && (
        <Box w="100%">
          <ActionIcon
            bg="white.0"
            ml="auto"
            sx={theme => ({
              border: `1px solid ${theme.colors.gray[3]}`,
              boxShadow: theme.shadows.sm,
            })}
            className={widgetClasses.action}
            onClick={onWidgetRemove}
            onMouseDown={e => e.stopPropagation()}
            onTouchStart={e => e.stopPropagation()}
          >
            <CloseButton />
          </ActionIcon>
        </Box>
      )}
      <RichTextEditor editor={editor} classNames={classes}>
        <RichTextEditor.Content />
      </RichTextEditor>
    </Card>
  );
};

import { IconChevronUp, IconTable } from '@tabler/icons-react';
import { Button, Text, Tooltip, useMarkovTheme } from '@/shared/design-system/v2';

interface DataSourceButtonProps {
  dataSourceName: string;
  isPopoverOpen: boolean;
}

export const DataSourceButton = ({ dataSourceName, isPopoverOpen }: DataSourceButtonProps) => {
  const theme = useMarkovTheme();

  const button = (
    <Button
      variant="subtle"
      leftIcon={<IconTable size={16} color={theme.colors.green[6]} />}
      rightIcon={<IconChevronUp size={16} color={theme.colors.gray[7]} />}
      shape="rounded"
      bg="white.0"
      sx={theme => ({
        border: `1px solid ${theme.colors.gray[0]}`,
        '&:hover': {
          backgroundColor: theme.colors.white[0],
          borderColor: theme.colors.blue[1],
        },
      })}
    >
      <Text
        variant="bodyShort03"
        color="gray.8"
        maw={200}
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {dataSourceName}
      </Text>
    </Button>
  );

  if (isPopoverOpen) {
    return button;
  }

  return (
    <Tooltip label={dataSourceName} openDelay={500} withinPortal withArrow>
      {button}
    </Tooltip>
  );
};

import get from 'lodash/get';
import { useEffect } from 'react';
import { useDataAnalyticsResourceSelectionModal } from '@/main/components/data-analytics/v2/DataAnalyticsResourceSelectionModal';
import { useDashboardWithDataAnalyticsContext } from '@/main/contexts/dashboard/DashboardWithDataAnalyticsContext';
import { useGetDashboardQuery } from '@/main/queries/dashboard/dashboard';
import { useGetWidgetDetailsQuery } from '@/main/queries/dashboard/widget';
import { Center, Loader, Vertical } from '@/shared/design-system/v2';
import { Dashboard } from './Dashboard';
import { EmptyDashboardView } from './EmptyDashboardView';
import { DashboardHeader } from './dashboard-header/DashboardHeader';
import { getFirstVizFromLayouts } from './util';

interface DashboardContainerProps {
  dashboardId: string;
}

export const DashboardContainer = ({ dashboardId }: DashboardContainerProps) => {
  const { isInitialLoading, isError, data: dashboardData } = useGetDashboardQuery(dashboardId);
  const { resourceId, setResourceId, isEditMode } = useDashboardWithDataAnalyticsContext();

  const handleOnDataAnalyticsCreate = (resourceId: string) => {
    setResourceId(resourceId);
  };

  const { open: openDataSourceSelectionModal } = useDataAnalyticsResourceSelectionModal({
    onDataAnalyticsCreate: handleOnDataAnalyticsCreate,
    allowClose: false,
  });

  const firstViz = getFirstVizFromLayouts(dashboardData);

  const { data: widget } = useGetWidgetDetailsQuery(firstViz?.i);

  useEffect(() => {
    if (resourceId) {
      return;
    }

    const dataSourceId = get(widget, 'dataConfiguration.dataSourceId');
    if (dataSourceId) {
      setResourceId(dataSourceId);
    } else {
      if (dashboardData && !firstViz) {
        openDataSourceSelectionModal();
      }
    }
  }, [dashboardData, firstViz, openDataSourceSelectionModal, resourceId, setResourceId, widget]);

  if (isInitialLoading) {
    return (
      <Center h="100%">
        <Loader text="Loading dashboard" />
      </Center>
    );
  }

  if (isError) {
    return <div>Error...</div>;
  }

  if (!dashboardData) {
    return <div>No data...</div>;
  }

  const layout = dashboardData.uiLayout.layout;

  if (!layout || !firstViz) {
    return <EmptyDashboardView />;
  }

  return (
    <Vertical h="100%" spacing="xs" sx={{ zIndex: 1 }}>
      {isEditMode && <DashboardHeader dashboardId={dashboardId} />}
      <Dashboard dashboard={dashboardData} />
    </Vertical>
  );
};

import { createStyles } from '@/shared/design-system/v2';
import { getStylesRef } from '@/shared/design-system/v2/styles';

export const useWidgetStyles = createStyles(theme => ({
  action: {
    ref: getStylesRef('action'),
    visibility: 'hidden',
    //   position: 'absolute',
    //   top: -16,
    //   zIndex: 2,
  },
  card: {
    [`&:hover .${getStylesRef('action')}`]: {
      visibility: 'visible',
    },
  },
}));

import { Box, Text } from '@/shared/design-system/v2';

export interface ThreadProps {
  threadId: string;
  threadTitle: string;
  createDate: string;
}

export interface ThreadsItemProps {
  thread: ThreadProps;
  isActive: boolean;
  onClick: (thread: ThreadProps) => void;
}

export const ThreadsItem = ({ thread, isActive, onClick }: ThreadsItemProps) => (
  <Box
    key={thread.threadId}
    p="md"
    sx={theme => ({
      cursor: 'pointer',
      background: isActive ? theme.colors.blue[0] : theme.colors.white[0],
      '&:hover': {
        background: isActive ? theme.colors.blue[0] : theme.colors.gray[0],
      },
    })}
    onClick={() => onClick(thread)}
  >
    <Text variant="subTitle04" color="gray.6">
      {thread.createDate}
    </Text>
    <Text lineClamp={2} variant="subTitle03" color={isActive ? 'blue.6' : 'gray.7'}>
      {thread.threadTitle}
    </Text>
  </Box>
);

import { IconFileUpload } from '@tabler/icons-react';
import { useState } from 'react';
import {
  Box,
  Button,
  Center,
  Loader,
  Tabs,
  Text,
  Vertical,
  closeAllModals,
  notifications,
  openModal,
} from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';
import { useCreateDataAnalyticsMutation } from '../../../queries/data-analytics';
import { CompleteParams } from '../../../queries/data-upload';
import { dataAnalyticsSupportedFiletypes } from '../../common/uploader/file-uploader/util';
import { ConnectorResourceSelection } from '../../connectors/modals/add-resource/ConnectorResourceSelection';
import { useDataSourcesFileUploadModal } from '../../data-sources/modals/use-data-sources-file-upload';
import { getDataAnalyticsResourceActions } from '../getDataAnalyticsResourceActions';
import { DATA_ANALYTICS_NAME, supportedConnectorTypes } from '../util';
import { DataResourcesContainer } from './DataResources.container';

const TAB_HEIGHT = 36;

enum DataSelectionTab {
  DATA_SOURCES = 'DATA_SOURCES',
  CONNECTORS = 'CONNECTORS',
}

interface DataAnalyticsResourceSelectionModalProps {
  onDataAnalyticsCreate: (resourceId: string) => void;
}

const DataAnalyticsResourceSelectionModal = ({
  onDataAnalyticsCreate,
}: DataAnalyticsResourceSelectionModalProps) => {
  const [activeTab, setActiveTab] = useState<string | null>(DataSelectionTab.DATA_SOURCES);

  const { isLoading: isDataAnalyticsLoading, mutateAsync: createDataAnalytics } =
    useCreateDataAnalyticsMutation();

  const handleCreateDataAnalytics = async (resourceId: string) => {
    try {
      await createDataAnalytics({ resourceId });
    } catch (err) {
      logger.error(err);
      notifications.error(`Error encountered while creating ${DATA_ANALYTICS_NAME}`);
      return;
    }

    onDataAnalyticsCreate(resourceId);
    closeAllModals();
  };

  const onFileUploadCompleteHandler = (
    state: 'success' | 'error',
    completeParams: CompleteParams,
  ) => {
    if (state === 'success') {
      const resourceId = completeParams.key;
      handleCreateDataAnalytics(resourceId);
    }
    // A notification is already shown in case of 'error'.
  };

  const openFileUploader = useDataSourcesFileUploadModal({
    onFileUploadComplete: onFileUploadCompleteHandler,
    supportedFileTypes: dataAnalyticsSupportedFiletypes,
  });

  const tabs = [
    {
      label: 'Data Sources',
      value: DataSelectionTab.DATA_SOURCES,
      content: (
        <DataResourcesContainer
          onSelectResource={handleCreateDataAnalytics}
          initialColWidth={{ name: 540, connectorType: 130, preview: 120 }}
        />
      ),
    },
    {
      label: 'Connectors',
      value: DataSelectionTab.CONNECTORS,
      content: (
        <ConnectorResourceSelection
          availableConnectorTypes={supportedConnectorTypes}
          onSelectResource={handleCreateDataAnalytics}
          getResourceActions={getDataAnalyticsResourceActions}
        />
      ),
    },
  ];

  if (isDataAnalyticsLoading) {
    return (
      <Center h="100%">
        <Loader text={`Opening ${DATA_ANALYTICS_NAME}...`} />
      </Center>
    );
  }

  return (
    <Tabs value={activeTab} onTabChange={setActiveTab} h="100%">
      <Tabs.List>
        {tabs.map(tab => (
          <Tabs.Tab key={tab.value} value={tab.value} size="sm">
            {tab.label}
          </Tabs.Tab>
        ))}
        <Button variant="subtle" ml="auto" leftIcon={<IconFileUpload />} onClick={openFileUploader}>
          Upload a file
        </Button>
      </Tabs.List>
      {tabs.map(tab => (
        <Tabs.Panel key={tab.value} value={tab.value} pt="xl" h={`calc(100% - ${TAB_HEIGHT}px)`}>
          {tab.content}
        </Tabs.Panel>
      ))}
    </Tabs>
  );
};

interface useDataAnalyticsResourceSelectionModalProps {
  onDataAnalyticsCreate: (resourceId: string) => void;
  allowClose?: boolean;
}

export const useDataAnalyticsResourceSelectionModal = ({
  onDataAnalyticsCreate,
  allowClose = true,
}: useDataAnalyticsResourceSelectionModalProps) => {
  const open = () =>
    openModal({
      title: (
        <Vertical spacing="xs">
          <Text variant="subTitle01">Select data</Text>
          <Text variant="subTitle03">
            Select a Data Source, upload a file, or create a new DB connection.
          </Text>
        </Vertical>
      ),
      size: '83vw',
      children: (
        <Box h="70vh">
          <DataAnalyticsResourceSelectionModal onDataAnalyticsCreate={onDataAnalyticsCreate} />
        </Box>
      ),
      withCloseButton: allowClose,
      closeOnClickOutside: allowClose,
      closeOnEscape: allowClose,
    });

  return { open };
};

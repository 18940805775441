import { ScrollArea } from '@mantine/core';
import { WidgetModel } from '@/main/generated/api';
import { useGetBrainstormExplanationQuery } from '@/main/queries/dashboard/widget';
import { AISkeletonLoader } from '@/shared/components/loader/AISkeletonLoader';
import { Alert, MarkdownPreview } from '@/shared/design-system/v2';

interface BrainstormTextViewContainerProps {
  widget: WidgetModel;
}

export const BrainstormTextViewContainer = ({ widget }: BrainstormTextViewContainerProps) => {
  const {
    isLoading,
    isRefetching,
    isError,
    data: brainstormTextResponse,
  } = useGetBrainstormExplanationQuery(widget.widgetId);

  if (isLoading || isRefetching) {
    return <AISkeletonLoader />;
  }

  if (isError) {
    return <Alert color="red">Not able to generate text, please try again later</Alert>;
  }

  return (
    <ScrollArea h="50%" p="sm">
      <MarkdownPreview>{brainstormTextResponse}</MarkdownPreview>
    </ScrollArea>
  );
};

import { useMemo } from 'react';
import { ChatPromptInputWithSuggestions } from '@/main/components/common/chat-with-data/chat/input-prompt/ChatPromptInputWithSuggestions';
import { ThreadProps } from '@/main/components/common/chat-with-data/menu/panels/threads-panel/ThreadsItem';
import {
  defaultThreadValues,
  useThreadsItemContext,
} from '@/main/contexts/chat-with-data/ThreadsItemContext';
import { ChatterRequestModel, QuestionTypes } from '@/main/generated/api';
import { useChatWithVisualizationMutation } from '@/main/queries/data-analytics';

export interface BrainstormPromptInputContainerProps {
  visualizationId: string;
}

export const BrainstormPromptInputContainer = ({
  visualizationId,
}: BrainstormPromptInputContainerProps) => {
  const { activeThread, setActiveThread } = useThreadsItemContext();

  // TODO: Fix Empty suggestions for visualization chat
  const suggestions = useMemo(() => [], []);

  const { mutate: queryPrompt, isLoading: isNewConversationLoading } =
    useChatWithVisualizationMutation(visualizationId, activeThread?.threadId || '');

  const isNewThread = activeThread === defaultThreadValues.activeThread;

  // TODO: This API logic should be extracted into a single one. For now let's extract the following handler.
  const handleSend = (question: string) => {
    const chatterRequestModel: ChatterRequestModel = {
      threadId: activeThread?.threadId,
      question: question,
      questionType: QuestionTypes.Text,
    };
    queryPrompt(chatterRequestModel, {
      onSuccess: res => {
        if (!activeThread) {
          const newConversation = res.data;
          const localThread: ThreadProps = {
            threadId: newConversation.threadId,
            threadTitle: newConversation.question,
            createDate: newConversation.createDate,
          };
          // Set it as active if current selected thread is not undefined.
          // Handles the case when the user has selected an old thread.
          setActiveThread(previousThread => previousThread ?? localThread);
        }
      },
    });
  };

  return (
    <ChatPromptInputWithSuggestions
      suggestions={suggestions}
      onSend={handleSend}
      isSendDisabled={!!isNewConversationLoading}
      isInputDisabled={false}
      isNewThread={isNewThread}
      resourceId={visualizationId}
    />
  );
};

import first from 'lodash/first';
import last from 'lodash/last';
import { ReactFlowInstance } from 'reactflow';
import { v4 as uuid } from 'uuid';
import {
  Edge as ReactflowEdge,
  Node as ReactflowNode,
  createStyles,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import {
  NodeData as ApiNodeData,
  Edge,
  ErrorCode,
  Node,
  OperatorCategory,
  OperatorModel,
  WorkflowRunOperatorStatus,
} from '../../../generated/api';
import {
  AllParameterTypeProps,
  FormDataTypes,
} from '../create/workflow-builder/operator-parameter-form/util';
import { transformWorkflowEdgeToEdge } from '../edges/util';
import { topologicalSort } from '../workflow-utils';

export const NODE_WIDTH = 360;
export const NODE_HEIGHT = 76;
export const NODE_BORDER = 4;
export const NODE_REVOLVING_BORDER = 10;
export const ICON_SIZE = 40;

//no special characters because of kube-flow restrictions
const getId = () => `node${uuid().substring(0, 4)}`;

export interface XYPosition {
  x: number;
  y: number;
}

export type NodeParameters = AllParameterTypeProps;

export interface NodeData extends Omit<ApiNodeData, 'configuration'> {
  configuration?: Record<string, FormDataTypes>;
  additional?: object;
}

export enum EdgeType {
  DataFrame = 'DataFrame',
  // It could be ModelOutput etc, that we have to figure out.
  // Inference output would be a data-frame only, we have to
  // look at training example.
  Model = 'Model',
}

export interface EdgeData {
  isHovered: boolean;
}

export const DEFAULT_BORDER_COLOR = '#DCE0E5';

export type WorkflowNode = ReactflowNode<NodeData>;
export type WorkflowEdge = ReactflowEdge<EdgeData>;

export const createWorkflowNode = (
  operatorId: string,
  nodeCategory: OperatorCategory,
  position: XYPosition,
  name?: string,
): WorkflowNode => {
  const newNode: WorkflowNode = {
    id: getId(),
    position,
    type: nodeCategory,

    data: {
      id: operatorId,
      configuration: {},
      additional: {},
    },
  };

  return newNode;
};

export const transformNodeToWorkflowNode = (
  node: Node,
  operators: OperatorModel[],
): WorkflowNode => {
  // Kubeflow is making node ids lowercase. so matching is required like this
  const operator = operators.find(op => op.operatorId.toLowerCase() === node.data.id.toLowerCase());

  const newNode: WorkflowNode = {
    id: node.id,
    position: node.uiData.position,
    type: operator?.category,
    data: {
      id: node.data.id,
      configuration: { ...node.data.configuration },
      additional: { ...node.uiData.additional },
    },
  };

  return newNode;
};

export const transformWorkflowNodeToNode = (node: WorkflowNode): Node => {
  const newNode: Node = {
    id: node.id,
    data: {
      id: node.data.id,
      configuration: { ...node.data.configuration },
    },
    uiData: {
      position: node.position,
      additional: { ...node.data.additional },
    },
  };

  return newNode;
};

export const MAX_ALLOWED_CONNECTIONS_PER_HANDLE = 12;

export const useNodeRevolvingBorderStyles = createStyles(
  (theme, { color1, color2 }: { color1: string; color2: string }) => ({
    nodeRevolvingBorder: {
      position: 'relative',
      width: `${NODE_WIDTH + NODE_BORDER}px`,
      height: `${NODE_HEIGHT + NODE_BORDER}px`,
      borderRadius: '8px',
      overflow: 'hidden',
      zIndex: 0,

      '&::before': {
        content: '""',
        position: 'absolute',
        top: '-250%',
        left: '-10%',
        right: '-10%',
        bottom: '-250%',
        background: `linear-gradient(0deg, ${color1} 0%, ${color2} 100%)`,
        clipPath: 'polygon(0 0, 0 100%, 100% 0)',
        animation: 'rotate 2s linear infinite',
      },

      '@keyframes rotate': {
        from: {
          transform: 'rotate(0deg)',
        },
        to: {
          transform: 'rotate(360deg)',
        },
      },
    },
  }),
);

export const useNodeLoadingBorder = () => {
  const theme = useMarkovTheme();

  const { classes } = useNodeRevolvingBorderStyles({
    color1: theme.colors.gray[0],
    color2: theme.colors.gray[4],
  });
  return classes.nodeRevolvingBorder;
};

export const useGetColorsFromStatus = (
  status: WorkflowRunOperatorStatus,
  operatorType: OperatorCategory,
) => {
  const theme = useMarkovTheme();

  const { classes } = useNodeRevolvingBorderStyles({
    color1: '#fff1d6',
    color2: '#ffad0a',
  });

  switch (status) {
    case WorkflowRunOperatorStatus.Success:
      return {
        bgColor: 'green.0',
        borderColor: theme.colors.green[6],
      };
    case WorkflowRunOperatorStatus.Failed:
      return {
        bgColor: 'red.0',
        borderColor: theme.colors.red[6],
      };
    case WorkflowRunOperatorStatus.Terminated:
      return {
        bgColor: 'gray.1',
        borderColor: theme.colors.gray[6],
      };
    case WorkflowRunOperatorStatus.Running: {
      return {
        bgColor: 'yellow.0',
        borderColor: theme.colors.yellow[6],
        revolvingBorder: classes.nodeRevolvingBorder,
      };
    }
    case WorkflowRunOperatorStatus.NotStarted:
    default:
      return {
        bgColor: 'white.0',
        borderColor: DEFAULT_BORDER_COLOR,
      };
  }
};

export const findNodesAfter = (startNodeId: string | undefined, edges: WorkflowEdge[]) => {
  if (!startNodeId) return [];

  const visited = new Set<string>();
  const queue: string[] = [startNodeId];

  const result: string[] = [];

  while (queue.length > 0) {
    const currentNodeId = queue.shift()!;

    if (visited.has(currentNodeId)) continue;

    visited.add(currentNodeId);
    result.push(currentNodeId);

    const outgoingEdges = edges.filter(e => e.source === currentNodeId);

    for (const edge of outgoingEdges) {
      if (!visited.has(edge.target)) {
        queue.push(edge.target);
      }
    }
  }

  return result;
};

const OFFSET_Y_FROM_NODE = 160;
const OFFSET_X_FROM_NODE = 500;

export const positionCalculator = (
  nodes: WorkflowNode[],
  edges: WorkflowEdge[],
  reactFlowInstance: ReactFlowInstance<any, any>,
) => {
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  const viewPortToReactflow = reactFlowInstance.screenToFlowPosition({
    x: viewportWidth,
    y: viewportHeight,
  });

  const topoSort = topologicalSort(
    edges.map<Edge>(edge => transformWorkflowEdgeToEdge(edge)),
    nodes.map<Node>(node => transformWorkflowNodeToNode(node)),
  );
  const lastNode = last(nodes);
  const firstNode = nodes.find(node => node.id === first(topoSort));

  let position;

  if (lastNode) {
    const newY = lastNode.position.y + OFFSET_Y_FROM_NODE;

    if (newY > (viewPortToReactflow?.y ?? viewportHeight)) {
      position = {
        x: (firstNode?.position.x ?? 0) + OFFSET_X_FROM_NODE,
        y: firstNode?.position.y ?? 0,
      };
    } else {
      position = { x: lastNode.position.x, y: newY };
    }
  } else {
    position = reactFlowInstance.screenToFlowPosition({
      x: viewportWidth / 2,
      y: viewportHeight / 5,
    });
  }

  return position;
};

export const getErrorMessageFromErrorCode = (code: ErrorCode) => {
  switch (code) {
    case ErrorCode.InvalidInput:
      return `This operation doesn’t support connected input type`;
    case ErrorCode.MissingInput:
      return 'No input is connected to this operation';
    case ErrorCode.MissingParameter:
      return 'Missing values in required fields';
    case ErrorCode.InvalidInput:
    default:
      return `Some values in the required fields don’t match the current input`;
  }
};

import { Text, openModal } from '@/shared/design-system/v2';
import { OperatorStatusDetails } from '../../../../../generated/api';
import { LogsModal } from './LogsModal';

export const useLogsModal =
  () =>
  (
    workflowId: string,
    debugId: string,
    defaultTab: 'preview' | 'logs',
    nodeId: string,
    status: OperatorStatusDetails,
    prevNodeId?: string,
  ) => {
    openModal({
      title: <Text variant="subTitle03">Node run details</Text>,
      withinPortal: true,
      size: 'auto',
      children: (
        <LogsModal
          workflowId={workflowId}
          debugId={debugId}
          defaultTab={defaultTab}
          nodeId={nodeId}
          status={status}
          prevNodeId={prevNodeId}
        />
      ),
    });
  };

import first from 'lodash/first';
import { OperatorModel, TemplateModel } from '@/main/generated/api';
import { Horizontal, Image, Text } from '@/shared/design-system/v2';

interface AutomationTemplateItemProps {
  handleFlowButtonClick: (templateData: TemplateModel) => void;
  template?: TemplateModel;
  operators?: OperatorModel[];
}

export const AutomationTemplateItem = ({
  handleFlowButtonClick,
  template,
  operators = [],
}: AutomationTemplateItemProps): JSX.Element => {
  if (!template) {
    return <></>;
  }

  const firstKeyOperatorId = first(template.keyOperators);
  const keyOperator = operators.find(operator => operator.operatorId === firstKeyOperatorId);

  // TODO: Add click handler once templates are read from BE
  const handleClick = () => handleFlowButtonClick(template);

  return (
    <Horizontal
      spacing="sm"
      px="lg"
      py="sm"
      bg="linear-gradient(90deg, #FFFFFF 0%, rgba(248, 245, 253, 0.52) 29%, rgba(244, 238, 251, 0.2) 100%)"
      sx={theme => ({
        cursor: 'pointer',
        border: `1px solid ${theme.colors.gray[0]}`,
        borderRadius: theme.radius.sm,
      })}
      onClick={handleClick}
    >
      <Image src={keyOperator?.iconUrl} height={24} width={24} />
      <Text variant="small01" color="gray.7">
        {template.name}
      </Text>
    </Horizontal>
  );
};

import { Box, Button, Center, Image, Text, Vertical } from '@/shared/design-system/v2';
import { useBuildAutomationModal } from '../home/modal/BuildAutomationModal';
import { EMPTY_SCREEN_ILLUSTRATION } from './util';

export const EmptyListView = () => {
  const { open: openBuildAutomationModal } = useBuildAutomationModal();

  return (
    <Center h="100%">
      <Vertical align="center" spacing="xxl" pb="xl">
        <Image height={220} width={160} src={EMPTY_SCREEN_ILLUSTRATION} />
        <Box w="100%" sx={{ textAlign: 'center' }}>
          <Text variant="subTitle02" color="gray.8">
            Looks like there are no automation here.
          </Text>
          <Text variant="bodyShort03" color="gray.6">
            Get started by creating an automation flow. All your flows will be displayed here on
            this page.
          </Text>
        </Box>
        <Button variant="outline" onClick={openBuildAutomationModal}>
          <Text variant="subTitle04" color="blue.6">
            Build my first automation
          </Text>
        </Button>
      </Vertical>
    </Center>
  );
};

import { PropsWithChildren } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { AppRoutes, getRoute } from './constants';

export const REDIRECT_TO_APP_ACTION = 'REDIRECT_TO_APP';

export const PublicAppRedirectController = ({ children }: PropsWithChildren) => {
  const [searchParams] = useSearchParams();
  const { workspaceId } = useParams();

  const action = searchParams.get('action');
  const appId = searchParams.get('appId');

  if (action === REDIRECT_TO_APP_ACTION && appId) {
    const workflowsHome = getRoute(AppRoutes.WORKFLOWS_HOME);
    const workflowAppsList = getRoute(AppRoutes.WORKFLOW_APPS_LIST);
    const path = `/${workspaceId}/${workflowsHome}/${workflowAppsList}/${appId}`;

    return <Navigate to={path} />;
  }

  return <>{children}</>;
};

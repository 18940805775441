/* eslint-disable no-console */
import { Alert, Center } from '@mantine/core';
import { useTimeout } from '@mantine/hooks';
import first from 'lodash/first';
import {
  Navigate,
  RouteProps,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { logger } from '@/shared/initializers/logging';
import { Loading } from '../components/common/Loading';
import { ConnectorV2Type } from '../generated/api';
import { useExchangeAuthCodeAndCreateConnection } from '../queries/connectors/connector-oauth-v2';
import { renderAuthRoute } from './AuthRouter';
import { AppRoutes, getRoute } from './constants';

interface RedirectToExistingConnectorProps {
  workspaceId: string;
  connectorType?: ConnectorV2Type;
  connectionId?: string;
  returnTo?: string | null;
}

export const RedirectToExistingConnector = ({
  workspaceId,
  connectionId,
  returnTo,
}: RedirectToExistingConnectorProps) => {
  const navigate = useNavigate();
  const redirectTo = returnTo || `/${workspaceId}/${getRoute(AppRoutes.DATA_SOURCES)}/connectors`;

  useTimeout(() => navigate(redirectTo), 2000, {
    autoInvoke: true,
  });

  return (
    <Center w="100%" h="100%" pos={'-moz-initial'}>
      <Alert color="blue">Account already connected. Redirecting to the existing connection</Alert>
    </Center>
  );
};
export const RedirectToConnectorsTab = ({
  workspaceId,
  returnTo,
}: RedirectToExistingConnectorProps) => {
  const navigate = useNavigate();
  const redirectTo = returnTo || `/${workspaceId}/${getRoute(AppRoutes.DATA_SOURCES)}/connectors`;
  console.log('Redirecting to:', redirectTo);
  useTimeout(() => navigate(redirectTo), 2000, {
    autoInvoke: true,
  });

  return null;
};

export const AuthRedirect = () => {
  const { provider = '', connectorType } = useParams();
  const [searchParams] = useSearchParams();

  const returnTo = searchParams.get('state');
  // Extract only the workspace ID using regex
  const workspaceId = first(returnTo?.match(/wsp-[a-zA-Z0-9]+/)) || '';

  const code = searchParams.get('code') ?? '';

  if (
    !workspaceId ||
    !connectorType ||
    !Object.values(ConnectorV2Type).includes(connectorType?.toUpperCase() as ConnectorV2Type)
  ) {
    logger.error('Workspace ID and Connector Type are required but not provided.');
  }

  const { data, isError, isLoading } = useExchangeAuthCodeAndCreateConnection(
    workspaceId,
    code,
    provider,
    connectorType?.toUpperCase() as ConnectorV2Type,
  );

  if (isLoading) {
    return (
      <Center w="100%" h="100%">
        <Loading />
        Authenticating {provider?.replace(/^\w/, c => c.toUpperCase())} account
      </Center>
    );
  }

  if (isError) {
    return (
      <Center w="100%" h="100%">
        <Alert color="red">There has been an error validating account credentials.</Alert>
      </Center>
    );
  }
  if (data && data.connectionId) {
    return (
      <RedirectToExistingConnector workspaceId={workspaceId} connectionId={data.connectionId} />
    );
  }

  // Only navigate to returnTo if we have a value
  if (!returnTo) {
    return <Navigate to={`/${workspaceId}`} />;
  }

  // If we don't have a connectionId, but the authentication was successful,
  // redirect to the connectors tab
  return <RedirectToConnectorsTab workspaceId={workspaceId} returnTo={returnTo} />;
};

const oauthRoutes: RouteProps[] = [
  {
    // Currently all providers and connectors fit here, but it is possible we may have
    // to extend it
    path: '/:provider/:connectorType',
    element: <AuthRedirect />,
  },
];

export const OAuthRouter = () => <Routes>{oauthRoutes.map(renderAuthRoute)}</Routes>;

import { IconSparkles } from '@tabler/icons-react';
import noop from 'lodash/noop';
import { PropsWithChildren } from 'react';
import { Box, Horizontal } from '@/shared/design-system/v2';
import { skyBlue } from '@/shared/design-system/v2/theme/colors/colorList';

export interface SuggestedQuestionProps {
  onClick?: () => void;
  color?: string;
}

export const SuggestedQuestion = ({
  onClick,
  color = skyBlue.skyBlue6,
  children,
}: PropsWithChildren<SuggestedQuestionProps>) => {
  const isClickable = !!onClick;

  return (
    <Horizontal
      aria-label="Suggested question"
      py="sm"
      px="lg"
      bg="gray.1"
      w={380}
      align="start"
      sx={theme => {
        const clickableStyle = isClickable
          ? {
              cursor: 'pointer',
              '&:hover': {
                background: theme.colors.blue[0],
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 8px 0px',
              },
            }
          : {};

        return {
          borderRadius: '5px',
          ...clickableStyle,
        };
      }}
      onClick={onClick ?? noop}
      noWrap
    >
      <Box>
        <IconSparkles color={color} stroke={1.5} size={24} />
      </Box>
      {children}
    </Horizontal>
  );
};

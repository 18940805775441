import { Text, openModal } from '@/shared/design-system/v2';
import { CompleteParams } from '../../../queries/data-upload';
import { DataSourcesFileModal } from './AddDataSourceFileModal';

interface UseDataSourcesFileUploadModalProps {
  onFileUploadComplete?: (state: 'success' | 'error', completeParams: CompleteParams) => void;
  supportedFileTypes?: string[];
}

// Deprecated: use UploadDataSourcesFile instead
export const useDataSourcesFileUploadModal = (options?: UseDataSourcesFileUploadModalProps) => () =>
  openModal({
    title: (
      <Text variant="subTitle02" p={24}>
        Upload a file
      </Text>
    ),

    size: 'lg',
    padding: 0,
    children: (
      <DataSourcesFileModal
        onFileUploadComplete={options?.onFileUploadComplete}
        supportedFileTypes={options?.supportedFileTypes}
      />
    ),
  });

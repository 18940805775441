import { IconGitFork } from '@tabler/icons-react';
import { FeatureActionCard } from '@/main/components/common/home/FeatureActionCard';
import { WorkflowsListContainer } from '@/main/components/workflows/list/List.container';
import { Box, Button, Container, Horizontal, Text, Vertical } from '@/shared/design-system/v2';
import { SampleAutomationsContainer } from '../../workspace/studio-home/workflow/SampleAutomations.container';
import { useBuildAutomationModal } from './modal/BuildAutomationModal';

const ILLUSTRATION_AUTOMATION_TUTORIAL =
  'https://ik.imagekit.io/markovml/homepage/illustration-automation-tutorial.svg?updatedAt=1739531291215';
const ILLUSTRATION_DATA_INTEGRATIONS =
  'https://ik.imagekit.io/markovml/homepage/illustration-data-integrations.svg?updatedAt=1739531303483';
const ILLUSTRATION_OPERATIONS =
  'https://ik.imagekit.io/markovml/homepage/illustration-operations-homepage.svg?updatedAt=1739531278063';

const TABLE_VERTICAL_OFFSET = 300;

// TODO: Add click handlers for these options once available
export const AutomationsHome = () => {
  const { open: openBuildAutomationModal } = useBuildAutomationModal();

  const getStartedPrompts = [
    {
      title: 'How to make your first automation',
      ctaLabel: 'Read more',
      illustrationUrl: ILLUSTRATION_AUTOMATION_TUTORIAL,
      background: 'linear-gradient(90deg, #FFEDF7 0%, #FFFFFF 100%)',
      onClick: () => window.open('https://docs.markovml.com/docs/how-to-create-a-new-workflow'),
    },
    {
      title: 'How to schedule your automation',
      ctaLabel: 'Read more',
      illustrationUrl: ILLUSTRATION_DATA_INTEGRATIONS,
      background: 'linear-gradient(90deg, #FFF7E7 0%, #FFFFFF 100%)',
      onClick: () => window.open('https://docs.markovml.com/docs/schedule-a-workflow'),
    },
    // {
    //   title: '10 Powerful Operations to Boost Automation',
    //   ctaLabel: 'Read more',
    //   illustrationUrl: ILLUSTRATION_OPERATIONS,
    //   background: 'linear-gradient(93.67deg, #F4EDFB 0%, #FFFFFF 100%)',
    //   onClick: noop,
    // },
  ];

  // TODO: Read these templates from the BE
  // Note: This is a placeholder list (from design) and not final. Full template details
  // are needed to open the TemplateFlowModal, specify icons, etc.
  const templates = [
    {
      templateId: 'temp001',
      name: 'Merge Multiple Data Files',
      icon: <IconGitFork />,
    },
    {
      templateId: 'temp002',
      name: 'Add Row Number to CSV Files',
      icon: <IconGitFork />,
    },
    {
      templateId: 'temp003',
      name: 'Analyze for Sentiment at Scale',
      icon: <IconGitFork />,
    },
    {
      templateId: 'temp004',
      name: 'Build AutoML Model',
      icon: <IconGitFork />,
    },
  ];

  return (
    <Box h="100%" bg="white.0" sx={{ overflowY: 'auto' }}>
      <Container h="100%" py="md" size="md">
        <Vertical h="100%" spacing="lg">
          <Horizontal position="apart">
            <Vertical spacing="xs">
              <Text variant="heading03">Automations</Text>
              <Text variant="bodyShort02" color="gray.8">
                Build automation flows in a easy-to-use, no-code, drag-and-drop interface
              </Text>
            </Vertical>
            <Button variant="primary" onClick={openBuildAutomationModal}>
              + Build an Automation
            </Button>
          </Horizontal>
          <Horizontal spacing="lg" pt="md" pb="lg" px="xs">
            {getStartedPrompts.map(
              ({ title, ctaLabel, illustrationUrl, background, onClick }, i) => (
                <FeatureActionCard
                  key={i}
                  title={title}
                  ctaLabel={ctaLabel}
                  illustrationUrl={illustrationUrl}
                  bg={background}
                  onClick={onClick}
                />
              ),
            )}
          </Horizontal>
          <Vertical spacing="xs">
            <Text variant="small03" color="gray.6">
              Sample automations
            </Text>
            <SampleAutomationsContainer />
          </Vertical>
          <Box h={`calc(100% - ${TABLE_VERTICAL_OFFSET}px)`}>
            <WorkflowsListContainer />
          </Box>
        </Vertical>
      </Container>
    </Box>
  );
};

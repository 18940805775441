import { PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';

interface WorkflowRunContext {
  workflowId: string;

  runId: string;
  setRunId: React.Dispatch<React.SetStateAction<string>>;

  isDebugMode: boolean;
  setIsDebugMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const WorkflowRunContext = createContext<WorkflowRunContext | undefined>(undefined);

export const useWorkflowRunContext = () => {
  const context = useContext(WorkflowRunContext);

  if (context === undefined) {
    throw new Error('useWorkflowRunContext must be used within a WorkflowRunContextProvider');
  }

  return context;
};

interface WorkflowRunProviderProps {
  workflowId: string;
  workflowRunId?: string;
}

export const WorkflowRunContextProvider = ({
  children,
  workflowId,
  workflowRunId = '',
}: PropsWithChildren<WorkflowRunProviderProps>) => {
  const [isDebugMode, setIsDebugMode] = useState(false);
  const [runId, setRunId] = useState(workflowRunId);

  const value = useMemo(
    () => ({
      workflowId,
      runId,
      setRunId,
      isDebugMode,
      setIsDebugMode,
    }),
    [runId, workflowId, isDebugMode],
  );

  return <WorkflowRunContext.Provider value={value}>{children}</WorkflowRunContext.Provider>;
};

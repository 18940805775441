import { IconMessages } from '@tabler/icons-react';
import { Alert, Horizontal } from '@/shared/design-system/v2';
import { ResourceKey, ResourceRequest, StorageType } from '../../generated/api';
import { AddResourceButtonContainer } from '../connectors/connector-details/actions/AddResourceButton.container';
import { ResourceActionsParams } from '../connectors/connector-details/actions/ResourceActions';
import { ResourcePreviewButton } from '../connectors/connector-details/actions/ResourcePreviewButton';
import { isFileSupported } from '../connectors/connector-resources/util';

const supportedNonLeafResourceTypes = [ResourceKey.PostgresDb, ResourceKey.SnowflakeSchema];

interface StartChatButtonProps {
  connectorId: string;
  connectorType: StorageType;
  resourcePath: ResourceRequest[];
  onResourceAdd?: (resourceId: string) => void;
  isNonLeafResource?: boolean;
}

const StartChatButton = ({
  connectorId,
  connectorType,
  resourcePath,
  onResourceAdd,
  isNonLeafResource,
}: StartChatButtonProps) => (
  <AddResourceButtonContainer
    connectorId={connectorId}
    connectorType={connectorType}
    resourcePath={resourcePath}
    onResourceAdd={onResourceAdd}
    leftIcon={<IconMessages />}
    isDataSink={isNonLeafResource}
  >
    Start Chat
  </AddResourceButtonContainer>
);

export const getDataAnalyticsResourceActions = ({
  connectorId,
  connectorType,
  resource,
  resourcesHierarchy,
  onResourceAdd,
}: ResourceActionsParams): JSX.Element | null => {
  const { key, value, isFinal: isLeaf } = resource;

  const isUnsupportedFileType =
    isLeaf && connectorType === StorageType.S3 && !isFileSupported(value);

  if (isUnsupportedFileType) {
    return <Alert>Filetype not supported</Alert>;
  }

  const resourcePath = [...resourcesHierarchy, { key, value }];
  if (isLeaf) {
    return (
      <Horizontal>
        <ResourcePreviewButton connectorId={connectorId} resourcePath={resourcePath} />
        <StartChatButton
          connectorId={connectorId}
          connectorType={connectorType}
          resourcePath={resourcePath}
          onResourceAdd={onResourceAdd}
        />
      </Horizontal>
    );
  }

  // Enable chat action for PostgresDB and Snowflake schema also
  if (supportedNonLeafResourceTypes.includes(key)) {
    return (
      <StartChatButton
        connectorId={connectorId}
        connectorType={connectorType}
        resourcePath={resourcePath}
        onResourceAdd={onResourceAdd}
        isNonLeafResource
      />
    );
  }

  return null;
};

import { PropsWithChildren } from 'react';
import { UploadStatusDisplay } from './UploadStatusDisplay';
import { FileUploadProvider } from './useFileUploadProvider';

export const DataUploadProvider = ({ children }: PropsWithChildren<Record<never, unknown>>) => (
  <FileUploadProvider>
    {children}
    <UploadStatusDisplay />
  </FileUploadProvider>
);

import { Box, openModal } from '@/shared/design-system/v2';
import { PLATFORM_INTRO_VIDEO_URL } from './util';

export const useIntroVideoModal = () => {
  const open = () =>
    openModal({
      withCloseButton: false,
      size: '1440px',
      padding: '4px',
      children: (
        <Box w="100%" h="600px">
          <iframe
            width="100%"
            height="100%"
            src={PLATFORM_INTRO_VIDEO_URL}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </Box>
      ),
    });

  return { open };
};

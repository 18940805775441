import { IconCategory, IconTools } from '@tabler/icons-react';
import {
  Badge,
  Box,
  Card,
  Center,
  Horizontal,
  Image,
  Text,
  ThemeIcon,
  Vertical,
  useMarkovTheme,
} from '@/shared/design-system/v2';

export const EmptyDashboardView = () => {
  const theme = useMarkovTheme();

  return (
    <Center w="100%" h="100%">
      <Card hoverEffect={false}>
        <Horizontal>
          <Box w="273px">
            <Vertical justify="center" align="center">
              <Text variant="overline" color="gray.7">
                Step-1
              </Text>
              <Badge
                leftSection={
                  <ThemeIcon variant="light" color="yellow.0">
                    <IconCategory size={16} color={theme.colors.orange[7]} />
                  </ThemeIcon>
                }
                color="yellow.1"
                bg="yellow.0"
                variant="outline"
                radius="4px"
                h="28px"
              >
                <Text variant="subTitle04" color="orange.7" tt="initial">
                  Chat with your data
                </Text>
              </Badge>
              <Box w="100px" h="100px">
                <Image src="https://ik.imagekit.io/markovml/data-analytics/chat-with-data-icon_TEtMX0B9K.svg?updatedAt=1738696056976" />
              </Box>
              <Text variant="bodyShort02" color="gray.7" ta="center">
                Add insights by interacting with your data in the chat window
              </Text>
            </Vertical>
          </Box>
          <Box w="273px">
            <Vertical justify="center" align="center">
              <Text variant="overline" color="gray.7">
                Step-2
              </Text>
              <Badge
                leftSection={
                  <ThemeIcon variant="light" color="yellow.0">
                    <IconTools size={16} color={theme.colors.orange[7]} />
                  </ThemeIcon>
                }
                color="yellow.1"
                bg="yellow.0"
                variant="outline"
                radius="4px"
                h="28px"
              >
                <Text variant="subTitle04" color="orange.7" tt="initial">
                  Add insights to dashboard
                </Text>
              </Badge>
              <Box w="100px" h="100px">
                <Image src="https://ik.imagekit.io/markovml/data-analytics/dashboard-illustration_B5XNoAZv0.svg?updatedAt=1740419842706" />
              </Box>
              <Text variant="bodyShort02" color="gray.7" ta="center" maw={140}>
                Start adding charts to your dashboard
              </Text>
            </Vertical>
          </Box>
        </Horizontal>
      </Card>
    </Center>
  );
};

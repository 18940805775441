import { useMediaQuery } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import { Alert, Card, SimpleGrid, Text, Vertical, useMarkovTheme } from '@/shared/design-system/v2';
import { TemplateModel } from '../../../../generated/api';
import {
  useGetOperatorListQuery,
  useGetTemplatesListQuery,
} from '../../../../queries/workflows/operators';
import { useTemplateFlowModal } from '../../../workflows/create/templates/useTemplateFlowModal';
import { TemplateCard } from './TemplateCard';
import { TemplatesSkeleton } from './TemplatesSkeleton';

const useTotalDisplayedTemplates = (isWorkflowsHomePage: boolean) => {
  const theme = useMarkovTheme();

  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const isSm = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const isMd = useMediaQuery(`(max-width: 1200px`);

  if (isXs) {
    return 1;
  } else if (isSm) {
    return isWorkflowsHomePage ? 2 : 1;
  } else if (isMd) {
    return isWorkflowsHomePage ? 3 : 2;
  }

  return isWorkflowsHomePage ? 4 : 3;
};

interface TemplateContainerProps {
  searchText: string;
  onAddWorkflow: (templateId?: string, workflowName?: string) => Promise<void>;
  onCreateBlankWorkflow: () => void;
  isWorkflowsHomePage?: boolean;
  isTemplatesHomePage?: boolean;
  selectedCategoryFilters?: string[];
}

export const TemplateContainer = ({
  searchText,
  onAddWorkflow,
  onCreateBlankWorkflow,
  isWorkflowsHomePage,
  isTemplatesHomePage,
  selectedCategoryFilters,
}: TemplateContainerProps) => {
  const { isLoading, isError, data } = useGetTemplatesListQuery();
  const { isLoading: operatorListLoading, data: operatorList } = useGetOperatorListQuery();
  const { open: openTemplateFlowModal } = useTemplateFlowModal();

  const filteredTemplatesBySearch =
    data?.filter(
      datum =>
        datum.name.toLowerCase().includes(searchText.toLowerCase()) ||
        datum.description.toLowerCase().includes(searchText.toLowerCase()),
    ) ?? [];

  const filteredTemplatesByCategory =
    data?.filter(datum =>
      datum.category?.some(category => selectedCategoryFilters?.includes(category)),
    ) ?? [];

  const templatesCount = useTotalDisplayedTemplates(isWorkflowsHomePage ?? false);

  const homepageTemplates = filteredTemplatesBySearch.slice(0, templatesCount);

  const filteredTemplates =
    selectedCategoryFilters && selectedCategoryFilters.length > 0
      ? filteredTemplatesByCategory
      : filteredTemplatesBySearch;

  if (isLoading || operatorListLoading) {
    // Add 1 to template count since we use one grid cell for the "add" button
    return (
      <TemplatesSkeleton
        isTemplatesHomePage
        numTemplates={isWorkflowsHomePage ? templatesCount : templatesCount + 1}
      />
    );
  }

  if (isError || !data) {
    return <Alert color="red">Error occured fetching templates</Alert>;
  }

  const handleFlowButtonClick = (templateData: TemplateModel) => {
    openTemplateFlowModal(templateData, operatorList ?? [], onAddWorkflow);
  };

  return (
    <SimpleGrid
      cols={3}
      w="100%"
      spacing="lg"
      breakpoints={[
        { maxWidth: 'lg', cols: 4 },
        { maxWidth: '1200px', cols: 3 },
        { maxWidth: 'sm', cols: 2 },
        { maxWidth: 'xs', cols: 1 },
      ]}
    >
      {isTemplatesHomePage && (
        <Card
          radius="sm"
          bg="blue.5"
          onClick={onCreateBlankWorkflow}
          data-testid="button-create-new-workflow"
        >
          <Vertical w="100%" h="100%" justify="center" align="center">
            <IconPlus color="#fff" size={48} strokeWidth="1.5px" />
            <Text variant="subTitle02" color="white.0" lineClamp={2}>
              New workflow
            </Text>
          </Vertical>
        </Card>
      )}
      {(isTemplatesHomePage || isWorkflowsHomePage ? homepageTemplates : filteredTemplates)?.map(
        template => (
          <TemplateCard
            key={template.templateId}
            templateData={template}
            handleFlowButtonClick={handleFlowButtonClick}
            operatorList={operatorList ?? []}
            variant={isTemplatesHomePage ? 'small' : 'big'}
          />
        ),
      )}
    </SimpleGrid>
  );
};

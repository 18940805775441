import { createStyles } from '@mantine/styles';

interface StylesParams {
  active?: boolean;
}

export const useTextViewEditorStyles = createStyles((theme, params: StylesParams) => ({
  root: {
    border: 'none',
    cursor: 'text',
    padding: theme.spacing.sm,

    '& .ProseMirror': {
      backgroundColor: params.active ? theme.colors.gray[0] : 'transparent',
      borderRadius: theme.radius.sm,
    },
  },
}));

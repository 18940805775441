import { Skeleton, Text } from '@/shared/design-system/v2';
import { OperatorModel } from '../../../generated/api';
import { useDataSourceDetailsQuery } from '../../../queries/data-sources';
import { StorageMetadataModel, storageConfigMap } from '../../data-sources/cell-renderers/config';
import { ICON_SIZE, NODE_WIDTH } from './utils';

interface ResourceNameProps {
  operator?: OperatorModel;
  resourceId?: string;
  fileName?: string;
  identifier?: string;
}

export const ResourceName = ({ operator, resourceId, fileName, identifier }: ResourceNameProps) => {
  const query = useDataSourceDetailsQuery(resourceId ?? '');

  const { isLoading } = query;
  const connectorType = query.data?.connectorType;
  const resourceMetadata = query.data?.resourceMetadata as StorageMetadataModel;
  let name;

  if (resourceId) {
    name =
      connectorType && resourceMetadata
        ? storageConfigMap[connectorType]?.getResourceName(resourceMetadata) ?? ''
        : '';
  }

  if (fileName) {
    name = fileName;
  }

  if (identifier) {
    name = identifier;
  }

  if (isLoading && resourceId) {
    return <Skeleton h={32} />;
  }

  return (
    <Text
      variant="small03"
      maw={NODE_WIDTH - ICON_SIZE - 32}
      color="gray.8"
      sx={{
        flex: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      title={name}
    >
      {name}
    </Text>
  );
};

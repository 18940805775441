import first from 'lodash/first';
import get from 'lodash/get';
import { Layouts } from 'react-grid-layout';
import {
  DashboardModel,
  DashboardUILayoutModelV1VersionEnum,
  DashboardUILayoutModelV2VersionEnum,
} from '@/main/generated/api';
import { DashboardWithLayoutV1 } from '@/main/queries/dashboard/dashboard';
import { parseLayoutJson } from '@/main/queries/dashboard/util';

export interface DashboardLayoutModel {
  i: string;
  w: number;
  h: number;
  x: number;
  y: number;
}

export const addWidgetToDashboard = (
  dashboard: DashboardModel,
  currentNumCols: number, // Since dashboard can change column number when responsive, this tells the current state
  widgetId: string,
): Layouts => {
  const layout = dashboard.uiLayout;

  switch (layout.version) {
    case DashboardUILayoutModelV1VersionEnum.V1: {
      const layouts = parseLayoutJson(layout);

      const newLayoutItem = {
        i: widgetId,
        x: 0, // (layouts.lg.length * 2) % (currentNumCols || 12),
        y: Infinity, // puts it at the bottom
        w: 8,
        h: 8,
      };

      const updatedLayouts = Object.keys(layouts).reduce((acc, key) => {
        acc[key] = [...layouts[key], newLayoutItem];
        return acc;
      }, {} as Layouts);

      return updatedLayouts;
    }

    case DashboardUILayoutModelV2VersionEnum.V2:
    default:
      return {};
  }
};

export const removeWidgetFromDashboard = (dashboard: DashboardWithLayoutV1, widgetId: string) => {
  const layouts = dashboard.uiLayout.layout;

  return Object.keys(layouts).reduce((acc, key) => {
    acc[key] = layouts[key].filter(layoutItem => layoutItem.i !== widgetId);
    return acc;
  }, {} as Layouts);
};

export const getFirstVizFromLayouts = (dashboard?: DashboardWithLayoutV1) => {
  if (!dashboard) {
    return;
  }

  const layouts = get(dashboard, 'uiLayout.layout');

  // TODO:: centralize this logic
  //assert that sm is always present
  return first(layouts.sm);
};

import { Navigate, RouteProps, Routes } from 'react-router-dom';
import { UnavailablePage } from '../pages/Unavailable';
import { renderAuthRoute } from './AuthRouter';
import { AppRoutes, getRoute } from './constants';
import { lazyWithRetry } from './util';

const DataAnalytics = lazyWithRetry(() => import('../pages/DataAnalytics/DataAnalytics'));
const DataAnalyticsDetails = lazyWithRetry(
  () => import('../pages/DataAnalytics/DataAnalyticsDetails'),
);
const DataAnalyticsDashboard = lazyWithRetry(
  () => import('../pages/DataAnalytics/DataAnalyticsDashboard'),
);

const dataAnalyticsRoutes: RouteProps[] = [
  {
    path: '/:tabValue?',
    element: <DataAnalytics />,
  },
  {
    path: `${getRoute(AppRoutes.DATA_ANALYTICS_CHATS)}/${getRoute(
      AppRoutes.DATA_ANALYTICS_DETAILS,
    )}`,
    element: <DataAnalyticsDetails />,
  },
  {
    path: `${getRoute(AppRoutes.DASHBOARDS)}/${getRoute(AppRoutes.DASHBOARD_DETAILS)}`,
    element: <DataAnalyticsDashboard />,
  },
  {
    path: getRoute(AppRoutes.ERROR_ROUTE),
    element: <UnavailablePage />,
  },
  {
    path: '*',
    element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
  },
];

export const DataAnalyticsRouter = (): JSX.Element => (
  <Routes>{dataAnalyticsRoutes.map(renderAuthRoute)}</Routes>
);

import {
  IconFile,
  IconFileTypeCsv,
  IconFileTypeDoc,
  IconFileTypeHtml,
  IconFileTypeJpg,
  IconFileTypePdf,
  IconFileTypePng,
  IconFileTypeTxt,
  IconFileTypeXml,
} from '@tabler/icons-react';
import { Text } from '@/shared/design-system/v2';
import { FileData, UploadState, UploadStatus } from './types';

export const getFileIcon = (fileName: string) => {
  const extension = fileName.split('.').pop()?.toLowerCase();
  switch (extension) {
    case 'pdf':
      return <IconFileTypePdf size={16} />;
    case 'txt':
      return <IconFileTypeTxt size={16} />;
    case 'doc':
    case 'docx':
      return <IconFileTypeDoc size={16} />;
    case 'csv':
      return <IconFileTypeCsv size={16} />;
    case 'jpg':
    case 'jpeg':
      return <IconFileTypeJpg size={16} />;
    case 'png':
      return <IconFileTypePng size={16} />;
    case 'html':
      return <IconFileTypeHtml size={16} />;
    case 'xml':
      return <IconFileTypeXml size={16} />;
    default:
      return <IconFile size={16} />;
  }
};

export const getUploadStatus = (fileData: FileData[]): UploadStatus => {
  const completedCount = fileData.filter(f => f.state === UploadState.UPLOAD_SUCCESS).length;
  const totalCount = fileData.length;
  const percentComplete = totalCount > 0 ? Math.round((completedCount / totalCount) * 100) : 0;
  const hasErrors = fileData.some(f => f.state === UploadState.UPLOAD_ERRORED);
  const isInProgress = fileData.some(
    f => f.state === UploadState.UPLOAD_STARTED || f.state === UploadState.UPLOAD_QUEUED,
  );

  return {
    completedCount,
    totalCount,
    percentComplete,
    hasErrors,
    isInProgress,
  };
};

export const renderUploadStatusText = (status: UploadStatus) => {
  if (status.totalCount === 0) return null;

  return (
    <Text variant="small03" c="dimmed">
      {status.completedCount} of {status.totalCount} completed
      {' • '}
      {status.percentComplete}%{status.hasErrors && ' • Some files failed'}
    </Text>
  );
};

export const renderUploadCount = (count: number, status: UploadStatus) => (
  <Text variant="bodyShort02">
    {status.isInProgress ? 'Uploading' : 'Uploaded'} {count} files
  </Text>
);

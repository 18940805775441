import range from 'lodash/range';
import { SimpleGrid, Skeleton } from '@/shared/design-system/v2';

const CARD_HEIGHT = 206;

interface TemplatesSkeletonProps {
  numTemplates: number;
  isTemplatesHomePage?: boolean;
  height?: number;
}

export const TemplatesSkeleton = ({
  numTemplates,
  isTemplatesHomePage = false,
  height = CARD_HEIGHT,
}: TemplatesSkeletonProps): JSX.Element => (
  <SimpleGrid
    cols={isTemplatesHomePage ? 4 : 3}
    w="100%"
    spacing="lg"
    breakpoints={[
      { maxWidth: 'lg', cols: isTemplatesHomePage ? 4 : 3 },
      { maxWidth: 'md', cols: isTemplatesHomePage ? 3 : 2 },
      { maxWidth: 'sm', cols: isTemplatesHomePage ? 2 : 1 },
      { maxWidth: 'xs', cols: 1 },
    ]}
  >
    {range(numTemplates).map(value => (
      <Skeleton key={value} w="100%" h={height} />
    ))}
  </SimpleGrid>
);

import { IconBrain } from '@tabler/icons-react';
import get from 'lodash/get';
import { MouseEventHandler } from 'react';
import { ChartTypes } from '@/main/charts';
import { useBrainstormChatModal } from '@/main/components/dashboard/widget/chat/BrainstormChatModal';
import { useDashboardWithDataAnalyticsContext } from '@/main/contexts/dashboard/DashboardWithDataAnalyticsContext';
import {
  ChartType as ChartTypesEnum,
  TextConfigurationModelV1VersionEnum,
  WidgetModel,
  WidgetType,
  WidgetUIConfigurationModel,
} from '@/main/generated/api';
import {
  useGetDashboardQuery,
  useUpdateDashboardLayoutMutation,
} from '@/main/queries/dashboard/dashboard';
import {
  Box,
  Button,
  Card,
  CloseButton,
  Divider,
  Horizontal,
  ScrollArea,
  Text,
  Vertical,
  closeAllModals,
  openConfirmModal,
} from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';
import { VisualizationComponent } from '../../analyser/visualization/Visualization';
import { TableView } from '../../common/chat-with-data/chat/answers/TableView';
import { ChartView } from '../../common/chat-with-data/chat/answers/multi-chart/ChartView';
import { removeWidgetFromDashboard } from '../util';
import { TextView } from './TextView/TextView';
import { BrainstormTextViewContainer } from './chat/BrainstormTextView.container';
import { useWidgetStyles } from './widget.styles';

const convertDisplayTypeToChartType = (displayType: ChartTypesEnum): ChartTypes => {
  switch (displayType) {
    case ChartTypesEnum.Column:
      return ChartTypes.COLUMN;
    case ChartTypesEnum.Bar:
      return ChartTypes.BAR;
    case ChartTypesEnum.Bubble:
      return ChartTypes.PACKED_BUBBLE;
    case ChartTypesEnum.Pie:
      return ChartTypes.PIE;

    default:
      logger.error(`Unsupported chart type requested: ${displayType}`);
      // Fallback to default chart type.
      return ChartTypes.BAR;
  }
};

interface WidgetContentProps {
  title: string;
  type: WidgetType;
  configuration?: WidgetUIConfigurationModel;
  onVisualizationUpdate: (config: WidgetUIConfigurationModel) => void;
  rawData?: any[];
  widgetId: string;
  widget: WidgetModel;
}

export const WidgetContent = ({
  type,
  configuration,
  rawData,
  title,
  widgetId,
  onVisualizationUpdate,
  widget,
}: WidgetContentProps) => {
  const { classes } = useWidgetStyles();
  const { open: openBrainstormChatModal } = useBrainstormChatModal();
  const { dashboardId, isEditMode } = useDashboardWithDataAnalyticsContext();
  const { data: dashboard } = useGetDashboardQuery(dashboardId);

  const { mutateAsync: updateDashboardLayout } = useUpdateDashboardLayoutMutation();

  const handleWidgetRemove = () => {
    const layouts = dashboard?.uiLayout.layout;

    if (!layouts) {
      return;
    }

    const updatedLayouts = removeWidgetFromDashboard(dashboard, widgetId);

    updateDashboardLayout({
      dashboardId,
      layouts: updatedLayouts,
    });
  };

  const onWidgetRemove: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    e.preventDefault();

    openConfirmModal({
      title: <Text variant="heading03">Confirm removing widget</Text>,
      onCancel: () => closeAllModals(),
      onConfirm: () => handleWidgetRemove(),
      children: <Text variant="bodyShort01">Do you want to remove widget from dashboard?</Text>,
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red.7' },
    });
  };

  const handleOpenBrainstormChatModal = () => {
    if (dashboard) {
      openBrainstormChatModal({
        title,
        widgetId,
        widget,
        dashboard,
      });
    }
  };

  const renderActions = () => (
    <Horizontal spacing="xs">
      <Button
        variant="subtle"
        leftIcon={<IconBrain size={16} />}
        onClick={handleOpenBrainstormChatModal}
        onMouseDown={e => e.stopPropagation()}
        onTouchStart={e => e.stopPropagation()}
        size="xs"
        className={classes.action}
      >
        Brainstorm
      </Button>

      {isEditMode && (
        <CloseButton
          bg="white.0"
          className={classes.action}
          sx={theme => ({
            border: `1px solid ${theme.colors.gray[3]}`,
            boxShadow: theme.shadows.sm,
          })}
          onClick={onWidgetRemove}
          onMouseDown={e => e.stopPropagation()}
          onTouchStart={e => e.stopPropagation()}
        />
      )}
    </Horizontal>
  );

  if (type === 'table' && rawData) {
    if (!widget.brainstormConfiguration) {
      const hasSingleDataPoint = rawData.length === 1 && Object.keys(rawData[0]).length === 1;
      const chartConfiguration = configuration?.chart;
      if (hasSingleDataPoint) {
        return (
          <ChartView
            title={title}
            cardProps={{
              className: classes.card,
              withBorder: false,
              hoverEffect: false,
            }}
            titleProps={{ p: 0 }}
            actions={renderActions()}
            chartType={ChartTypes.WORD_CLOUD}
            xAxisTitle={''}
            yAxisTitle={''}
            xColName={get(chartConfiguration, 'xAxis.columns.0', '')}
            yColName={get(chartConfiguration, 'yAxis.columns.0', '')}
            rawData={rawData}
          />
        );
      }

      return (
        <Card
          h="100%"
          py="md"
          px="lg"
          hoverEffect={isEditMode}
          withBorder
          radius="md"
          onClick={e => e.stopPropagation()}
          className={classes.card}
        >
          <Vertical h="100%">
            <VisualizationComponent.Header actions={renderActions()}>
              <VisualizationComponent.Header.Title title={title} />
            </VisualizationComponent.Header>
            <Box h="calc(100% - 24px)">
              <TableView content={rawData} />
            </Box>
          </Vertical>
        </Card>
      );
    }

    return (
      <Card
        h="100%"
        py="md"
        px="lg"
        hoverEffect={isEditMode}
        withBorder
        radius="md"
        onClick={e => e.stopPropagation()}
        className={classes.card}
      >
        <Vertical h="50%">
          <VisualizationComponent.Header actions={renderActions()}>
            <VisualizationComponent.Header.Title title={title} />
          </VisualizationComponent.Header>
          <Box h="calc(100% - 24px)">
            <TableView content={rawData} />
          </Box>
        </Vertical>
        <Divider />
        <BrainstormTextViewContainer widget={widget} />
      </Card>
    );
  }

  if (type === 'text' && configuration?.text?.version === 'v1') {
    const initialContent = configuration?.text?.content ?? '';
    const handleContentUpdate = (content: string) => {
      onVisualizationUpdate({
        ...configuration,
        text: { version: TextConfigurationModelV1VersionEnum.V1, ...configuration?.text, content },
      });
    };

    return (
      <TextView
        initialContent={initialContent}
        editable={isEditMode}
        onContentChange={handleContentUpdate}
        onWidgetRemove={isEditMode ? onWidgetRemove : undefined}
      />
    );
  }

  if (type === 'chart' && configuration?.chart?.version === 'v1' && rawData) {
    const chartConfiguration = configuration.chart;

    if (!widget.brainstormConfiguration) {
      return (
        <>
          <ChartView
            title={title}
            cardProps={{ radius: 'md', className: classes.card, hoverEffect: isEditMode }}
            titleProps={{ p: 0 }}
            actions={renderActions()}
            chartType={convertDisplayTypeToChartType(chartConfiguration.type)}
            xAxisTitle={chartConfiguration.xAxis.title ?? ''}
            yAxisTitle={chartConfiguration.yAxis.title ?? ''}
            xColName={get(chartConfiguration, 'xAxis.columns.0', '')}
            yColName={get(chartConfiguration, 'yAxis.columns.0', '')}
            rawData={rawData}
          />
        </>
      );
    }

    return (
      <Card h="100%" radius="md" sx={{ borderRadius: '8px' }} bg="white.0">
        <ScrollArea h="100%">
          <ChartView
            title={title}
            cardProps={{
              className: classes.card,
              withBorder: false,
              hoverEffect: false,
            }}
            titleProps={{ p: 0 }}
            actions={renderActions()}
            chartType={convertDisplayTypeToChartType(chartConfiguration.type)}
            xAxisTitle={chartConfiguration.xAxis.title ?? ''}
            yAxisTitle={chartConfiguration.yAxis.title ?? ''}
            xColName={get(chartConfiguration, 'xAxis.columns.0', '')}
            yColName={get(chartConfiguration, 'yAxis.columns.0', '')}
            rawData={rawData}
          />
          <Divider />
          <BrainstormTextViewContainer widget={widget} />
        </ScrollArea>
      </Card>
    );
  }

  return <></>;
};

import get from 'lodash/get';
import { ChartTypes } from '@/main/charts';
import { TableView } from '@/main/components/common/chat-with-data/chat/answers/TableView';
import { ChartView } from '@/main/components/common/chat-with-data/chat/answers/multi-chart/ChartView';
import { BrainstormModeChatWithWidget } from '@/main/components/dashboard/widget/chat/BrainstormModeChatWithWidget';
import { ChartType as ChartTypesEnum, WidgetModel } from '@/main/generated/api';
import { DashboardWithLayoutV1 } from '@/main/queries/dashboard/dashboard';
import { useGetWidgetDataQuery, useGetWidgetDetailsQuery } from '@/main/queries/dashboard/widget';
import {
  Alert,
  Box,
  Card,
  Center,
  Horizontal,
  Loader,
  Text,
  openModal,
} from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';
import { BrainstormTextViewContainer } from './BrainstormTextView.container';

const MODAL_ID = 'brainstorm-chat-modal';

// TODO: Fix this
const convertDisplayTypeToChartType = (displayType: ChartTypesEnum): ChartTypes => {
  switch (displayType) {
    case ChartTypesEnum.Column:
      return ChartTypes.COLUMN;
    case ChartTypesEnum.Bar:
      return ChartTypes.BAR;
    case ChartTypesEnum.Bubble:
      return ChartTypes.PACKED_BUBBLE;
    case ChartTypesEnum.Pie:
      return ChartTypes.PIE;
    default:
      logger.error(`Unsupported chart type requested: ${displayType}`);
      return ChartTypes.BAR;
  }
};

interface BrainstormChatModalProps {
  widgetId: string;
  title: string;
  dashboard: DashboardWithLayoutV1;
}

const BrainstormContainer = ({ widgetId, title, dashboard }: BrainstormChatModalProps) => {
  const { isLoading, isFetching, isError, data: widget } = useGetWidgetDetailsQuery(widgetId);
  const { data: rawData } = useGetWidgetDataQuery(widget?.widgetId);

  let leftContent;

  if (isLoading || isFetching) {
    leftContent = (
      <Center w="100%" h="100%">
        <Loader text="loading widget info" />
      </Center>
    );
  } else if (isError) {
    leftContent = (
      <Center w="100%" h="100%">
        <Alert color="red">Error loading viz data</Alert>
      </Center>
    );
  } else {
    const type = widget.widgetType;
    const configuration = widget.uiConfiguration;

    leftContent = (
      <Card h="100%" withBorder hoverEffect={false}>
        {type === 'table' && rawData && (
          <Box h={widget.brainstormConfiguration ? '50%' : '100%'}>
            <TableView content={rawData} />
          </Box>
        )}
        {type === 'chart' && configuration?.chart?.version === 'v1' && rawData && (
          <ChartView
            title={title}
            cardProps={{ withBorder: false, h: widget.brainstormConfiguration ? '50%' : '100%' }}
            chartType={convertDisplayTypeToChartType(configuration.chart.type)}
            xAxisTitle={configuration.chart.xAxis.title ?? ''}
            yAxisTitle={configuration.chart.yAxis.title ?? ''}
            xColName={get(configuration.chart, 'xAxis.columns.0', '')}
            yColName={get(configuration.chart, 'yAxis.columns.0', '')}
            rawData={rawData}
          />
        )}
        {widget.brainstormConfiguration ? <BrainstormTextViewContainer widget={widget} /> : null}
      </Card>
    );
  }

  return (
    <Horizontal h="76vh" spacing="md" grow>
      {leftContent}
      <BrainstormModeChatWithWidget widgetId={widgetId} dashboard={dashboard} />
    </Horizontal>
  );
};

interface useBrainstormChatModalProps {
  title: string;
  widgetId: string;
  widget: WidgetModel;
  dashboard: DashboardWithLayoutV1;
}

export const useBrainstormChatModal = () => {
  const open = ({ title, widgetId, widget, dashboard }: useBrainstormChatModalProps) =>
    openModal({
      modalId: MODAL_ID,
      title: <Text variant="heading03">Brainstorm: {title}</Text>,
      centered: true,
      size: '90vw',
      styles: {
        title: {
          marginInline: 'auto',
        },
        close: {
          margin: 0,
        },
      },
      children: <BrainstormContainer widgetId={widgetId} title={title} dashboard={dashboard} />,
    });

  return { open };
};

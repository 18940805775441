import {
  IconBook,
  IconCalendarBolt,
  IconCircleCaretRight,
  IconMovie,
  IconSearch,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { MARKOVML_DOCS } from '@/main/developer-docs/links';
import { useGetUserInfoQuery } from '@/main/queries/account/workspace';
import { useAbsoluteRoutes } from '@/main/router/hooks';
import {
  Box,
  Button,
  Container,
  Horizontal,
  Image,
  Text,
  UnstyledButton,
  Vertical,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { useNewChatModal } from '../../data-analytics/home/NewChatModal';
import { AppFeatureCard } from './AppFeatureCard';
import { IntroCard } from './IntroCard';
import { useIntroVideoModal } from './useIntroVideoModal';
import { useScheduleTrialModal } from './useScheduleTrialModal';

const HERO_ILLUSTRATION =
  'https://ik.imagekit.io/markovml/homepage/multi-ui-preview_TE-BmXxgI.svg?updatedAt=1739416414171';
const CHAT_WITH_DATA_ILLUSTRATION =
  'https://ik.imagekit.io/markovml/homepage/chat-with-data_Xth2o7I4S.svg?updatedAt=1739418202749';
const AI_APPS_ILLUSTRATION =
  'https://ik.imagekit.io/markovml/homepage/ai-apps_vVoYwD-Pr.svg?updatedAt=1739418198541';
const AUTOMATIONS_ILLUSTRATION =
  'https://ik.imagekit.io/markovml/homepage/automation-flow_OlHTbQfGT.svg?updatedAt=1739463426554';

export const GlobalHome = (): JSX.Element => {
  const theme = useMarkovTheme();
  const navigate = useNavigate();
  const { getDataAnalyticsDetailsRoute, getWorkflowsAppsRoute, getWorkflowsHomeRoute } =
    useAbsoluteRoutes();

  const handleOnDataAnalyticsCreate = (resourceId: string) => {
    navigate(getDataAnalyticsDetailsRoute(resourceId));
  };

  const { open: openNewChatModal } = useNewChatModal({
    onDataAnalyticsCreate: handleOnDataAnalyticsCreate,
  });
  const { open: openScheduleTrial } = useScheduleTrialModal();
  const { open: openIntroVideo } = useIntroVideoModal();

  const { data: user } = useGetUserInfoQuery();

  const handleNavigateToDataAnalytics = () => {
    openNewChatModal();
  };

  const handleNavigateToApps = () => {
    navigate(getWorkflowsAppsRoute());
  };

  const handleNavigateToAutomations = () => {
    navigate(getWorkflowsHomeRoute());
  };

  const handleNavigateVideoTutorials = () => {
    window.open(`${MARKOVML_DOCS}/docs/video-tutorials`);
  };

  const handleNavigateDocumentation = () => {
    window.open(`${MARKOVML_DOCS}/docs/welcome-to-markovml-docs `);
  };

  const handleNavigateFAQs = () => {
    window.open(`${MARKOVML_DOCS}/docs/faqs`);
  };

  const features = [
    {
      title: 'Chat with your data',
      description:
        'Visualize and chat with your data to generate charts and reports for smarter decision-making.',
      ctaLabel: 'Explore Data Insights',
      illustrationUrl: CHAT_WITH_DATA_ILLUSTRATION,
      onClick: handleNavigateToDataAnalytics,
    },
    {
      title: 'Get Things Done with AI Apps',
      description:
        'Accelerate your GTM campaigns with AI-powered apps that deliver results instantly.',
      ctaLabel: 'Discover AI Apps',
      illustrationUrl: AI_APPS_ILLUSTRATION,
      onClick: handleNavigateToApps,
    },
    {
      title: 'Automation flows',
      description: 'Automate tasks with AI flows designed to ease your day-to-day GTM work.',
      ctaLabel: 'Explore Flow Automation',
      illustrationUrl: AUTOMATIONS_ILLUSTRATION,
      onClick: handleNavigateToAutomations,
    },
  ];

  const introCards = [
    {
      title: 'Book a Free Consultation',
      buttonText: 'Schedule a call with expert',
      icon: <IconCalendarBolt size={24} color={theme.colors.purple[2]} />,
      onClick: () => openScheduleTrial(),
    },
    {
      title: 'MarkovML in 45 Seconds',
      buttonText: 'Play intro video',
      icon: <IconMovie size={24} color={theme.colors.purple[2]} />,
      onClick: () => openIntroVideo(),
    },
  ];

  const userFirstName = user?.name?.split(' ')?.at(0) ?? '';
  const heroMessage = userFirstName ? `Welcome aboard, ${userFirstName}` : 'Welcome aboard';

  return (
    <Box bg="white.0" mih="100%">
      <Container size="md" py={32}>
        <Vertical spacing="xxl">
          <Vertical
            p="xxl"
            pb={32}
            bg="linear-gradient(90deg, rgba(233, 239, 255, 0.6) 0%, rgba(244, 237, 251, 0.6) 100%)"
            sx={theme => ({
              border: `1px solid {theme.colors.blue[0]}`,
              borderRadius: theme.radius.sm,
            })}
          >
            <Horizontal noWrap spacing={66} align="start">
              <Box pt="lg" pl="xxl">
                <Vertical spacing="xs" mih={170}>
                  <Text variant="heading02">{heroMessage}</Text>
                  <Text variant="bodyShort01" color="gray.8">
                    Fast-forward your pipelines with our range of offerings. Analyze data, automate
                    tasks, and get things done effortlessly.
                  </Text>
                </Vertical>
                <Vertical spacing="sm">
                  <Text variant="subTitle03" color="gray.7">
                    Start your MarkovML journey!
                  </Text>
                  <Horizontal spacing="lg">
                    <Button variant="primary" onClick={handleNavigateToApps}>
                      <Text variant="subTitle04" color="white.0">
                        Discover AI Apps
                      </Text>
                    </Button>
                    <UnstyledButton onClick={handleNavigateToDataAnalytics}>
                      <Text variant="subTitle03" color="blue.7" sx={{ cursor: 'pointer' }}>
                        or Get Insights from Data
                      </Text>
                    </UnstyledButton>
                  </Horizontal>
                </Vertical>
              </Box>
              <Box w={500} sx={{ flexShrink: 0 }}>
                <Image src={HERO_ILLUSTRATION} w="100%" />
                <Vertical>
                  <Text variant="overline" color="gray.6" tt="uppercase">
                    Your Quick Start Guide
                  </Text>
                  <Horizontal spacing="xs">
                    {/* TODO: Add links for these buttons */}
                    <Button
                      color="white"
                      leftIcon={<IconCircleCaretRight size={20} color={theme.colors.gray[8]} />}
                      onClick={handleNavigateVideoTutorials}
                    >
                      <Text variant="subTitle03" color="gray.8">
                        Video Tutorials
                      </Text>
                    </Button>
                    <Button
                      color="white"
                      leftIcon={<IconBook size={20} color={theme.colors.gray[8]} />}
                      onClick={handleNavigateDocumentation}
                    >
                      <Text variant="subTitle03" color="gray.8">
                        Documentation
                      </Text>
                    </Button>
                    <Button
                      color="white"
                      leftIcon={<IconSearch size={20} color={theme.colors.gray[8]} />}
                      onClick={handleNavigateFAQs}
                    >
                      <Text variant="subTitle03" color="gray.8">
                        FAQs
                      </Text>
                    </Button>
                  </Horizontal>
                </Vertical>
              </Box>
            </Horizontal>
          </Vertical>
          <Horizontal noWrap spacing="xxl">
            {introCards.map(card => (
              <IntroCard
                key={card.title}
                title={card.title}
                buttonText={card.buttonText}
                icon={card.icon}
                onClick={card.onClick}
              />
            ))}
          </Horizontal>
          <Text variant="bodyLong02" color="gray.6">
            Explore our offerings, use cases, and more.
          </Text>
          <Vertical spacing="xxl">
            {features.map(({ title, description, ctaLabel, illustrationUrl, onClick }, i) => (
              <AppFeatureCard
                key={i}
                title={title}
                description={description}
                ctaLabel={ctaLabel}
                illustrationUrl={illustrationUrl}
                onClick={onClick}
              />
            ))}
          </Vertical>
        </Vertical>
      </Container>
    </Box>
  );
};

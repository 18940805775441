import { isTempVisualizationId } from '@/main/queries/dashboard/dashboard-update.utils';
import { useGetWidgetDetailsQuery } from '@/main/queries/dashboard/widget';
import { Alert, Card, Center, Loader } from '@/shared/design-system/v2';
import { Widget } from './Widget';

interface WidgetContainerProps {
  widgetId: string;
}

export const WidgetContainer = ({ widgetId }: WidgetContainerProps) => {
  const { isLoading, isError, data: widget } = useGetWidgetDetailsQuery(widgetId);

  if (isLoading || isTempVisualizationId(widgetId)) {
    return (
      <Card withBorder h="100%" w="100%" bg="white.0" radius="md">
        <Center h="100%" w="100%">
          <Loader text="Loading widget..." />
        </Center>
      </Card>
    );
  }

  if (isError) {
    return (
      <Card withBorder h="100%" w="100%" bg="white.0" radius="md">
        <Center h="100%" w="100%">
          <Alert color="red">Failed to load widget info</Alert>
        </Center>
      </Card>
    );
  }

  if (!widget) {
    return (
      <Card withBorder h="100%" w="100%" bg="white.0">
        <Center h="100%" w="100%">
          <Alert>Widget info not found</Alert>
        </Center>
      </Card>
    );
  }

  return <Widget widget={widget} />;
};

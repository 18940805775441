import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { workflowApi } from '@/shared/lib/api';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { CreateWorkflowRunRequest, WorkflowRunStatus } from '../../../generated/api';
import { debugRunKeys } from '../debug';
import { workflowsQueryKeys } from '../list/list';

export const useCreateWorkflowRunMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: CreateWorkflowRunRequest) =>
      workflowApi.createWorkflowRunV1(workspaceId, workflowId, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: workflowsQueryKeys.all,
        });
        queryClient.invalidateQueries({
          queryKey: workflowsQueryKeys.listAllRuns(workspaceId, workflowId),
        });
      },
    },
  );
};

export const useGetWorkflowRunDetailsQuery = (workflowId: string, workflowRunId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    workflowsQueryKeys.runsDetail(workspaceId, workflowId, workflowRunId),
    () => workflowApi.getWorkflowRunV1(workspaceId, workflowId, workflowRunId),
    {
      enabled: Boolean(workflowId && workflowRunId),
      select: res => res.data,
    },
  );
};

export const useStopWorkflowRunMutation = (workflowId: string, workflowRunId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(() => workflowApi.stopWorkflowRunV1(workspaceId, workflowId, workflowRunId), {
    onSuccess: () => {
      queryClient.invalidateQueries(
        debugRunKeys.debugRunStatus(workspaceId, workflowId, workflowRunId),
      );
      queryClient.invalidateQueries(
        workflowsQueryKeys.runsDetail(workspaceId, workflowId, workflowRunId),
      );
      queryClient.invalidateQueries(workflowsQueryKeys.list(workspaceId));
    },
  });
};

export const useGetWorkflowRunCreditUsageQuery = (
  workflowId: string,
  workflowRunId: string,
  status?: WorkflowRunStatus,
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    workflowsQueryKeys.runsCreditDetail(workspaceId, workflowId, workflowRunId),
    () => workflowApi.getWorkflowRunCreditUsageV1(workspaceId, workflowId, workflowRunId, false),
    {
      enabled: Boolean(workflowId && workflowRunId && status === WorkflowRunStatus.Success),
      select: res => res.data,
    },
  );
};

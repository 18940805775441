import debounce from 'lodash/debounce';
import { useCallback, useEffect } from 'react';
import { useDashboardWithDataAnalyticsContext } from '@/main/contexts/dashboard/DashboardWithDataAnalyticsContext';
import { WidgetModel, WidgetUIConfigurationModel } from '@/main/generated/api';
import { useUpdateVisualizationConfigurationMutation } from '@/main/queries/dashboard/widget';
import { WidgetContent } from './WidgetContent';
import { WidgetContentContainer } from './WidgetContent.container';

interface WidgetProps {
  widget: WidgetModel;
}

export const Widget = ({ widget }: WidgetProps) => {
  const { mutate: updateVisualizationConfiguration } = useUpdateVisualizationConfigurationMutation(
    widget.widgetId,
  );
  const { addDataSourceId } = useDashboardWithDataAnalyticsContext();

  useEffect(() => {
    const dataSourceId = widget?.dataConfiguration?.dataSourceId;
    if (dataSourceId) {
      // Track all the data sources used in the dashboard
      addDataSourceId(dataSourceId);
    }
  }, [widget, addDataSourceId]);

  const handleVisualizationUpdate = useCallback(
    debounce((configuration: WidgetUIConfigurationModel) => {
      updateVisualizationConfiguration(configuration);
    }, 500),
    [],
  );

  if (widget.widgetType === 'text') {
    return (
      <WidgetContent
        // Figure out fix for this
        title={widget.name as string}
        type={widget.widgetType}
        configuration={widget.uiConfiguration}
        onVisualizationUpdate={handleVisualizationUpdate}
        widgetId={widget.widgetId}
        widget={widget}
      />
    );
  }

  return (
    <WidgetContentContainer
      widget={widget}
      title={widget.name as string}
      onVisualizationUpdate={handleVisualizationUpdate}
    />
  );
};

import { IconTable } from '@tabler/icons-react';
import { Horizontal, Radio, Text, Tooltip, useMarkovTheme } from '@/shared/design-system/v2';
import { DataSource } from './types';

interface DataSourceItemProps {
  source: DataSource;
  isSelected: boolean;
  onSelect: (resourceId: string) => void;
}

export const DataSourceItem = ({ source, isSelected, onSelect }: DataSourceItemProps) => {
  const theme = useMarkovTheme();

  return (
    <Radio
      value={source.resourceId}
      checked={isSelected}
      size="xs"
      onChange={() => onSelect(source.resourceId)}
      label={
        <Horizontal align="center" spacing="xs" sx={{ cursor: 'pointer' }}>
          <IconTable size={16} color={isSelected ? theme.colors.blue[6] : theme.colors.gray[7]} />
          <Tooltip openDelay={500} label={source.resourceName} withinPortal withArrow>
            <Text
              variant="bodyShort03"
              color="gray.7"
              maw={250}
              fw={isSelected ? 500 : undefined}
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {source.resourceName}
            </Text>
          </Tooltip>
        </Horizontal>
      }
      sx={theme => ({
        width: '100%',
        padding: theme.spacing.sm,
        borderRadius: theme.radius.sm,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.colors.gray[0],
        },
        backgroundColor: isSelected ? theme.colors.blue[0] : undefined,
      })}
    />
  );
};

import { SimpleGridBreakpoint } from '@mantine/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import { sendAnalytics } from '@/main/initializers/analytics';
import {
  IllustrationAutoGrade,
  IllustrationChatWithMarko,
  IllustrationCopyEdit,
  IllustrationLessonPlanner,
  IllustrationQnaWithPdf,
  IllustrationRetailAi,
  IllustrationSummarize,
  IllustrationSupplementGenerator,
  IllustrationTeacherAssistant,
} from '@/shared/design-system';
import { Vertical } from '@/shared/design-system/v2';
import { appStoreEvents } from '../../analytics/appStoreEvents';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { useAbsoluteRoutes } from '../../router/hooks';
import { CardGrid } from '../workspace/home-v2/CardGrid';
import {
  COMING_SOON_BUTTON_TEXT,
  DEFAULT_BUTTON_TEXT,
  SEE_DEMO_BUTTON_TEXT,
  SubscribedAppsCard,
} from './SubscribedAppsCard';
import { RETAIL_AI_APP_NAME } from './retail-ai/home/util';
import {
  AUTO_GRADER_APP_NAME,
  CHAT_WITH_MARKOV_APP_NAME,
  COPY_COMPASS_APP_NAME,
  COPY_EDIT_APP_NAME,
  LESSON_PLANNER,
  QNA_WITH_PDF_APP_NAME,
  SUMMARIZE_APP_NAME,
  SUPPLEMENT_GENERATOR_APP_NAME,
  TEACHER_ASSISTANT_APP_NAME,
} from './util';

const defaultBreakpoints = [
  { maxWidth: 'lg', cols: 4 },
  { maxWidth: 'md', cols: 3 },
  { maxWidth: 'xs', cols: 1 },
];

interface EdTechAppsContainerProps {
  cols?: number;
  breakpoints?: SimpleGridBreakpoint[];
}

export const EdTechAppsContainer = ({
  cols = 5,
  breakpoints = defaultBreakpoints,
}: EdTechAppsContainerProps) => {
  const { workspaceId } = useAppMetadata();
  const navigate = useNavigate();
  const {
    featureAppQnaWithDoc,
    featureAppInstructorAi,
    featureChatWithMarkov,
    featureAutoGrader,
    featureAppSupplementGenerator,
    featureAppLessonPlanner,
    featureRetailAiApp,
    featureCopyEdit,
    featureCopyCompassCard,
  } = useFlags();
  const {
    getCopyEditRoute,
    getSummarizationRoute,
    getChatWithMarkoRoute,
    getDocQnARoute,
    getRetailAiRoute,
  } = useAbsoluteRoutes();

  const handleNavigateToArcadeSupplementGenerator = () => {
    window.open('https://app.arcade.software/share/D83KcWtPnejCinFdvIfc', '_blank');
  };
  const handleNavigateToCopyEdit = () => {
    sendAnalytics(
      appStoreEvents.apps.launchApp({
        workspaceId: workspaceId,
      }),
    );
    navigate(getCopyEditRoute());
  };

  const handleNavigateToCopyCompass = () => {
    window.open(`//${import.meta.env.VITE_APP_COPY_COMPASS_HOST}/${workspaceId}`);
  };

  const apps = [
    {
      appId: 'copy-edit',
      appName: COPY_EDIT_APP_NAME,
      description: `Collaborative workflow to improve learning material's clarity, readability, and coherence by correcting grammar, punctuation, spelling, and syntax errors`,
      author: 'MarkovML',
      onClick: handleNavigateToCopyEdit,
      icon: <IllustrationCopyEdit height="100%" />,
      isVisible: featureCopyEdit,
    },
    {
      appId: 'copy-compass',
      appName: COPY_COMPASS_APP_NAME,
      description: `An all-in-one platform to research, write, edit and format using AI`,
      author: 'MarkovML',
      onClick: handleNavigateToCopyCompass,
      icon: <IllustrationCopyEdit height="100%" />,
      isVisible: featureCopyCompassCard,
    },
    {
      appId: 'retail-ai',
      appName: RETAIL_AI_APP_NAME,
      description:
        'Optimize your product listings with our AI-powered app for title and description recommendations, keyword analysis, and reviews analysis.',
      author: 'MarkovML',
      onClick: () => navigate(getRetailAiRoute()),
      icon: <IllustrationRetailAi height="100%" />,
      isVisible: featureRetailAiApp,
      buttonText: DEFAULT_BUTTON_TEXT,
    },
    {
      appId: 'summarization',
      appName: SUMMARIZE_APP_NAME,
      description:
        'Condense long-form learning material into concise and relevant summaries for quick learning',
      author: 'MarkovML',
      onClick: () => navigate(getSummarizationRoute()),
      icon: <IllustrationSummarize height="100%" />,
      isVisible: true,
    },
    {
      appId: 'qna-with-pdf',
      appName: QNA_WITH_PDF_APP_NAME,
      description:
        'A digital assistant for learners crafted to boost the learning experience via engaging conversations with educational content',
      author: 'MarkovML',
      icon: <IllustrationQnaWithPdf height="100%" />,
      onClick: () => navigate(getDocQnARoute()),
      isVisible: featureAppQnaWithDoc !== 'NOT_AVAILABLE',
      buttonText:
        featureAppQnaWithDoc === 'AVAILABLE' ? COMING_SOON_BUTTON_TEXT : DEFAULT_BUTTON_TEXT,
    },
    {
      appId: 'supplement-generator',
      appName: SUPPLEMENT_GENERATOR_APP_NAME,
      description:
        'Transform your long-form learning material into various digital formats, creating tailored supplemental resources for learners',
      author: 'MarkovML',
      icon: <IllustrationSupplementGenerator height="100%" />,
      // Add onClick
      onClick: handleNavigateToArcadeSupplementGenerator,
      isVisible: featureAppSupplementGenerator !== 'NOT_AVAILABLE',
      buttonText:
        featureAppSupplementGenerator === 'AVAILABLE'
          ? COMING_SOON_BUTTON_TEXT
          : SEE_DEMO_BUTTON_TEXT,
    },
    {
      appId: 'teacher-assistant',
      appName: TEACHER_ASSISTANT_APP_NAME,
      description: `A digital assistant for instructors to create personalized learning material, assist with instructions, and enhance classroom engagement`,
      author: 'MarkovML',
      icon: <IllustrationTeacherAssistant height="100%" />,
      isVisible: featureAppInstructorAi !== 'NOT_AVAILABLE',
      buttonText: COMING_SOON_BUTTON_TEXT,
    },
    {
      appId: 'edtech-lesson-planner',
      appName: LESSON_PLANNER,
      description: `Create collaborative workflow to improve learning material's clarity, readability and coherence`,
      author: 'MarkovML',
      icon: <IllustrationLessonPlanner height="100%" />,
      isVisible: featureAppLessonPlanner !== 'NOT_AVAILABLE',
      buttonText: COMING_SOON_BUTTON_TEXT,
    },
    {
      appId: 'chat-with-marko',
      appName: CHAT_WITH_MARKOV_APP_NAME,
      description:
        'MarkovML-powered LLM with conversational abilities to help with content generation and grammar correction for context-aware linguistic assistance',
      author: 'MarkovML',
      onClick: () => navigate(getChatWithMarkoRoute()),
      icon: <IllustrationChatWithMarko height="100%" />,
      isVisible: featureChatWithMarkov !== 'NOT_AVAILABLE',
      buttonText:
        featureChatWithMarkov === 'AVAILABLE' ? COMING_SOON_BUTTON_TEXT : DEFAULT_BUTTON_TEXT,
    },
    {
      appId: 'auto-grade',
      appName: AUTO_GRADER_APP_NAME,
      description:
        'Automatically assesses and grades student assignments, quizzes, or exams, providing immediate, consistent, and unbiased evaluations using AI',
      author: 'MarkovML',
      icon: <IllustrationAutoGrade height="100%" />,
      isVisible: featureAutoGrader !== 'NOT_AVAILABLE',
      buttonText: COMING_SOON_BUTTON_TEXT,
    },
  ];

  return (
    <section aria-labelledby="apps-section">
      <Vertical spacing={24}>
        <CardGrid breakpoints={breakpoints} cols={cols} spacing={28} pb={24}>
          {apps
            .filter(app => app.isVisible)
            .map(app => (
              <SubscribedAppsCard
                key={app.appId}
                appId={app.appId}
                appName={app.appName}
                author={app.author}
                description={app.description}
                icon={app.icon}
                onButtonClick={app.onClick}
                buttonText={app.buttonText}
              />
            ))}
        </CardGrid>
      </Vertical>
    </section>
  );
};

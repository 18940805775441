import { useCallback, useMemo, useState } from 'react';
import { Layout, Layouts } from 'react-grid-layout';
import { useDashboardWithDataAnalyticsContext } from '@/main/contexts/dashboard/DashboardWithDataAnalyticsContext';
import {
  DashboardWithLayoutV1,
  useUpdateDashboardLayoutMutation,
} from '@/main/queries/dashboard/dashboard';
import { isTempVisualizationId } from '@/main/queries/dashboard/dashboard-update.utils';
import { Box, ScrollArea } from '@/shared/design-system/v2';
import { GridLayout } from '../common/grid-layout/GridLayout';
import { useStyles } from './Dashboard.styles';
import { WidgetContainer } from './widget/Widget.container';

const isLayoutSame = (l: Layout[], layout: Layout[]) => {
  if (!layout || l.length !== layout.length) {
    return false;
  }

  let isSame = true;

  l.forEach(l => {
    const currLayout = layout.find(layoutItem => layoutItem.i === l.i);

    if (!currLayout) {
      isSame = false;
      return;
    }

    isSame =
      currLayout.w === l.w && currLayout.h === l.h && currLayout.x === l.x && currLayout.y === l.y;
  });

  return isSame;
};

const hasTempViz = (allLayouts: Layouts) => {
  let hasTemp = false;
  Object.keys(allLayouts).forEach((breakPoint: string) => {
    allLayouts[breakPoint].forEach(layout => {
      if (hasTemp) {
        return;
      }
      hasTemp = isTempVisualizationId(layout.i);
    });
  });

  return hasTemp;
};

interface DashboardProps {
  dashboard: DashboardWithLayoutV1;
}

export const Dashboard = ({ dashboard }: DashboardProps) => {
  const [responsiveState, setResponsiveState] = useState({
    breakpoint: 'sm',
    cols: 24,
  });

  const { mutateAsync: updateDashboard } = useUpdateDashboardLayoutMutation();
  const { isEditMode } = useDashboardWithDataAnalyticsContext();
  const { cx, classes } = useStyles();

  const layouts: Layouts = dashboard.uiLayout.layout;

  const handleLayoutChange = useCallback(
    (currentLayout: Layout[], allLayouts: Layouts) => {
      // React grid layout calls this function as soon as rendered.
      // this calls API unnecessarily. Adding this check to prevent
      // the call and re-rendering. Need to figure out a better way to handle this
      // FIXIT
      if (isLayoutSame(currentLayout, layouts[responsiveState.breakpoint])) {
        return;
      }

      // TEMP-ID- is a temporary ID for a widget that is not yet created.
      // We don't want to save this to the dashboard.
      // TODO: Find a better way to handle this
      if (hasTempViz(allLayouts)) {
        return;
      }

      updateDashboard({
        ...dashboard,
        layouts: allLayouts,
      });
    },
    [layouts, responsiveState],
  );

  const handleBreakpointChange = (breakpoint: string, newCols: number) => {
    setResponsiveState({
      breakpoint,
      cols: newCols,
    });
  };

  const handleWidgetRemove = (widgetId: string) => {
    updateDashboard({
      ...dashboard,
      // Some issue with BE types
      layouts: Object.entries(layouts).reduce((acc, [key, val]) => {
        acc[key] = val.filter(layoutItem => layoutItem.i !== widgetId);
        return acc; // Return the updated accumulator
      }, {} as { [key: string]: Layout[] }),
    });
  };

  const children = useMemo(
    () =>
      (layouts[responsiveState.breakpoint] ?? layouts.lg).map((l: any) => (
        <GridLayout.Item key={l.i} data-layout-id={l.i}>
          <WidgetContainer widgetId={l.i} />
        </GridLayout.Item>
      )),
    [layouts, responsiveState],
  );

  const headerHeight = isEditMode ? 61 : 0;
  const scrollAreaHeight = `calc(100% - ${headerHeight}px)`;

  return (
    <Box className={cx({ [classes.grid]: isEditMode })} h={scrollAreaHeight}>
      <ScrollArea w="100%" h="100%">
        <GridLayout
          onLayoutChange={isEditMode ? handleLayoutChange : undefined}
          readOnlyMode={!isEditMode}
          layouts={layouts}
          onBreakpointChange={handleBreakpointChange}
        >
          {children}
        </GridLayout>
      </ScrollArea>
    </Box>
  );
};

import noop from 'lodash/noop';
import { useEffect, useRef } from 'react';
import { ScrollArea, VerticalList, createStyles } from '@/shared/design-system/v2';
import { useWorkflowRunContext } from '../../../../contexts/workflows/WorkflowRunContext';
import { WorkflowRun } from '../../../../generated/api';
import { WorkflowRunListItem } from './WorkflowRunListItem';

const useListItemStyles = createStyles(theme => ({
  listItem: {
    borderRadius: '8px',
    '.mantine-List-itemWrapper': {
      width: '100%',
    },
  },
  clickable: {
    cursor: 'pointer',
    ':hover': {
      background: theme.colors.gray[1],
    },
  },
  selected: {
    background: theme.colors.blue[1],
    ':hover': {
      background: theme.colors.blue[1],
    },
  },
}));

export interface WorkflowRunsListProps {
  workflowRuns: Array<WorkflowRun>;
  onWorkflowRunDetailsClick: (id: string) => void;
  isClickable: boolean;
  isPanelExpanded: boolean;
}

export const WorkflowRunsList = ({
  workflowRuns,
  onWorkflowRunDetailsClick,
  isClickable,
  isPanelExpanded,
}: WorkflowRunsListProps) => {
  const { runId } = useWorkflowRunContext();
  const { classes, cx } = useListItemStyles();

  const itemRefs = useRef<Record<string, HTMLLIElement | null>>({});

  useEffect(() => {
    if (runId && itemRefs.current[runId]) {
      itemRefs.current[runId]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [runId]);

  return (
    <ScrollArea h="100%">
      <VerticalList spacing="xsm" px="md" pt="xxl" listStyleType="none" sx={{ flexGrow: 1 }}>
        {workflowRuns.map(run => {
          const { workflowRunId } = run;
          const isSelected = workflowRunId === runId;

          const handleClick = isClickable ? () => onWorkflowRunDetailsClick(workflowRunId) : noop;

          const listItemClass = cx(classes.listItem, {
            [classes.clickable]: isClickable,
            [classes.selected]: isSelected,
          });

          return (
            <VerticalList.Item
              key={workflowRunId}
              mb="lg"
              onClick={handleClick}
              className={listItemClass}
              ref={el => (itemRefs.current[workflowRunId] = el)}
              aria-label={`Run number ${run.runNumber}`}
            >
              <WorkflowRunListItem
                run={run}
                isPanelExpanded={isPanelExpanded}
                isUpcoming={!isClickable}
                isSelected={isSelected}
              />
            </VerticalList.Item>
          );
        })}
      </VerticalList>
    </ScrollArea>
  );
};

import { Box, openModal } from '@/shared/design-system/v2';
import { SCHEDULE_DEMO_LINK } from './util';

export const useScheduleTrialModal = () => {
  const open = () =>
    openModal({
      withCloseButton: false,
      size: '1440px',
      padding: '4px',
      children: (
        <Box w="100%" h="800px">
          <iframe src={SCHEDULE_DEMO_LINK} width="100%" height="100%" style={{ border: 'none' }} />
        </Box>
      ),
    });

  return { open };
};

import { SuggestedQuestion } from '@/main/components/common/chat-with-data/chat/content/SuggestedQuestion';
import { FollowUpQuestionTemplate } from '@/main/generated/api';
import { Badge, Text } from '@/shared/design-system/v2';

const REFERENCE_NAME_MAX_WIDTH = 160;

const getQuestionWithMarkup = (questionWithTemplate: FollowUpQuestionTemplate) => {
  const { questionTemplate, placeholderMappings } = questionWithTemplate;

  // Iterate through placeholders and replace them with Badge components
  const markupQuestion = questionTemplate.split(/({.+?})/).map((placeholder, index) => {
    const placeholderMappingMatch = placeholderMappings.find(
      placeholderMapping => placeholderMapping.key === placeholder.slice(1, -1), // Extract key without braces
    );

    if (placeholderMappingMatch) {
      const value = placeholderMappingMatch.value;
      return (
        <Badge key={index} maw={REFERENCE_NAME_MAX_WIDTH} sx={{ textTransform: 'none' }}>
          <Text truncate fw={400} title={value}>
            {value}
          </Text>
        </Badge>
      );
    }

    // Return regular text parts
    return placeholder;
  });

  // Use reduce to replace placeholders in questionTemplate with values
  const vanillaQuestion = placeholderMappings.reduce(
    (acc, mapping) => acc.replace(`{${mapping.key}}`, mapping.value),
    questionTemplate,
  );

  return {
    markupQuestion,
    vanillaQuestion,
  };
};

interface DataAnalyticsSuggestedQuestionProps {
  onClick: (question: string) => void;
  questionWithTemplate: FollowUpQuestionTemplate;
}

export const DataAnalyticsSuggestedQuestion = ({
  onClick,
  questionWithTemplate,
}: DataAnalyticsSuggestedQuestionProps) => {
  const { markupQuestion, vanillaQuestion } = getQuestionWithMarkup(questionWithTemplate);

  return (
    <SuggestedQuestion onClick={() => onClick(vanillaQuestion)}>
      <Text variant="bodyLong02">{markupQuestion}</Text>
    </SuggestedQuestion>
  );
};

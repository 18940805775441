import { Alert, Skeleton } from '@mantine/core';
import { useTemplateFlowModal } from '@/main/components/workflows/create/templates/useTemplateFlowModal';
import { AutomationTemplateItem } from '@/main/components/workflows/home/templates/AutomationTemplateItem';
import { TemplateModel } from '@/main/generated/api';
import {
  useGetOperatorListQuery,
  useGetTemplatesListQuery,
} from '@/main/queries/workflows/operators';
import { useAbsoluteRoutes } from '@/main/router/hooks';
import { Horizontal } from '@/shared/design-system/v2';
import { useAddWorkflow } from './use-add-workflow';

export const SampleAutomationsContainer = () => {
  const { getWorkflowBuilderRoute } = useAbsoluteRoutes();
  const { handleAddWorkflow } = useAddWorkflow(getWorkflowBuilderRoute);
  const { open: openTemplateFlowModal } = useTemplateFlowModal();

  const { isLoading, isError, data } = useGetTemplatesListQuery();
  const { isLoading: operatorListLoading, data: operatorList } = useGetOperatorListQuery();

  const handleFlowButtonClick = (templateData: TemplateModel) => {
    openTemplateFlowModal(templateData, operatorList ?? [], handleAddWorkflow);
  };

  if (isLoading || operatorListLoading) {
    return (
      <Horizontal>
        <Skeleton h={40} w={250} />
        <Skeleton h={40} w={250} />
        <Skeleton h={40} w={250} />
        <Skeleton h={40} w={250} />
      </Horizontal>
    );
  }

  if (isError || !data) {
    return (
      <Alert color="red.6">
        There was an error loading the sample automations. Please try again
      </Alert>
    );
  }

  const sampleTemplates = [
    data.find(template => template.templateId === 'merge-multiple-data-files-template'),
    data.find(template => template.templateId === 'add-row-number-template'),
    data.find(template => template.templateId === 'sentiment-analysis-template'),
    data.find(template => template.templateId === 'outlier-detection-template'),
  ];

  return (
    <Horizontal spacing="xxl">
      {sampleTemplates.map(template => (
        <AutomationTemplateItem
          key={template?.templateId}
          handleFlowButtonClick={handleFlowButtonClick}
          template={template}
          operators={operatorList}
        />
      ))}
    </Horizontal>
  );
};

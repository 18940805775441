import { IconInfoCircle } from '@tabler/icons-react';
import AutomationsHomePage from '@/main/pages/Workflows/AutomationsHome';
import { useAbsoluteRoutes } from '@/main/router/hooks';
import {
  Box,
  Button,
  Container,
  Horizontal,
  Text,
  Tooltip,
  Vertical,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { ArtifactStateType } from '../../../generated/api';
import { useShowSideBar } from '../../../pages/home/WorkspaceShell';
import { useCreateBlankWorkflow } from '../../workspace/studio-home/workflow/use-add-workflow';
import { WorkflowsListContainer } from '../list/List.container';

export const getHeadingDescriptionFromState = (state?: ArtifactStateType) => {
  switch (state) {
    case ArtifactStateType.Archived:
      return {
        title: 'Archived',
        description:
          'These workflows are no longer active. They are stored in the archive for future reference,',
      };
    default:
      return {
        title: 'Workflows',
        description:
          'These are the workflows that have been finalized, reviewed, and published for active use.',
      };
  }
};

interface WorkflowsHomePageProps {
  state?: ArtifactStateType;
}

export const WorkflowsListHomePage = ({ state }: WorkflowsHomePageProps) => {
  const theme = useMarkovTheme();

  const { getWorkflowBuilderRoute } = useAbsoluteRoutes();
  const { createBlankWorkflow } = useCreateBlankWorkflow(getWorkflowBuilderRoute);

  useShowSideBar(true);
  const { title } = getHeadingDescriptionFromState(state);

  if (state !== ArtifactStateType.Archived) {
    return <AutomationsHomePage />;
  }

  return (
    <Vertical h="100%" bg="white.0" spacing={0}>
      <Box w="100%" sx={{ borderBottom: `1px solid ${theme.colors.gray[2]}` }} py="10.4px" px="xl">
        <Horizontal position="apart" noWrap>
          <Horizontal spacing="xs" noWrap>
            <Text variant="subTitle01" color="gray.9">
              Workflows
            </Text>
            <Tooltip
              multiline
              radius="sm"
              label={
                <Text variant="bodyLong03" maw={240}>
                  Catalog of all your relevant workflows
                </Text>
              }
              position="bottom-start"
            >
              <Box mt="xs">
                <IconInfoCircle size={24} color={theme.colors.gray[6]} />
              </Box>
            </Tooltip>
          </Horizontal>
          <Button variant="primary" onClick={createBlankWorkflow}>
            <Text variant="bodyShort02" color="white.0">
              + Create new
            </Text>
          </Button>
        </Horizontal>
      </Box>
      <Box h="100%">
        <Container h="100%" size="xl" pt="xl">
          <WorkflowsListContainer state={state} />
        </Container>
      </Box>
    </Vertical>
  );
};

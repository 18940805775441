import { useMemo } from 'react';
import { ThreadProps } from '@/main/components/common/chat-with-data/menu/panels/threads-panel/ThreadsItem';
import {
  ChatResourceTypes,
  useChatWithResourceThreadsListQuery,
} from '@/main/queries/chat-with-data/chat';
import { formatDateLong } from '@/shared/lib/ui';
import { ThreadHistoryPopover } from './ThreadHistoryPopover';

export interface ThreadHistoryPopoverContainerProps {
  resourceId: string;
}

export const ThreadHistoryPopoverContainer = ({
  resourceId,
}: ThreadHistoryPopoverContainerProps) => {
  const { isLoading, isFetching, isError, data, fetchNextPage, hasNextPage } =
    useChatWithResourceThreadsListQuery(resourceId, ChatResourceTypes.DATA_ANALYTICS);

  const threads = useMemo<ThreadProps[]>(
    () =>
      data?.pages
        .flatMap(page => page.response)
        .map(thread => ({
          ...thread,
          createDate: formatDateLong(thread.createDate),
        })) || [],
    [data],
  );

  return (
    <ThreadHistoryPopover
      isLoading={isLoading}
      isFetching={isFetching}
      isError={isError}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      threads={threads}
    />
  );
};

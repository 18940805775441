import { useMemo } from 'react';
import { useMarkovTheme } from '@/shared/design-system/v2';

export const sendButtonSize = 36;
export const atButtonSize = 22;
export const buttonRightInset = 10;

const INPUT_HEIGHT = 92;

// Returns raw style objects for React Mention component
export const useSuggestionPromptStyles = (focused: boolean) => {
  const theme = useMarkovTheme();

  const promptInputStyles = useMemo(
    () => ({
      control: {
        height: INPUT_HEIGHT,
        fontFamily: theme.fontFamily,
      },
      input: {
        height: '100%',
        overflow: 'auto',
        lineHeight: 1.6,
        padding: theme.spacing.sm,
        paddingLeft: theme.spacing.lg,
        paddingRight: sendButtonSize + atButtonSize + 10,
        border: 'none',
        outline: 'none',
        color: theme.colors.gray[9],
      },
      // height, lineHeight and padding of highlighter must match values for input
      highlighter: {
        height: '100%',
        lineHeight: 1.6,
        padding: theme.spacing.sm,
        paddingLeft: theme.spacing.lg,
        paddingRight: sendButtonSize + atButtonSize + buttonRightInset,
        backgroundColor: theme.colors.white[0],
        borderRadius: theme.radius.md,
      },
      suggestions: {
        boxShadow: `rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px`,
        zIndex: 3,
        'border-radius': '8px',
      },
    }),
    [focused],
  );

  const mentionStyles = useMemo(
    () => ({
      backgroundColor: theme.colors.blue[3],
      opacity: 0.3,
      padding: 1,
      marginLeft: -1,
      borderRadius: theme.radius.md,
    }),
    [],
  );

  return {
    mention: mentionStyles,
    input: promptInputStyles,
  };
};

import { useAbsoluteRoutes } from '@/main/router/hooks';
import { Horizontal, ScrollArea, Text, Vertical, closeAllModals } from '@/shared/design-system/v2';
import { TemplateModel } from '../../../../generated/api';
import {
  useGetOperatorListQuery,
  useGetTemplatesListQuery,
} from '../../../../queries/workflows/operators';
import { TemplateCard } from '../../../workspace/studio-home/workflow/TemplateCard';
import { useAddWorkflow } from '../../../workspace/studio-home/workflow/use-add-workflow';
import { useTemplateFlowModal } from './useTemplateFlowModal';

interface SimilarTemplatesContainerProps {
  templateId: string;
  categories: string[];
}

export const SimilarTemplatesContainer = ({
  templateId,
  categories,
}: SimilarTemplatesContainerProps): JSX.Element => {
  const { getWorkflowBuilderRoute } = useAbsoluteRoutes();

  const { data } = useGetTemplatesListQuery();
  const { data: operatorList } = useGetOperatorListQuery();
  const { open: openTemplateFlowModal } = useTemplateFlowModal();
  const { handleAddWorkflow } = useAddWorkflow(getWorkflowBuilderRoute);

  const similarTemplates = data?.filter(
    template =>
      template.templateId !== templateId &&
      template.category?.some(templateCategory => categories.includes(templateCategory)),
  );

  const handleFlowButtonClick = (templateData: TemplateModel) => {
    closeAllModals();
    openTemplateFlowModal(templateData, operatorList ?? [], handleAddWorkflow);
  };

  return (
    <Vertical w="100%">
      <Text variant="heading04" color="gray.8">
        Similar templates
      </Text>
      <ScrollArea maw={960} offsetScrollbars>
        <Horizontal spacing="xxl" noWrap w="100%" h="100%">
          {similarTemplates?.map(template => (
            <TemplateCard
              key={template.templateId}
              templateData={template}
              handleFlowButtonClick={handleFlowButtonClick}
              operatorList={operatorList ?? []}
              variant="small"
              titleLineClamp={1}
              width={320}
            />
          ))}
        </Horizontal>
      </ScrollArea>
    </Vertical>
  );
};

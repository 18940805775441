import { useNavigate } from 'react-router-dom';
import { useCreateDataAnalyticsMutation } from '@/main/queries/data-analytics';
import { useAbsoluteRoutes } from '@/main/router/hooks';
import {
  Button,
  Horizontal,
  MODAL_LEVEL_2_Z_INDEX,
  Text,
  Vertical,
  closeAllModals,
  closeModal,
  notifications,
  openModal,
} from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';
import { isProduction } from '@/shared/lib/env.util';
import { DataSourcePreviewContainer } from '../../data-sources/modals/DataSourcePreview.container';

const SAMPLE_RESOURCE_ID_DEV = '9CmB7bzR';
const SAMPLE_RESOURCE_ID_PROD = 'as564Cvt';
const SAMPLE_RESOURCE_NAME = '(Sample) Marketing Campaign Data.csv';

const SAMPLE_DATA_MODAL_ID = 'sample-data-modal-id';

export const getSampleDataResourceID = () =>
  isProduction() ? SAMPLE_RESOURCE_ID_PROD : SAMPLE_RESOURCE_ID_DEV;

const SampleDataModal = () => {
  const navigate = useNavigate();
  const { getDataAnalyticsDetailsRoute } = useAbsoluteRoutes();
  const { mutateAsync: createDataAnalytics, isLoading: isCreating } =
    useCreateDataAnalyticsMutation();

  // TODO: Read sample resource from API (ID not consistent across envs)
  const resourceId = isProduction() ? SAMPLE_RESOURCE_ID_PROD : SAMPLE_RESOURCE_ID_DEV;

  const handleCreateDataAnalytics = async () => {
    try {
      await createDataAnalytics({ resourceId });
    } catch (err) {
      logger.error(err);
      notifications.error('Error encountered while creating chat.');
      return;
    }

    navigate(getDataAnalyticsDetailsRoute(resourceId));
    closeAllModals();
  };

  const handleCloseModal = () => {
    closeModal(SAMPLE_DATA_MODAL_ID);
  };

  return (
    <Vertical>
      <DataSourcePreviewContainer resourceId={resourceId} />
      <Horizontal position="right">
        <Button variant="subtle" onClick={handleCloseModal}>
          Go back
        </Button>
        <Button variant="primary" onClick={handleCreateDataAnalytics} loading={isCreating}>
          Start Chat
        </Button>
      </Horizontal>
    </Vertical>
  );
};

export const useSampleDataModal = () => {
  const open = () =>
    openModal({
      modalId: SAMPLE_DATA_MODAL_ID,
      title: <Text variant="subTitle01">{SAMPLE_RESOURCE_NAME}</Text>,
      size: '90vw',
      children: <SampleDataModal />,
      zIndex: MODAL_LEVEL_2_Z_INDEX,
    });

  return { open };
};

import { IconSearch } from '@tabler/icons-react';
import { useMemo, useState } from 'react';
import { TemplateContainer } from '@/main/components/workspace/studio-home/workflow/Template.container';
import { TemplateCategoryFilters } from '@/main/components/workspace/studio-home/workflow/TemplateCategoryFilters';
import {
  useAddWorkflow,
  useCreateBlankWorkflow,
} from '@/main/components/workspace/studio-home/workflow/use-add-workflow';
import { useGetTemplatesListQuery } from '@/main/queries/workflows/operators';
import { useAbsoluteRoutes } from '@/main/router/hooks';
import {
  Box,
  Button,
  Horizontal,
  ScrollArea,
  Tabs,
  Text,
  TextInput,
  Vertical,
  openModal,
  useInputState,
} from '@/shared/design-system/v2';

const SIDE_PANEL_WIDTH = 180;
const SCROLL_AREA_HEIGHT = 480;

// TODO: Read these from BE (these are UI placeholders - currently templates aren't associated with these)
const filters = ['Content Creation', 'Data Enrichment', 'Market Research', 'Miscellaneous'];

const BuildAutomationModal = (): JSX.Element => {
  const [searchQuery, setSearchQuery] = useInputState('');
  const [selectedUseCase, setSelectedUseCase] = useState<string | null>(null);
  const [selectedCategoryFilters, setCategorySelectedFilters] = useState<string[]>([]);

  const { getWorkflowBuilderRoute } = useAbsoluteRoutes();
  const { handleAddWorkflow } = useAddWorkflow(getWorkflowBuilderRoute);
  const { createBlankWorkflow } = useCreateBlankWorkflow(getWorkflowBuilderRoute);

  const { data: templateList } = useGetTemplatesListQuery();

  const allCategories = useMemo(
    () => Array.from(new Set(templateList?.flatMap(template => template.category ?? []))),
    [templateList],
  );

  const handleBlankCanvasClick = () => {
    createBlankWorkflow();
  };

  const headerText = selectedUseCase || 'All templates';

  return (
    <Vertical>
      <Box>
        <TextInput
          icon={<IconSearch size={20} />}
          ariaLabel="Template search"
          placeholder="What are you looking for?"
          value={searchQuery}
          onChange={setSearchQuery}
        />
      </Box>
      <Tabs
        variant="pills"
        color="blue.0"
        value={selectedUseCase}
        onTabChange={setSelectedUseCase}
        styles={theme => ({
          tab: {
            color: `${theme.colors.gray[7]}!important`,
            '&[data-active]': { color: `${theme.colors.blue[6]}!important` },
          },
        })}
      >
        <Horizontal noWrap spacing="xxl" align="start">
          <Vertical w={SIDE_PANEL_WIDTH} sx={{ flexShrink: 0 }}>
            <Box>
              <Button variant="primary" onClick={handleBlankCanvasClick}>
                + Use blank canvas
              </Button>
            </Box>
            <Vertical spacing="sm">
              <Text variant="small03" color="gray.4">
                Use case based
              </Text>
              <Tabs.List>
                {filters.map(filter => (
                  <Tabs.Tab key={filter} value={filter} py="sm">
                    {filter}
                  </Tabs.Tab>
                ))}
              </Tabs.List>
            </Vertical>
          </Vertical>
          <Box sx={{ flexGrow: 1 }}>
            <ScrollArea h={SCROLL_AREA_HEIGHT}>
              <Vertical>
                <Text variant="subTitle02" color="dark.3">
                  {headerText}
                </Text>
                <TemplateCategoryFilters
                  size="sm"
                  selectedFilters={selectedCategoryFilters}
                  setSelectedFilters={setCategorySelectedFilters}
                  templateList={templateList ?? []}
                  allCategories={allCategories}
                />
                <TemplateContainer
                  searchText={searchQuery}
                  onAddWorkflow={handleAddWorkflow}
                  onCreateBlankWorkflow={createBlankWorkflow}
                  selectedCategoryFilters={selectedCategoryFilters}
                />
              </Vertical>
            </ScrollArea>
          </Box>
        </Horizontal>
      </Tabs>
    </Vertical>
  );
};

export const useBuildAutomationModal = () => {
  const open = () =>
    openModal({
      title: (
        <Box>
          <Text variant="heading04">Build New Automation</Text>
          <Text variant="bodyShort03" color="gray.7">
            Start from scratch or customize an existing template.
          </Text>
        </Box>
      ),
      styles: {
        header: {
          background: 'linear-gradient(90deg, #FFEDF7 0%, #FFF7E7 100%)',
          paddingTop: 12,
          paddingBottom: 12,
          marginBottom: 12,
        },
      },
      children: <BuildAutomationModal />,
      size: '1172px',
    });

  return { open };
};

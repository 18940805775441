import { WidgetModel, WidgetUIConfigurationModel } from '@/main/generated/api';
import { useGetWidgetDataQuery } from '@/main/queries/dashboard/widget';
import { Alert, Card, Center, Loader, Text } from '@/shared/design-system/v2';
import { WidgetContent } from './WidgetContent';

interface WidgetContentContainerProps {
  title: string;
  widget: WidgetModel;
  onVisualizationUpdate: (configuration: WidgetUIConfigurationModel) => void;
}

export const WidgetContentContainer = ({
  widget,
  title,
  onVisualizationUpdate,
}: WidgetContentContainerProps) => {
  const { isLoading, isError, data: vizData } = useGetWidgetDataQuery(widget.widgetId);

  if (isLoading) {
    return (
      <Card withBorder h="100%" w="100%" bg="white.0" radius="md">
        <Center h="100%" w="100%">
          <Loader text="Loading widget..." />
        </Center>
      </Card>
    );
  }

  if (isError) {
    return (
      <Card withBorder h="100%" w="100%" bg="white.0" radius="md">
        <Center h="100%" w="100%">
          <Alert color="red">
            <Text variant="subTitle04">
              Error loading viz data. Please refresh or contact support
            </Text>
          </Alert>
        </Center>
      </Card>
    );
  }

  if (!vizData) {
    return (
      <Card withBorder h="100%" w="100%" bg="white.0" radius="md">
        <Center h="100%" w="100%">
          <Alert color="red">
            <Text variant="subTitle04">
              There seems to be an issue loading viz data. Please refresh or contact support
            </Text>
          </Alert>
        </Center>
      </Card>
    );
  }

  return (
    <WidgetContent
      title={title}
      type={widget.widgetType}
      configuration={widget.uiConfiguration}
      rawData={vizData}
      onVisualizationUpdate={onVisualizationUpdate}
      widgetId={widget.widgetId}
      widget={widget}
    />
  );
};

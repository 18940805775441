import { IconMessage2Plus } from '@tabler/icons-react';
import { useThreadsItemContext } from '@/main/contexts/chat-with-data/ThreadsItemContext';
import { ActionIcon, Tooltip } from '@/shared/design-system/v2';

export const ChatPromptNewThread = () => {
  const { createNewEmptyThread } = useThreadsItemContext();

  return (
    <Tooltip label="Create new thread" withArrow>
      <ActionIcon aria-label="Create new thread" color="gray.7" onClick={createNewEmptyThread}>
        <IconMessage2Plus size={20} />
      </ActionIcon>
    </Tooltip>
  );
};

import {
  IconArchive,
  IconBolt,
  IconCloudNetwork,
  IconHome,
  IconMessageSearch,
  IconRoute,
} from '@tabler/icons-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLayoutContext } from '@/main/contexts/layout/LayoutContext';
import {
  Box,
  Divider,
  Horizontal,
  Navbar,
  UnstyledButton,
  Vertical,
} from '@/shared/design-system/v2';
import { AppRoutes } from '../../../router/constants';
import { useAbsoluteRoutes } from '../../../router/hooks';
import { DATA_ANALYTICS_NAME } from '../../data-analytics/util';
import { useSidebarStyles } from '../sidebar-v2/Sidebar.style';
import { SideBarOptions } from '../sidebar-v2/SidebarLinks';
import { getActiveBar } from '../util';
import { NAV_BAR_COLLAPSED_SIZE, NAV_BAR_EXPANDED_SIZE } from './Sidebar';

const STROKE_WIDTH = '1.5px';

export const NonEnterpriseNavbar = (): JSX.Element => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const activeBar = getActiveBar(pathname);

  const { sidebarExpanded, setSidebarMouseEnter } = useLayoutContext();
  const { featureTextToSql } = useFlags();
  const {
    getWorkspaceRoute,
    getWorkflowsHomeRoute,
    getWorkflowsAppsRoute,
    getWorkflowsArchivedRoute,
    getDataSourcesRoute,
    getDataAnalyticsRoute,
    getIntegrationsRoute,
  } = useAbsoluteRoutes();
  const { classes, cx } = useSidebarStyles({ colorScheme: 'data-studio' });

  const handleMouseEnter = () => {
    setSidebarMouseEnter(true);
  };

  const handleMouseLeave = () => {
    setSidebarMouseEnter(false);
  };

  const navBarSize = sidebarExpanded ? NAV_BAR_EXPANDED_SIZE : NAV_BAR_COLLAPSED_SIZE;

  const topElements = [
    {
      id: SideBarOptions.HOME,
      label: 'Home',
      icon: <IconHome strokeWidth={STROKE_WIDTH} />,
      isVisible: true,
      separator: true,
      onClick: () => {
        navigate(getWorkspaceRoute());
      },
    },
    {
      id: SideBarOptions.DATA_ANALYTICS,
      label: DATA_ANALYTICS_NAME,
      icon: <IconMessageSearch strokeWidth={STROKE_WIDTH} />,
      isVisible: !!featureTextToSql,
      onClick: () => {
        navigate(getDataAnalyticsRoute());
      },
    },
    {
      id: SideBarOptions.WORKFLOWS_APPS,
      path: AppRoutes.WORKFLOW_APPS_LIST,
      label: 'AI Apps',
      onClick: () => {
        navigate(getWorkflowsAppsRoute());
      },
      icon: <IconBolt strokeWidth={STROKE_WIDTH} />,
    },
    {
      id: SideBarOptions.WORKFLOWS_HOME,
      path: AppRoutes.WORKFLOWS_HOME,
      label: 'Automations',
      onClick: () => {
        navigate(getWorkflowsHomeRoute());
      },
      icon: <IconRoute strokeWidth={STROKE_WIDTH} />,
    },
  ].filter(({ isVisible = true }) => isVisible);

  const bottomElements = [
    {
      id: SideBarOptions.WORKFLOWS_ARCHIVED,
      path: AppRoutes.WORKFLOWS_ARCHIVED,
      label: 'Archived workflows',
      onClick: () => {
        navigate(getWorkflowsArchivedRoute());
      },
      icon: <IconArchive strokeWidth={STROKE_WIDTH} />,
    },
    {
      id: SideBarOptions.DATA_SOURCES,
      path: AppRoutes.DATA_SOURCES,
      label: 'Data sources',
      onClick: () => {
        navigate(getDataSourcesRoute());
      },
      icon: <IconCloudNetwork strokeWidth={STROKE_WIDTH} />,
    },
    {
      id: SideBarOptions.INTEGRATIONS,
      path: AppRoutes.INTEGRATIONS,
      label: 'Integrations',
      onClick: () => {
        navigate(getIntegrationsRoute());
      },
      icon: <IconCloudNetwork strokeWidth={STROKE_WIDTH} />,
    },
  ];

  return (
    <Navbar
      p={0}
      withBorder
      pos="static"
      width={{
        base: navBarSize,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Vertical spacing={0} h="100%" w="100%" bg="white.0" p="sm">
        <Navbar.Section grow>
          {topElements.map(element => (
            <Box key={element.label}>
              <UnstyledButton
                data-testid={`sidebar-link-${element.label}`}
                mb="sm"
                component={Horizontal}
                noWrap
                onClick={element.onClick}
                className={cx(classes.navButton, {
                  [classes.active]: activeBar === element.id,
                })}
              >
                {element.icon}
                {element.label}
              </UnstyledButton>
              {element.separator && <Divider mb="sm" />}
            </Box>
          ))}
        </Navbar.Section>

        <Navbar.Section grow={false}>
          {bottomElements.map(element => (
            <UnstyledButton
              key={element.label}
              data-testid={`sidebar-link-${element.label}`}
              my="sm"
              component={Horizontal}
              noWrap
              onClick={element.onClick}
              className={cx(classes.navButton, {
                [classes.active]: activeBar === element.id,
              })}
            >
              {element.icon}
              {element.label}
            </UnstyledButton>
          ))}
        </Navbar.Section>
      </Vertical>
    </Navbar>
  );
};

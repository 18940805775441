import first from 'lodash/first';
import { useMemo } from 'react';
import { Layouts } from 'react-grid-layout';
import { ConversationViewWrapper } from '@/main/components/common/chat-with-data/chat/conversation/ConversationView.wrapper';
import { ThreadsItemProvider } from '@/main/contexts/chat-with-data/ThreadsItemContext';
import {
  BrainStormConfigurationModelV1VersionEnum,
  ChatterRequestModel,
  DashboardUILayoutModelV1VersionEnum,
  QuestionTypes,
} from '@/main/generated/api';
import {
  ChatResourceTypes,
  useChatWithResourceThreadsListQuery,
} from '@/main/queries/chat-with-data/chat';
import {
  DashboardWithLayoutV1,
  useUpdateDashboardLayoutMutation,
} from '@/main/queries/dashboard/dashboard';
import '@/main/queries/dashboard/widget';
import { useUpdateWidgetBrainstormConfigurationMutation } from '@/main/queries/dashboard/widget';
import { useChatWithVisualizationMutation } from '@/main/queries/data-analytics';
import { Alert, Center, Loader } from '@/shared/design-system/v2';
import { BrainstormEmptyContent } from './BrainstormEmptyContent';
import { BrainstormPromptInputContainer } from './BrainstormPromptInput.container';

interface BrainstormModeChatWithWidgetProps {
  widgetId: string;
  dashboard: DashboardWithLayoutV1;
}

export const BrainstormModeChatWithWidget = ({
  widgetId,
  dashboard,
}: BrainstormModeChatWithWidgetProps) => {
  const {
    isLoading: isThreadsLoading,
    isError: isThreadsError,
    data,
  } = useChatWithResourceThreadsListQuery(widgetId, ChatResourceTypes.WIDGET);

  const threads = useMemo(() => data?.pages.flatMap(page => page.response) || [], [data]);
  const firstThread = first(threads);
  const { mutate: queryPrompt } = useChatWithVisualizationMutation(
    widgetId,
    firstThread?.threadId || '',
  );
  const { mutate: updateDashboardLayout } = useUpdateDashboardLayoutMutation();

  const { mutate: updateBrainstormConfiguration } =
    useUpdateWidgetBrainstormConfigurationMutation(widgetId);

  const handleAddTextToWidget = (question: string) => {
    updateBrainstormConfiguration({
      brainstormConfiguration: {
        question,
        version: BrainStormConfigurationModelV1VersionEnum.V1,
      },
    });

    const existingUILayouts = dashboard.uiLayout;

    if (
      !existingUILayouts ||
      existingUILayouts.version !== DashboardUILayoutModelV1VersionEnum.V1
    ) {
      return;
    }

    const existingLayouts = existingUILayouts.layout;

    const updatedLayouts = Object.keys(existingLayouts).reduce((acc, val) => {
      acc[val] = existingLayouts[val].map(widgetItem => {
        if (widgetItem.i === widgetId) {
          return {
            ...widgetItem,
            h: widgetItem.h < 16 ? 16 : widgetItem.h,
          };
        }

        return widgetItem;
      });

      return acc;
    }, {} as Layouts);

    updateDashboardLayout({
      dashboardId: dashboard.dashboardId,
      layouts: updatedLayouts,
    });
  };

  if (isThreadsLoading) {
    return (
      <Center h="100%">
        <Loader color="yellow.6" text="Loading chat history" />
      </Center>
    );
  }

  if (isThreadsError) {
    return (
      <Alert color="red">
        There was an error loading your chat history. Please try again or contact support.
      </Alert>
    );
  }

  const handleQuestionExecution = (question: string) => {
    const chatterRequestModel: ChatterRequestModel = {
      threadId: firstThread?.threadId,
      question: question,
      questionType: QuestionTypes.Text,
    };
    queryPrompt(chatterRequestModel);
  };

  return (
    <ThreadsItemProvider resourceId={widgetId} thread={firstThread}>
      <ConversationViewWrapper
        resourceId={widgetId}
        resourceType={ChatResourceTypes.WIDGET}
        activeThread={firstThread}
        titleText="Welcome to Brainstorm Mode"
        subHeadingText="Ask questions, explore trends, and extract key takeaways."
        emptyChatContentInnerContent={
          <BrainstormEmptyContent onQuestionClick={handleQuestionExecution} />
        }
        onAddToWidget={handleAddTextToWidget}
      >
        <BrainstormPromptInputContainer visualizationId={widgetId} />
      </ConversationViewWrapper>
    </ThreadsItemProvider>
  );
};

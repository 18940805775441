import first from 'lodash/first';
import { useThreadsItemContext } from '../../../contexts/chat-with-data/ThreadsItemContext';
import { useQuestionTypeContext } from '../../../contexts/data-analytics/QuestionTypeContext';
import { ChatterRequestModel } from '../../../generated/api';
import {
  useChatWithCustomAppMutation,
  useGetAppBuilderDetailsQuery,
} from '../../../queries/app-builder/app-builder';
import {
  ChatResourceTypes,
  useIsNewConversationMutating,
} from '../../../queries/chat-with-data/chat';
import { ChatPromptInput } from '../../common/chat-with-data/chat/input-prompt/ChatPromptInput';
import { ThreadProps } from '../../common/chat-with-data/menu/panels/threads-panel/ThreadsItem';

export interface CustomAppInputPromptContainerProps {
  resourceId: string;
}

export const CustomAppInputPromptContainer = ({
  resourceId,
}: CustomAppInputPromptContainerProps) => {
  const { data: appDetails, isFetching } = useGetAppBuilderDetailsQuery(resourceId);
  const { activeThread, setActiveThread } = useThreadsItemContext();
  const { currentQuestionType } = useQuestionTypeContext();

  const { mutate: queryPrompt } = useChatWithCustomAppMutation(
    resourceId,
    activeThread?.threadId || '',
  );

  const isNewConversationLoading = useIsNewConversationMutating(
    resourceId,
    activeThread?.threadId || '',
    ChatResourceTypes.CUSTOM_APP,
  );

  const handleSend = (question: string) => {
    const chatterRequestModel: ChatterRequestModel = {
      threadId: activeThread?.threadId,
      question: question,
      questionType: currentQuestionType,
    };
    queryPrompt(chatterRequestModel, {
      onSuccess: res => {
        if (!activeThread) {
          const newConversation = res.data;
          const localThread: ThreadProps = {
            threadId: newConversation.threadId,
            threadTitle: newConversation.question,
            createDate: newConversation.createDate,
          };
          // Set it as active if current selected thread is not undefined.
          // Handles the case when the user has selected an old thread.
          setActiveThread(previousThread => previousThread ?? localThread);
        }
      },
    });
  };

  const placeholder = first(appDetails?.appProperties?.examples) || 'Ask me a question';
  const prompt = appDetails?.appProperties?.appPrompt;

  return (
    <ChatPromptInput
      ariaLabel="Ask me a question"
      placeholder={placeholder}
      onSend={handleSend}
      isSendDisabled={!!isNewConversationLoading}
      disabled={!prompt || isFetching}
    />
  );
};

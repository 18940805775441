import { IconBulb, IconSquareArrowUpFilled } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import {
  ActionIcon,
  Alert,
  Badge,
  Box,
  Horizontal,
  ScrollArea,
  Text,
  Vertical,
  useFocusWithin,
  useInputState,
  useLocalStorage,
} from '@/shared/design-system/v2';
import {
  buttonRightInset,
  sendButtonSize,
  useSuggestionPromptStyles,
} from './ChatPromptInputWithSuggestions.style';
import { ChatPromptInputHeader } from './data-source-header/ChatPromptInputHeader';
import { DataSource } from './data-source-header/types';

// Add spaces around word for highlight; remove extra spaces for LLM request
// TODO: This is OK but UX can be further improved, e.g. when prompt ends with column name + "?"
const displayTransform = (_id: string, display: string) => ` ${display} `;
const revertDisplayTransform = (text: string) => text.replaceAll('  ', ' ');

const SUGGESTION_WIDTH = 250;
const ALERT_DISMISSED_KEY = 'data-analytics-help-alert-dismissed';
const HELP_TIP_FONT_WEIGHT = 540;

interface ChatPromptInputWithSuggestionsProps {
  suggestions: SuggestionDataItem[];
  onSend: (prompt: string) => void;
  isSendDisabled?: boolean;
  isInputDisabled?: boolean;
  isNewThread?: boolean;
  isFooterVisible?: boolean;
  selectedDataSource?: DataSource;
  openDataSourceSelectionModal?: () => void;
  recentDataSources?: DataSource[];
  onDataSourceSelect?: (dataSourceId: string) => void;
  onPreviewDataClick?: () => void;
  resourceId?: string;
}

export const ChatPromptInputWithSuggestions = ({
  suggestions,
  onSend,
  isSendDisabled = false,
  isInputDisabled = false,
  isFooterVisible = true,
  selectedDataSource,
  openDataSourceSelectionModal,
  recentDataSources = [],
  onDataSourceSelect,
  onPreviewDataClick,
  resourceId,
}: ChatPromptInputWithSuggestionsProps) => {
  const { ref, focused } = useFocusWithin();
  const styles = useSuggestionPromptStyles(focused);
  // currentQueryMarkup is used by MentionsInput; currentQuery stores plain text
  const [currentQuery, setCurrentQuery] = useInputState('');
  const [currentQueryMarkup, setCurrentQueryMarkup] = useInputState('');
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertDismissed, setAlertDismissed] = useLocalStorage<boolean>({
    key: ALERT_DISMISSED_KEY,
    defaultValue: false,
  });

  useEffect(() => {
    if (!alertDismissed && currentQuery) {
      setIsAlertVisible(true);
    }
  }, [currentQuery]);

  const handleCloseHelpAlert = () => {
    setIsAlertVisible(false);
    setAlertDismissed(true);
  };

  const disableSendQuery = isSendDisabled || !currentQuery;

  const handleSend = () => {
    if (disableSendQuery) {
      return;
    }
    onSend(revertDisplayTransform(currentQuery));
    setCurrentQuery('');
    setCurrentQueryMarkup('');
  };

  const handleInputKeyDown = (e: React.KeyboardEvent) => {
    if (!e.shiftKey && e.code === 'Enter') {
      e.preventDefault();
      handleSend();
    }
  };

  const handleInsertAtSymbol = () => {
    if (!ref.current) return;

    const input = ref.current;
    const selectionStart = input.selectionStart;
    const textBeforeCursor = input.value.slice(0, selectionStart);

    // Check if we need to add a space before @
    const needsSpace =
      textBeforeCursor.length > 0 &&
      !textBeforeCursor.endsWith(' ') &&
      !textBeforeCursor.endsWith('\n');

    const atText = needsSpace ? ' @' : '@';

    // Create a synthetic input event
    const event = new InputEvent('input', {
      bubbles: true,
      cancelable: true,
      inputType: 'insertText',
      data: atText,
    });

    input.setSelectionRange(selectionStart, selectionStart);
    input.focus();

    input.setRangeText(atText, selectionStart, selectionStart, 'end');
    input.dispatchEvent(event);

    setTimeout(() => {
      // This is a hack because on input focus, suggestions are not displayed in
      // Chromium based browsers due this bug: https://issues.chromium.org/issues/346898523.
      // For more info see: https://github.com/signavio/react-mentions/issues/376.
      const keyboardEvent = new KeyboardEvent('keydown', { key: 'ArrowRight', bubbles: true });
      ref.current.dispatchEvent(keyboardEvent);
    }, 0);
  };

  const showDataSourceName =
    selectedDataSource && openDataSourceSelectionModal && onPreviewDataClick && onDataSourceSelect;

  return (
    <Vertical w="100%" spacing="xs">
      {isAlertVisible && (
        <Alert
          p="lg"
          mb="sm"
          icon={<IconBulb size={40} />}
          color="dark"
          variant="filled"
          onClose={handleCloseHelpAlert}
          withCloseButton
        >
          <Text fw={HELP_TIP_FONT_WEIGHT} variant="bodyShort03">
            Use @ to refer &quot;TABLE&quot; and &quot;COLUMN NAMES&quot; to get accurate results.
          </Text>
          <Text fw={HELP_TIP_FONT_WEIGHT} mt="sm" variant="bodyShort03">
            E.g.: <Badge>COLUMN NAME</Badge>
          </Text>
        </Alert>
      )}
      <Box
        w="100%"
        pos="relative"
        sx={theme => ({
          borderRadius: theme.radius.md,
          border: `1px solid`,
          borderColor: focused ? theme.colors.blue[1] : theme.colors.gray[2],
          backgroundColor: theme.colors.gray[0],
        })}
      >
        {showDataSourceName && (
          <ChatPromptInputHeader
            selectedDataSource={selectedDataSource}
            recentDataSources={[...new Set([selectedDataSource, ...recentDataSources])]}
            onDataSourceSelect={onDataSourceSelect}
            openDataSourceSelectionModal={openDataSourceSelectionModal}
            onPreviewDataClick={onPreviewDataClick}
            resourceId={resourceId}
          />
        )}
        <MentionsInput
          inputRef={ref}
          aria-label="Input text query"
          placeholder="Try a new query"
          allowSpaceInQuery
          forceSuggestionsAboveCursor
          disabled={isInputDisabled}
          value={currentQueryMarkup}
          onChange={(_event, newValue, newPlainTextValue) => {
            setCurrentQuery(newPlainTextValue);
            setCurrentQueryMarkup(newValue);
          }}
          onKeyDown={handleInputKeyDown}
          style={styles.input}
          customSuggestionsContainer={(children: React.ReactNode) => (
            <ScrollArea.Autosize mah={360} sx={{ borderRadius: '8px' }}>
              {children}
            </ScrollArea.Autosize>
          )}
        >
          <Mention
            trigger="@"
            appendSpaceOnAdd
            data={suggestions}
            // Adds space around table/columns names for highlight
            displayTransform={displayTransform}
            renderSuggestion={(entry, _search, _highlightedDisplay, _index, focused) => {
              const type = String(entry.id).split('-').shift();
              return (
                <Box
                  p="sm"
                  w={SUGGESTION_WIDTH}
                  sx={theme => ({
                    border: `1px solid ${theme.colors.gray[1]}`,
                    backgroundColor: focused ? theme.colors.blue[0] : theme.white,
                  })}
                >
                  <Text variant="small01" truncate title={entry.display}>
                    {entry.display}
                  </Text>
                  {type && (
                    <Text variant="small03" color="gray.6" tt="uppercase">
                      {type}
                    </Text>
                  )}
                </Box>
              );
            }}
            style={styles.mention}
          />
        </MentionsInput>
        <ActionIcon
          aria-label="Send query"
          disabled={disableSendQuery}
          color="gray.7"
          onClick={handleSend}
          size={sendButtonSize}
          pos="absolute"
          right={buttonRightInset}
          bottom={showDataSourceName ? 5 : undefined}
          top={showDataSourceName ? undefined : 5}
          p={0}
          bg="transparent !important"
          sx={{ border: 0 }}
        >
          <IconSquareArrowUpFilled size="100%" />
        </ActionIcon>
      </Box>
      {isFooterVisible ? (
        <Horizontal position="apart" px="xs" noWrap>
          <Text variant="small02" color="gray.7" w="60%">
            <Text span fw={600}>
              Tip:
            </Text>{' '}
            Use @ to reference tables or columns.
          </Text>

          <Text variant="bodyLong03" color="gray.5">
            New line (Shift + ↵)
          </Text>
        </Horizontal>
      ) : null}
    </Vertical>
  );
};

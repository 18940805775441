import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Layouts } from 'react-grid-layout';
import { ulid } from 'ulid';
import { addWidgetToDashboard } from '@/main/components/dashboard/util';
import { useAppMetadata } from '@/main/contexts/app-metadata/AppMetadata';
import { DashboardModel, DashboardUILayoutModelV1VersionEnum } from '@/main/generated/api';
import { dashboardKeys } from './dashboard';
import { parseLayoutJson } from './util';

interface DashboardUpdateState {
  previousDashboard: AxiosResponse<DashboardModel> | null;
  tempVisualizationId: string | null;
}

interface WidgetCreationResponse {
  data: {
    widgetId: string;
  };
}

const TEMP_ID_PREFIX = 'TEMP_VIZ';

/**
 * Generates a temporary visualization ID using the TEMP_ID_PREFIX and a ULID
 * @returns A temporary visualization ID string
 */
export const createTempVisualizationId = (): string => `${TEMP_ID_PREFIX}-${ulid()}`;

/**
 * Checks if the given ID is a temporary visualization ID
 * @param id - The ID to check
 * @returns boolean indicating if the ID is a temporary visualization ID
 */
export const isTempVisualizationId = (id: string): boolean => id.startsWith(TEMP_ID_PREFIX);

/**
 * Hook that provides utilities for managing dashboard updates, including optimistic updates,
 * rollbacks, and success handling.
 *
 * @param dashboardId - The ID of the dashboard being modified
 * @returns Object containing handlers for different dashboard update scenarios
 */
export const useDashboardUpdates = (dashboardId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  /**
   * Updates the dashboard layout in the cache
   */
  const updateDashboardLayout = (allLayouts: Layouts) => {
    const currentDashboardData = queryClient.getQueryData<AxiosResponse<DashboardModel>>(
      dashboardKeys.getDashboardDetails(workspaceId, dashboardId),
    );

    if (currentDashboardData) {
      queryClient.setQueryData(dashboardKeys.getDashboardDetails(workspaceId, dashboardId), {
        ...currentDashboardData,
        data: {
          ...currentDashboardData.data,
          uiLayout: {
            version: DashboardUILayoutModelV1VersionEnum.V1,
            layout: JSON.stringify(allLayouts),
          },
        },
      });
    }
  };

  /**
   * Handles optimistic updates when adding a widget to the dashboard
   * @returns Object containing the previous dashboard state and temporary visualization ID
   */
  const handleOptimisticUpdate = async (): Promise<DashboardUpdateState> => {
    // Cancel any outgoing refetches
    await queryClient.cancelQueries(dashboardKeys.all);

    // Get the current dashboard data
    const previousDashboard = queryClient.getQueryData<AxiosResponse<DashboardModel>>(
      dashboardKeys.getDashboardDetails(workspaceId, dashboardId),
    );

    if (!previousDashboard) {
      return { previousDashboard: null, tempVisualizationId: null };
    }

    const tempVisualizationId = createTempVisualizationId();
    const newDashboardLayout = addWidgetToDashboard(
      previousDashboard.data,
      24,
      tempVisualizationId,
    );

    // Optimistically update the dashboard layout
    updateDashboardLayout(newDashboardLayout);

    return { previousDashboard, tempVisualizationId };
  };

  /**
   * Handles rolling back the dashboard state in case of an error
   */
  const handleRollback = (previousDashboard: AxiosResponse<DashboardModel> | null) => {
    if (previousDashboard) {
      queryClient.setQueryData(dashboardKeys.getDashboardDetails(workspaceId, dashboardId), {
        ...previousDashboard,
      });
    }
  };

  /**
   * Handles updating the dashboard after successful widget creation
   */
  const handleSuccess = (response: WidgetCreationResponse, tempVisualizationId: string | null) => {
    const currentDashboardData = queryClient.getQueryData<AxiosResponse<DashboardModel>>(
      dashboardKeys.getDashboardDetails(workspaceId, dashboardId),
    );

    if (currentDashboardData && tempVisualizationId) {
      const layouts = parseLayoutJson(currentDashboardData.data.uiLayout);

      const updatedLayouts = Object.keys(layouts).reduce((acc, key) => {
        acc[key] = layouts[key].map(l =>
          l?.i === tempVisualizationId ? { ...l, i: response.data.widgetId } : l,
        );
        return acc;
      }, {} as Layouts);

      queryClient.setQueryData(dashboardKeys.getDashboardDetails(workspaceId, dashboardId), {
        ...currentDashboardData,
        data: {
          ...currentDashboardData.data,
          uiLayout: {
            version: DashboardUILayoutModelV1VersionEnum.V1,
            layout: JSON.stringify(updatedLayouts),
          },
        },
      });
    }
  };

  return {
    handleOptimisticUpdate,
    handleRollback,
    handleSuccess,
    updateDashboardLayout,
  };
};

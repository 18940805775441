import { PropsWithChildren, createContext, useContext, useState } from 'react';

export interface DashboardWithDataAnalyticsContextState {
  dashboardId: string;
  resourceId?: string;
  setResourceId: (resourceId: string) => void;
  isEditMode: boolean;
  setIsEditMode: (mode: boolean) => void;
  isCollapsed: boolean;
  toggleCollapsed: () => void;
  activeDataSourceIds: string[];
  addDataSourceId: (dataSourceId: string) => void;
}

const DashboardWithDataAnalyticsContext =
  createContext<DashboardWithDataAnalyticsContextState | null>(null);

export const useDashboardWithDataAnalyticsContext = () => {
  const context = useContext(DashboardWithDataAnalyticsContext);
  if (!context) {
    throw new Error('useQuestionTypeContext should only be used inside the QuestionTypeProvider');
  }
  return context;
};

interface DashboardWithDataAnalyticsProviderProps {
  resourceId?: string;
  dashboardId: string;
}

export const DashboardWithDataAnalyticsProvider = ({
  children,
  resourceId,
  dashboardId,
}: PropsWithChildren<DashboardWithDataAnalyticsProviderProps>) => {
  const [dataSourceId, setDataSourceId] = useState<string | undefined>(resourceId);
  const [isEditMode, setIsEditMode] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);

  // State for tracking unique data sources across widgets
  const [activeDataSourceIds, setDataSourcesIds] = useState<string[]>([]);

  const handleResourceIdSelection = (resourceId: string) => {
    setDataSourceId(resourceId);
  };

  const toggleCollapsed = () => {
    setIsCollapsed(prev => !prev);
  };

  const addDataSourceId = (dataSourceId: string) => {
    if (dataSourceId) {
      setDataSourcesIds(prev => [...new Set([...prev, dataSourceId])]);
    }
  };

  return (
    <DashboardWithDataAnalyticsContext.Provider
      value={{
        dashboardId,
        resourceId: dataSourceId,
        setResourceId: handleResourceIdSelection,
        isEditMode,
        setIsEditMode,
        isCollapsed,
        toggleCollapsed,
        activeDataSourceIds,
        addDataSourceId,
      }}
    >
      {children}
    </DashboardWithDataAnalyticsContext.Provider>
  );
};

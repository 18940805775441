import { RouteProps, Routes } from 'react-router-dom';
import { renderAuthRoute } from '../AuthRouter';
import { AppRoutes, getRoute } from '../constants';
import { lazyWithRetry } from '../util';

const AppDetails = lazyWithRetry(() => import('@/main/pages/Workflows/apps/AppDetails'));
const AppBuilderDetails = lazyWithRetry(
  () => import('@/main/pages/Workflows/apps/AppBuilderDetails'),
);
const AppRunDetail = lazyWithRetry(() => import('@/main/pages/Workflows/apps/AppRun'));
const AppsList = lazyWithRetry(() => import('@/main/pages/Workflows/apps/AppsList'));

export const AppRuns = () => <>App run</>;

const workflowRuns = `${getRoute(AppRoutes.WORKFLOW_APPS_DETAILS)}/${getRoute(
  AppRoutes.WORKFLOW_APPS_RUNS,
)}`;
const workflowRunDetail = `${workflowRuns}/${getRoute(AppRoutes.WORKFLOW_APPS_RUN_DETAIL)}`;

const appBuilderDetails = `${getRoute(AppRoutes.WORKFLOW_APPS_BUILDER)}/${getRoute(
  AppRoutes.WORKFLOW_DETAIL,
)}`;

const appsRoutes: RouteProps[] = [
  {
    path: '/',
    element: <AppsList />,
  },
  {
    path: appBuilderDetails,
    element: <AppBuilderDetails />,
  },
  {
    path: getRoute(AppRoutes.WORKFLOW_APPS_DETAILS),
    element: <AppDetails />,
  },
  {
    path: workflowRuns,
    element: <AppRuns />,
  },
  {
    path: workflowRunDetail,
    element: <AppRunDetail />,
  },
];

export const AppsRouter = (): JSX.Element => <Routes>{appsRoutes.map(renderAuthRoute)}</Routes>;

import { Box, closeAllModals } from '@/shared/design-system/v2';
import { AsyncDropDownResources } from '../../../generated/api';
import { useInvalidateDataSourcesList } from '../../../queries/data-sources';
import { CompleteParams } from '../../../queries/data-upload';
import { useInvalidateResourceLookup } from '../../../queries/resource-lookup';
import { FileUploader } from '../../common/uploader/file-uploader/FileUploader';

interface DataSourcesFileModalProps {
  onFileUploadComplete?: (state: 'success' | 'error', completeParams: CompleteParams) => void;
  supportedFileTypes?: string[];
}

// Deprecated - use UploadDataSourcesFile
export const DataSourcesFileModal = ({
  onFileUploadComplete,
  supportedFileTypes = ['csv', 'txt', 'pdf', 'jpeg', 'jpg'],
}: DataSourcesFileModalProps) => {
  const invalidateDataSources = useInvalidateDataSourcesList();
  const invalidateResources = useInvalidateResourceLookup(AsyncDropDownResources.DataResources);

  const onUploadQueued = () => {
    closeAllModals();
  };

  const onUploadComplete = (state: 'success' | 'error', completeParams: CompleteParams) => {
    invalidateDataSources();
    invalidateResources();
    onFileUploadComplete?.(state, completeParams);
  };

  return (
    <Box px={24} pb={24}>
      <FileUploader
        onUploadQueued={onUploadQueued}
        onUploadComplete={onUploadComplete}
        showPreviewInModal
        supportedFileTypes={supportedFileTypes}
      />
    </Box>
  );
};

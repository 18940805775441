import { IconCircleCheckFilled, IconCircleXFilled, IconFolderUp, IconX } from '@tabler/icons-react';
import {
  Accordion,
  ActionIcon,
  Affix,
  Box,
  Horizontal,
  Loader,
  ScrollArea,
  Text,
} from '@/shared/design-system/v2';
import { rem, useMantineTheme } from '@/shared/design-system/v2/styles';
import { FileData, UploadState } from './types';
import { useDataUpload } from './useFileUploadProvider';
import { getFileIcon, getUploadStatus, renderUploadCount, renderUploadStatusText } from './utils';

interface UploadStatusDisplayProps {
  position?: {
    bottom?: string | number;
    right?: string | number;
  };
}

export function UploadStatusDisplay({
  position = { bottom: rem(20), right: rem(90) },
}: UploadStatusDisplayProps) {
  const theme = useMantineTheme();
  const { windowUploads, removeUpload } = useDataUpload();

  if (windowUploads.length === 0) {
    return null;
  }

  return (
    <Affix position={{ bottom: position.bottom, right: position.right }}>
      <Accordion
        w={360}
        defaultValue="uploads"
        variant="separated"
        radius="md"
        chevronPosition="right"
        sx={theme => ({
          border: `1px solid ${theme.colors.gray[2]}`,
          borderRadius: theme.radius.md,
        })}
      >
        <Accordion.Item px="xs" value="uploads">
          <Accordion.Control icon={<IconFolderUp color={theme.colors.gray[7]} />}>
            <Horizontal>
              <Text variant="subTitle05">Uploads</Text>
            </Horizontal>
          </Accordion.Control>

          <Accordion.Panel>
            {windowUploads.map((upload, index) => (
              <Accordion
                key={upload.id}
                radius="md"
                defaultValue={`upload-${index}`}
                variant="filled"
                chevronPosition="right"
              >
                <Accordion.Item value={`upload-${index}`}>
                  <Horizontal px="xs" noWrap spacing={0}>
                    <Accordion.Control>
                      <Horizontal
                        spacing={4}
                        align="flex-start"
                        style={{ flexDirection: 'column' }}
                      >
                        {renderUploadCount(
                          upload.fileData.length,
                          getUploadStatus(upload.fileData),
                        )}
                        {upload.fileData.length > 0 &&
                          renderUploadStatusText(getUploadStatus(upload.fileData))}
                      </Horizontal>
                    </Accordion.Control>

                    <ActionIcon
                      color="gray.7"
                      onClick={() => removeUpload(upload.id)}
                      disabled={getUploadStatus(upload.fileData).isInProgress}
                      title={
                        getUploadStatus(upload.fileData).isInProgress
                          ? 'Cannot remove while uploads are in progress'
                          : 'Remove uploads'
                      }
                    >
                      <IconX size={16} />
                    </ActionIcon>
                  </Horizontal>

                  <ScrollArea.Autosize mah={300}>
                    <Accordion.Panel>
                      {upload.fileData.map((file: FileData) => (
                        <Horizontal
                          key={`${upload.id}-${file.file.name}`}
                          spacing="sm"
                          p="xs"
                          noWrap
                          position="apart"
                        >
                          <Horizontal
                            noWrap
                            spacing="xs"
                            sx={{ opacity: file.state === UploadState.UPLOAD_QUEUED ? 0.3 : 1 }}
                          >
                            <Box sx={{ flexShrink: 0 }}>{getFileIcon(file.file.name)}</Box>
                            <Text variant="small03" lineClamp={1}>
                              {file.file.name}
                            </Text>
                          </Horizontal>

                          <Box sx={{ flexShrink: 0 }}>
                            {file.state === UploadState.UPLOAD_QUEUED ? (
                              <Loader size="xs" color="gray" />
                            ) : file.state === UploadState.UPLOAD_STARTED ? (
                              <Loader size="xs" />
                            ) : file.state === UploadState.UPLOAD_ERRORED ? (
                              <IconCircleXFilled color="red" size={16} />
                            ) : (
                              <IconCircleCheckFilled color="green" size={16} />
                            )}
                          </Box>
                        </Horizontal>
                      ))}
                    </Accordion.Panel>
                  </ScrollArea.Autosize>
                </Accordion.Item>
              </Accordion>
            ))}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Affix>
  );
}

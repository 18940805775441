import { IconDatabasePlus, IconUpload } from '@tabler/icons-react';
import noop from 'lodash/noop';
import { useCreateDataAnalyticsMutation } from '@/main/queries/data-analytics';
import { CompleteParams } from '@/main/queries/data-upload';
import {
  Box,
  Button,
  Card,
  Center,
  Horizontal,
  Image,
  Loader,
  Space,
  Text,
  Vertical,
  closeAllModals,
  notifications,
  openModal,
} from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';
import { dataAnalyticsSupportedFiletypes } from '../../common/uploader/file-uploader/util';
import { useDataSourcesFileUploadModal } from '../../data-sources/modals/use-data-sources-file-upload';
import { DATA_ANALYTICS_NAME } from '../util';
import { useDataAnalyticsResourceSelectionModal } from '../v2/DataAnalyticsResourceSelectionModal';
import { getSampleDataResourceID } from './SampleDataModal';

interface NewChatModalProps {
  onDataAnalyticsCreate: (resourceId: string) => void;
}

const NewChatModal = ({ onDataAnalyticsCreate }: NewChatModalProps) => {
  const { open: openDataSourceSelectionModal } = useDataAnalyticsResourceSelectionModal({
    onDataAnalyticsCreate,
  });

  const { isLoading: isDataAnalyticsLoading, mutateAsync: createDataAnalytics } =
    useCreateDataAnalyticsMutation();

  const handleCreateDataAnalytics = async (resourceId: string) => {
    try {
      await createDataAnalytics({ resourceId });
    } catch (err) {
      logger.error(err);
      notifications.error(`Error encountered while creating ${DATA_ANALYTICS_NAME}`);
      return;
    }

    onDataAnalyticsCreate(resourceId);
    closeAllModals();
  };

  const handleFileUploadComplete = async (
    state: 'success' | 'error',
    completeParams: CompleteParams,
  ) => {
    if (state === 'success') {
      const resourceId = completeParams.key;
      handleCreateDataAnalytics(resourceId);
    }
  };

  const handleSelectSampleData = () => {
    const sampleDataResourceID = getSampleDataResourceID();
    handleCreateDataAnalytics(sampleDataResourceID);
  };

  const openFileUploader = useDataSourcesFileUploadModal({
    onFileUploadComplete: handleFileUploadComplete,
    supportedFileTypes: dataAnalyticsSupportedFiletypes,
  });

  if (isDataAnalyticsLoading) {
    return (
      <Center h="100%">
        <Loader text={`Opening ${DATA_ANALYTICS_NAME}...`} />
      </Center>
    );
  }

  const options = [
    {
      title: 'Upload your dataset',
      description: `Supported file formats: ${dataAnalyticsSupportedFiletypes.join(', ')}`,
      illustrationUrl:
        'https://ik.imagekit.io/markovml/data-analytics/upload-box_krYNcCsmh.svg?updatedAt=1740079981721',
      button: (
        <Button variant="outline" leftIcon={<IconUpload />} onClick={openFileUploader}>
          Use a file
        </Button>
      ),
    },
    {
      title: 'Connect supported integrations',
      description: 'Read data from Postgres, Snowflake & more',
      illustrationUrl:
        'https://ik.imagekit.io/markovml/data-analytics/database_6tZ_c8PXe.svg?updatedAt=1740079981404',
      button: (
        <Button
          variant="outline"
          leftIcon={<IconDatabasePlus />}
          onClick={openDataSourceSelectionModal}
        >
          Use Integration
        </Button>
      ),
    },
  ];

  return (
    <Vertical spacing="lg">
      <Text variant="subTitle03" color="gray.7">
        Here are ways you can add your data
      </Text>
      <Horizontal grow noWrap align="start">
        {options.map(item => (
          <Card key={item.title} withBorder hoverEffect={false} p="lg" radius="sm">
            <Vertical>
              <Image src={item.illustrationUrl} width={32} />
              <Box>
                <Text variant="subTitle02" color="gray.8">
                  {item.title}
                </Text>
                <Text variant="bodyShort03" color="dark.3">
                  {item.description}
                </Text>
              </Box>
            </Vertical>
            <Space h={28} />
            {item.button}
          </Card>
        ))}
      </Horizontal>
      {/* TODO: Expose Sample data option once list of sample data sources is available */}
      <Center>
        <Horizontal spacing="xs">
          <Text variant="subTitle03">Not ready yet?</Text>
          <Text
            variant="subTitle03"
            color="blue.6"
            onClick={handleSelectSampleData}
            sx={{ cursor: 'pointer' }}
          >
            Try sample data instead
          </Text>
        </Horizontal>
      </Center>
    </Vertical>
  );
};

interface useNewChatModalProps {
  onDataAnalyticsCreate?: (resourceId: string) => void;
}

export const useNewChatModal = ({ onDataAnalyticsCreate }: useNewChatModalProps) => {
  const open = () =>
    openModal({
      title: <Text variant="heading03">Add your data</Text>,
      size: 'xl',
      children: <NewChatModal onDataAnalyticsCreate={onDataAnalyticsCreate ?? noop} />,
    });

  return { open };
};

import { Card, Flex, Text, Vertical } from '@/shared/design-system/v2';

interface IntroCardProps {
  title: string;
  buttonText: string;
  icon: JSX.Element;
  onClick: () => void;
}

export const IntroCard = ({ title, buttonText, icon, onClick }: IntroCardProps) => (
  <Card
    w={320}
    h={80}
    radius="sm"
    withBorder
    onClick={onClick}
    sx={{ '&:hover': { boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px!important' } }}
  >
    <Flex gap="sm">
      {icon}
      <Vertical spacing="xs">
        <Text variant="subTitle02" color="gray.8">
          {title}
        </Text>
        <Text variant="subTitle04" color="blue.6">
          {buttonText}
        </Text>
      </Vertical>
    </Flex>
  </Card>
);

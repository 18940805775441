import { createStyles, useMarkovTheme } from '@/shared/design-system/v2';

export type SidebarColorScheme = 'data-studio' | 'model-studio' | 'default';

interface SidebarColors {
  bg: string;
  fill: string;
  textFill: string;
  bgHover: string;
  fillHover: string;
  bgActive: string;
  fillActive: string;
}

export const useSidebarColors = (scheme: SidebarColorScheme = 'default') => {
  const theme = useMarkovTheme();

  const sidebarColors: Record<SidebarColorScheme, SidebarColors> = {
    'model-studio': {
      bg: theme.colors.white[0],
      fill: theme.colors.gray[6],
      textFill: theme.colors.gray[7],
      bgHover: theme.colors.blue[0],
      fillHover: theme.colors.blue[6],
      bgActive: theme.colors.blue[0],
      fillActive: theme.colors.blue[6],
    },
    'data-studio': {
      bg: theme.colors.white[0],
      fill: theme.colors.gray[7],
      textFill: theme.colors.gray[7],
      bgHover: theme.colors.blue[0],
      fillHover: theme.colors.blue[6],
      bgActive: theme.colors.blue[0],
      fillActive: theme.colors.blue[7],
    },
    default: {
      bg: theme.colors.dark[5],
      fill: theme.colors.gray[6],
      textFill: theme.colors.gray[6],
      bgHover: theme.colors.dark[4],
      fillHover: theme.colors.gray[0],
      bgActive: theme.colors.blue[6],
      fillActive: theme.colors.gray[0],
    },
  };

  return sidebarColors[scheme];
};

interface SidebarStyleParams {
  colorScheme?: SidebarColorScheme;
}

export const useSidebarStyles = createStyles((theme, params: SidebarStyleParams | void) => {
  const { bg, fill, textFill, bgHover, fillHover, bgActive, fillActive } = useSidebarColors(
    params?.colorScheme,
  );

  const iconStyles = {
    color: fill,
  };

  const iconHoverStyles = {
    svg: {
      color: fillHover,
    },
  };

  const iconActiveStyles = {
    svg: {
      color: fillActive,
    },
  };

  return {
    navSection: {
      borderRight: `1px solid ${theme.colors.gray[2]}`,
      transition: 'width 0.2s ease-out',
      display: 'flex',
      flexDirection: 'column',
    },
    navButton: {
      padding: '8px',
      borderRadius: theme.radius.sm,
      textDecoration: 'none',
      backgroundColor: bg,
      color: textFill,

      svg: {
        width: '24px',
        height: '24px',
        ...iconStyles,
      },

      '&:hover': {
        backgroundColor: bgHover,
        color: fillHover,
        ...iconHoverStyles,
      },
    },

    active: {
      '&, &:hover': {
        color: fillActive,
        backgroundColor: bgActive,
        ...iconActiveStyles,
      },
    },
  };
});

export const useNewSidebarMenuStyles = createStyles(theme => ({
  dropdown: {
    backgroundColor: theme.colors.dark[6],
    borderColor: theme.colors.dark[6],
    padding: 0,
    marginLeft: '-8px',
  },
  itemIcon: {
    svg: {
      width: '24px',
      height: '24px',
      color: theme.colors.dark[1],
    },
  },
  item: {
    backgroundColor: theme.colors.dark[6],
    color: theme.colors.gray[0],
    '&:hover': {
      backgroundColor: theme.colors.dark[4],
      color: theme.colors.blue[0],
      svg: {
        color: theme.colors.gray[0],
      },
    },
  },
  active: {
    color: theme.colors.gray[0],
    backgroundColor: theme.colors.blue[8],
    svg: {
      color: theme.colors.gray[0],
    },
  },
}));

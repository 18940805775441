import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppMetadata } from '@/main/contexts/app-metadata/AppMetadata';
import {
  ChartType,
  CreateWidgetRequestModel,
  UpdateWidgetBrainstormConfigurationRequestModel,
  WidgetType,
  WidgetUIConfigurationModel,
} from '@/main/generated/api';
import { dashboardAndWidgetApi, dataAnalyticsApi } from '@/shared/lib/api/oslo';
import { isTempVisualizationId, useDashboardUpdates } from './dashboard-update.utils';

const ROOT_KEY = 'widget';

const widgetKeys = {
  all: [ROOT_KEY],
  getWidgetDetails: (workspaceId: string, widgetId: string) => [
    ROOT_KEY,
    'details',
    workspaceId,
    widgetId,
  ],
  getWidgetData: (workspaceId: string, widgetId: string) => [
    ROOT_KEY,
    'data',
    workspaceId,
    widgetId,
  ],
  getWidgetBrainstormData: (workspaceId: string, widgetId: string) => [
    ROOT_KEY,
    'brainstorm-query',
    workspaceId,
    widgetId,
  ],
};

export const useGetWidgetDetailsQuery = (widgetId = '') => {
  const { workspaceId } = useAppMetadata();

  return useQuery({
    queryKey: widgetKeys.getWidgetDetails(workspaceId, widgetId),
    queryFn: () => dashboardAndWidgetApi.getWidgetV1(workspaceId, widgetId),
    select: data => data.data,
    enabled: !!widgetId && !isTempVisualizationId(widgetId),
  });
};

export const useGetWidgetDataQuery = (widgetId = '') => {
  const { workspaceId } = useAppMetadata();

  return useQuery({
    queryKey: widgetKeys.getWidgetData(workspaceId, widgetId),
    queryFn: () => dataAnalyticsApi.getWidgetDataV1(workspaceId, widgetId),
    enabled: Boolean(widgetId),
    select: data => data.data,
  });
};

interface WidgetRequest {
  dataAnalyticsId: string;
  conversationId: string;
  visualizationType?: WidgetType;
  chartType?: ChartType;
}

export const useCreateWidgetMutation = (dashboardId: string) => {
  const { workspaceId } = useAppMetadata();
  const { handleOptimisticUpdate, handleRollback, handleSuccess } =
    useDashboardUpdates(dashboardId);

  return useMutation({
    mutationFn: (widgetReq: WidgetRequest) =>
      dataAnalyticsApi.createDataAnalyticsWidgetV1(
        workspaceId,
        widgetReq.dataAnalyticsId,
        widgetReq.conversationId,
        widgetReq.visualizationType,
        widgetReq.chartType,
      ),
    onMutate: handleOptimisticUpdate,
    onError: (_err, _newWidget, context) => {
      handleRollback(context?.previousDashboard ?? null);
    },
    onSuccess: (response, _variables, context) => {
      handleSuccess(response, context?.tempVisualizationId ?? null);
    },
  });
};

export const useCreateVisualizationMutation = (dashboardId: string) => {
  const { workspaceId } = useAppMetadata();
  const { handleOptimisticUpdate, handleRollback, handleSuccess } =
    useDashboardUpdates(dashboardId);

  return useMutation({
    mutationFn: (widgetReq: CreateWidgetRequestModel) =>
      dashboardAndWidgetApi.createWidgetV1(workspaceId, widgetReq),
    onMutate: handleOptimisticUpdate,
    onError: (_err, _newWidget, context) => {
      handleRollback(context?.previousDashboard ?? null);
    },
    onSuccess: (response, _variables, context) => {
      handleSuccess(response, context?.tempVisualizationId ?? null);
    },
  });
};

export const useUpdateVisualizationConfigurationMutation = (widgetId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (uiConfiguration: WidgetUIConfigurationModel) =>
      dashboardAndWidgetApi.updateWidgetUIConfigurationV1(workspaceId, widgetId, {
        uiConfiguration,
      }),
    onSuccess() {
      queryClient.invalidateQueries(widgetKeys.getWidgetDetails(workspaceId, widgetId));
    },
  });
};

export const useGetBrainstormExplanationQuery = (widgetId: string, forceRefresh = false) => {
  const { workspaceId } = useAppMetadata();

  return useQuery({
    queryKey: widgetKeys.getWidgetBrainstormData(workspaceId, widgetId),
    queryFn: () => dataAnalyticsApi.getWidgetBrainstormDataV1(workspaceId, widgetId, forceRefresh),
    select: data => data.data,
  });
};

export const useUpdateWidgetBrainstormConfigurationMutation = (widgetId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  const { refetch } = useGetBrainstormExplanationQuery(widgetId, true);

  return useMutation({
    mutationFn: (brainstormConfiguration: UpdateWidgetBrainstormConfigurationRequestModel) =>
      dashboardAndWidgetApi.updateWidgetBrainstormConfigurationV1(
        workspaceId,
        widgetId,
        brainstormConfiguration,
      ),
    onMutate: () => {
      //inplemet
    },
    onSuccess() {
      queryClient.invalidateQueries(widgetKeys.getWidgetDetails(workspaceId, widgetId));

      // Invalidate the query with forceRefresh=true to ensure it's refetched with this parameter
      // TODO: Ideally this logic should be handled by BE; but since code is present at two places: Vienna
      // and Oslo, it is harder to do the invalidation on the BE side unless we write an internal API.
      refetch();
    },
  });
};

import { IconTextSize } from '@tabler/icons-react';
import { TextConfigurationModelV1VersionEnum, WidgetType } from '@/main/generated/api';
import { useCreateVisualizationMutation } from '@/main/queries/dashboard/widget';
import { Button, Card, Horizontal, Text, useMarkovTheme } from '@/shared/design-system/v2';

interface DashboardHeaderProps {
  dashboardId: string;
}

export const DashboardHeader = ({ dashboardId }: DashboardHeaderProps) => {
  const theme = useMarkovTheme();

  const { mutate: createVisualization } = useCreateVisualizationMutation(dashboardId);

  const handleInsertTextBlock = () => {
    createVisualization({
      widgetType: WidgetType.Text,
      name: 'Text Block',
      uiConfiguration: {
        text: {
          version: TextConfigurationModelV1VersionEnum.V1,
          content: '',
        },
      },
    });
  };

  const insertBlockOptions = [
    {
      label: 'Text',
      icon: IconTextSize,
      onClick: handleInsertTextBlock,
    },
  ];

  return (
    <Card
      w="fit-content"
      m="md"
      py="xs"
      radius="sm"
      hoverEffect={false}
      sx={{ boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.1)' }}
    >
      <Horizontal>
        <Text variant="subTitle03" color="gray.7" sx={{ userSelect: 'none' }}>
          Insert Block
        </Text>
        {insertBlockOptions.map(({ label, icon: Icon, onClick }) => (
          <Button
            key={label}
            variant="light"
            color="gray.6"
            leftIcon={<Icon size={16} color={theme.colors.gray[6]} />}
            compact
            onClick={onClick}
          >
            <Text variant="small01" color="gray.6">
              {label}
            </Text>
          </Button>
        ))}
      </Horizontal>
    </Card>
  );
};

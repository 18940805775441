import first from 'lodash/first';
import get from 'lodash/get';
import { useState } from 'react';
import { EXTRA_DATA_FIELD } from '@/main/components/common/form/renderers/util';
import { useCreateWorkflow } from '@/main/contexts/workflows/CreateWorkflow';
import { useWorkflowRunContext } from '@/main/contexts/workflows/WorkflowRunContext';
import { OperatorModel } from '@/main/generated/api';
import { useGetWorkflowDAGQuery } from '@/main/queries/workflows/dag';
import { useDownloadWorkflowNodeOutputMutation } from '@/main/queries/workflows/debug';
import { useGetWorkflowRunDetailsQuery } from '@/main/queries/workflows/runs/runs';
import { Alert, Horizontal, Modal, Text, notifications } from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';
import { downloadFromUrl } from '@/shared/lib/util';
import { transformEdgeToWorkflowEdge } from '../../../edges/util';
import { WorkflowNode, findNodesAfter, transformNodeToWorkflowNode } from '../../../nodes/utils';
import { Mode, findSourceNodes } from '../../utils';
import { NodeFormView } from './NodeFormView';
import { OperatorExampleModal } from './OperatorExampleModal';
import { FormDataTypes } from './util';

interface FormModalProps {
  id: string;
  isOpen: boolean;
  handleFormClose: () => void;
  operatorModel?: OperatorModel;
  configuration?: Record<string, FormDataTypes>;
}

export const FormModal = ({
  id,
  isOpen,
  handleFormClose,
  operatorModel,
  configuration,
}: FormModalProps): JSX.Element => {
  const { workflowId, nodes, setNodes, edges, saveWorkflowDAG, isSaving, isAppBuilder } =
    useCreateWorkflow();
  const { runId } = useWorkflowRunContext();

  const [data, setData] = useState(configuration);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const handleFormSubmit = async () => {
    const extraData = get(data, EXTRA_DATA_FIELD, []);

    const newNodes = nodes.map<WorkflowNode>(node => {
      if (node.id === id) {
        return {
          ...node,
          data: {
            ...node.data,
            configuration: data ?? {},
            additional: {
              ...node.data.additional,
              [EXTRA_DATA_FIELD]: Array.isArray(extraData) ? extraData : [],
            },
          },
        };
      }
      return node;
    });

    const nodesAfter = findNodesAfter(id, edges);

    saveWorkflowDAG(newNodes, edges, true, nodesAfter);
    setNodes(newNodes);
    notifications.success('Changes saved successfully');
  };

  const handleClose = () => {
    setData(configuration);
    handleFormClose();
  };

  const prevNodeId = first(findSourceNodes({ nodes, edges }, id));
  const isExampleAvailable = !!operatorModel?.example;

  return (
    <Modal
      withinPortal
      opened={isOpen}
      onClose={handleClose}
      size="1480px"
      padding={0}
      title={
        <Horizontal spacing={0} px="24px" pt="lg" pb="sm">
          <Text variant="heading04">{operatorModel?.name}</Text>
          {isExampleAvailable && <OperatorExampleModal operator={operatorModel} />}
        </Horizontal>
      }
      closeButtonProps={{
        ml: 0,
        mr: '24px',
      }}
    >
      {operatorModel ? (
        <NodeFormView
          workflowId={workflowId}
          runId={runId}
          nodeId={id}
          operatorModel={operatorModel}
          data={data}
          setData={setData}
          isFormDirty={isFormDirty}
          setIsFormDirty={setIsFormDirty}
          initialState={configuration}
          onFormSubmit={handleFormSubmit}
          onFormClose={handleFormClose}
          prevNodeId={prevNodeId}
          isFormSaving={isSaving}
          viewOnly={false}
          isAppBuilder={isAppBuilder}
        />
      ) : (
        <Alert>Could not find operator</Alert>
      )}
    </Modal>
  );
};

interface ReadOnlyFormModalProps {
  id: string;
  workflowId: string;
  operatorModel?: OperatorModel;
  configuration?: Record<string, FormDataTypes>;
  isOpen: boolean;
  handleClose: () => void;
  isDebugMode: boolean;
}

export const ReadOnlyFormModal = ({
  id,
  workflowId,
  operatorModel,
  configuration,
  isOpen,
  handleClose,
  isDebugMode,
}: ReadOnlyFormModalProps): JSX.Element => {
  const { data } = useGetWorkflowDAGQuery(workflowId);
  const [formData, setFormData] = useState(configuration);
  const { runId } = useWorkflowRunContext();

  const { data: run } = useGetWorkflowRunDetailsQuery(workflowId, runId);

  const { mutateAsync: downloadOutput, isLoading: isDownloading } =
    useDownloadWorkflowNodeOutputMutation(workflowId, runId);

  const handleDownloadClick = async () => {
    try {
      const data = await downloadOutput({ nodeId: id });

      if (!data.data.downloadLink) {
        return;
      }

      downloadFromUrl(data.data.downloadLink ?? '', data.data.fileName ?? '');
    } catch (error) {
      notifications.error('There was an error downloading your results. Please try again.');
      logger.error(error);
    }
  };

  const prevNodeId =
    operatorModel && data
      ? first(
          findSourceNodes(
            {
              nodes: data.nodes.map(n => transformNodeToWorkflowNode(n, [operatorModel])),
              edges: data.edges.map(edge => transformEdgeToWorkflowEdge(edge)),
            },
            id,
          ),
        )
      : undefined;

  const fileDownloadForNode = get(run, `nodeWiseOutput.${id}.fileDownload`);

  return (
    <Modal
      withinPortal
      opened={isOpen}
      onClose={handleClose}
      size="1480px"
      padding={0}
      title={
        <Horizontal spacing={0} px="24px" pt="lg" pb="xs">
          <Text variant="heading04">{operatorModel?.name}</Text>
        </Horizontal>
      }
      closeButtonProps={{
        mr: '24px',
      }}
    >
      {operatorModel && data ? (
        <NodeFormView
          workflowId={workflowId}
          nodeId={id}
          runId={runId}
          data={formData}
          setData={setFormData}
          operatorModel={operatorModel}
          initialState={configuration}
          onFormClose={handleClose}
          viewOnly
          prevNodeId={prevNodeId}
          mode={isDebugMode ? Mode.Debug : Mode.Build}
          onResultsDownload={fileDownloadForNode ? handleDownloadClick : undefined}
        />
      ) : (
        <Alert>Could not find operator</Alert>
      )}
    </Modal>
  );
};

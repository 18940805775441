import { IconInfoCircle } from '@tabler/icons-react';
import { PropsWithChildren } from 'react';
import { HoverCard, Text, useMarkovTheme } from '@/shared/design-system/v2';

export const InputInfoTooltip = ({ children }: PropsWithChildren) => {
  const theme = useMarkovTheme();
  return (
    <HoverCard withArrow openDelay={0} closeDelay={0} withinPortal>
      <HoverCard.Target>
        <IconInfoCircle size={20} style={{ paddingTop: 4, color: theme.colors.gray[6] }} />
      </HoverCard.Target>
      <HoverCard.Dropdown maw={500} bg="dark.7" sx={{ border: 'none' }}>
        <Text p={8} color="white.0" sx={{ whiteSpace: 'pre-wrap', lineHeight: 1.5 }}>
          {children}
        </Text>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

import { ArtifactStateType, ExecutionType, Operator } from '@/main/generated/api';

export enum FilterableFields {
  ARTIFACT_STATE = 'artifactState',
  SCHEDULE_STATUS = 'scheduleStatus',
  DEMO_ARTIFACT = 'demoArtifact',
  IS_WORKFLOW_APP = 'isWorkflowApp',
  APP_STATUS = 'appStatus',
}

export const scheduledOnlyFilter = {
  field: FilterableFields.SCHEDULE_STATUS,
  operator: Operator.In,
  value: ExecutionType.Scheduled,
};

export const lockedOnlyFilter = {
  field: FilterableFields.ARTIFACT_STATE,
  operator: Operator.In,
  value: ArtifactStateType.Active,
};

export const excludeDemoArtifactFilter = {
  field: FilterableFields.DEMO_ARTIFACT,
  operator: Operator.Eq,
  value: false,
};

export const excludeAppWorkflows = {
  field: FilterableFields.IS_WORKFLOW_APP,
  operator: Operator.NotEq,
  value: true,
};

export const EMPTY_SCREEN_ILLUSTRATION =
  'https://ik.imagekit.io/markovml/homepage/illustration-automations-empy.svg?updatedAt=1740074458874';

import { DataAnalyticsSuggestedQuestion } from '@/main/components/data-analytics/v2/DataAnalyticsSuggestedQuestion';
import { FollowUpQuestionTemplate } from '@/main/generated/api';
import { Vertical } from '@/shared/design-system/v2';

const SUGGESTED_QUESTIONS: FollowUpQuestionTemplate[] = [
  {
    questionTemplate: 'What patterns or trends stand out?',
    placeholderMappings: [],
  },
  {
    questionTemplate: 'Summarize this chart for me.',
    placeholderMappings: [],
  },
  {
    questionTemplate: 'What are the key takeaways from this data?',
    placeholderMappings: [],
  },
];

interface BrainstormEmptyContentProps {
  onQuestionClick: (question: string) => void;
}

export const BrainstormEmptyContent = ({ onQuestionClick }: BrainstormEmptyContentProps) => (
  <Vertical pt="sm">
    {SUGGESTED_QUESTIONS.map((question, index) => (
      <DataAnalyticsSuggestedQuestion
        key={index}
        onClick={onQuestionClick}
        questionWithTemplate={question}
      />
    ))}
  </Vertical>
);

import { createStyles } from '@mantine/core';

export const useStyles = createStyles(theme => ({
  grid: {
    backgroundSize: '36px 36px',
    backgroundImage: `
        linear-gradient(to right, ${theme.colors.gray[2]} 1px, transparent 1px),
        linear-gradient(to bottom, ${theme.colors.gray[2]} 1px, transparent 1px)
      `,
  },
}));

import { IconArrowNarrowRight } from '@tabler/icons-react';
import {
  Box,
  Card,
  Horizontal,
  Image,
  Text,
  Vertical,
  useMarkovTheme,
} from '@/shared/design-system/v2';

const CARD_WIDTH = 376;
const ILLUSTRATION_WIDTH = 162;

interface FeatureActionCardProps {
  title: string;
  ctaLabel: string;
  illustrationUrl: string;
  onClick: () => void;
  bg?: string;
}

export const FeatureActionCard = ({
  title,
  ctaLabel,
  illustrationUrl,
  onClick,
  bg,
}: FeatureActionCardProps): JSX.Element => {
  const theme = useMarkovTheme();

  return (
    <Card
      pl="xxl"
      pr="lg"
      pt="xl"
      pb="sm"
      w={CARD_WIDTH}
      bg={bg}
      onClick={onClick}
      sx={{
        border: 'none!important',
        '&:hover': { boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px!important' },
      }}
    >
      <Horizontal noWrap spacing="sm" position="apart">
        <Vertical>
          <Box h={72}>
            <Text variant="subTitle02" color="purple.9">
              {title}
            </Text>
          </Box>
          <Horizontal noWrap spacing="sm" py="sm">
            <Text variant="subTitle05" color="blue.6">
              {ctaLabel}
            </Text>
            <IconArrowNarrowRight color={theme.colors.blue[6]} />
          </Horizontal>
        </Vertical>
        <Box sx={{ flexShrink: 0 }}>
          <Image w={ILLUSTRATION_WIDTH} src={illustrationUrl} />
        </Box>
      </Horizontal>
    </Card>
  );
};
